import ReportIcon from '../assets/icons/report.png';
import Report from '../pages/report/Report';

const ReportRoute = (userInformation) => {
  return [
    {
      path: '/report',
      name: 'Report2',
      icon: ReportIcon,
      parent: false,
      component: Report,
      checkPermission() {
        if (parseInt(userInformation.r_id, 10) !== 1) return false;

        return true;
      },
    },
  ];
};

export default ReportRoute;
