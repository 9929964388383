import { useEffect, useCallback } from 'react';
import axios from 'axios';
import { apiUrl, goApiUrl } from '../../config/api.config';
import Mock from './mock';
import MockGo from './mockGo';

// Create axios instance for set default config
let numberOfAjaxCallPending = 0;
const callApi = axios.create({
  baseURL: apiUrl,
});
const callGoApi = axios.create({
  baseURL: goApiUrl,
});

if (process.env.REACT_APP_MOCK === 'true') {
  Mock(callApi);
  MockGo(callGoApi);
}

const AxiosInterceptor = ({ onBeforeSend, onDone, onAllDone }) => {
  const countAjaxCall = useCallback(() => {
    if (numberOfAjaxCallPending > 0) {
      numberOfAjaxCallPending--;
    }

    if (numberOfAjaxCallPending === 0) {
      onAllDone();
    }
  }, [onAllDone]);

  useEffect(() => {
    const handleRequest = (config) => {
      if (config.global !== false) {
        numberOfAjaxCallPending++;
      }

      if (typeof onBeforeSend === 'function') {
        return onBeforeSend(config);
      }

      return config;
    };

    const handleResponse = (response) => {
      if (!response) return response;

      countAjaxCall();

      if (typeof onDone === 'function') {
        return onDone(response);
      }

      return response;
    };

    const handleError = (error) => {
      countAjaxCall();

      if (error?.response?.data) {
        const { data } = error.response;

        return Promise.reject(new Error(data.msg));
      }

      return Promise.reject(error);
    };

    const requestInterceptor = callApi.interceptors.request.use(
      handleRequest,
      handleError
    );

    const responseInterceptor = callApi.interceptors.response.use(
      handleResponse,
      handleError
    );

    const goRequestInterceptor = callGoApi.interceptors.request.use(
      handleRequest,
      handleError
    );

    const goResponseInterceptor = callGoApi.interceptors.response.use(
      handleResponse,
      handleError
    );

    return () => {
      callApi.interceptors.request.eject(requestInterceptor);
      callApi.interceptors.response.eject(responseInterceptor);
      callGoApi.interceptors.request.eject(goRequestInterceptor);
      callGoApi.interceptors.response.eject(goResponseInterceptor);
    };
  }, [onBeforeSend, onDone, countAjaxCall]);

  return null;
};

export default AxiosInterceptor;
export { callApi, callGoApi };
