import { useEffect, useState, useContext, useMemo, useRef } from 'react';
import { FaCaretRight } from 'react-icons/fa';
import { Link, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import styled, { css } from 'styled-components';
import headerStyle from '../../styles/header.style';
import media from '../../styles/mixin/media';
import scrollbar from '../../styles/mixin/scrollbar';
import AuthenticateContext from '../../provider/context/authenticate.context';
import getRoutes from '../../routes/routes';

import Aside from './Aside';
import AsideHeader from './AsideHeader';

const Sidebar = ({ $display, toggleSidebar }) => {
  let location = useLocation();
  const sidebarRef = useRef(null);
  const [hoverDisplay, setHoverDisplay] = useState(false);
  const { userInformation } = useContext(AuthenticateContext);

  const routes = useMemo(() => {
    // filter child routes
    let routes = getRoutes(userInformation).map((route) => {
      if (route.child && route.child.length > 0) {
        route.child = route.child.filter(({ name, checkPermission }) => {
          return name && (!checkPermission || checkPermission());
        });
      }

      return route;
    });

    // filter parent routes
    return routes.filter(({ name, checkPermission }) => {
      return name && (!checkPermission || checkPermission());
    });
  }, [userInformation]);

  const nowPath = (path) => {
    return location.pathname.includes(path);
  };

  const handleMouseOver = () => {
    if ($display === true) return;

    setHoverDisplay(true);
  };

  const handleMouseOut = (e) => {
    if ($display === true) return;

    if (sidebarRef.current.contains(e.relatedTarget)) return;

    setHoverDisplay(false);
  };

  return (
    <>
      <Aside
        ref={sidebarRef}
        $display={$display || hoverDisplay}
        onMouseOver={handleMouseOver}
        onMouseOut={handleMouseOut}
      >
        <AsideHeader toggleSidebar={toggleSidebar} />
        <Menu>
          {routes.map((prop, key) => {
            //看選單是否有子選單
            if (prop.parent === true) {
              return (
                <MenuListWithSubMenu
                  {...prop}
                  active={nowPath(prop.path)}
                  currentPath={location.pathname}
                  key={key}
                  $display={$display}
                  hoverDisplay={hoverDisplay}
                />
              );
            }

            return <MenuList {...prop} active={nowPath(prop.path)} key={key} />;
          })}
        </Menu>
      </Aside>
    </>
  );
};

const MenuList = ({ path, name, icon, active }) => {
  const { t } = useTranslation();

  return (
    <MenuItem active={active}>
      <MenuItemLink to={path}>
        <img
          crossOrigin='anonymous'
          src={icon}
          alt={name + ' icon'}
          width='24px'
        />
        <span>{t(name)}</span>
      </MenuItemLink>
    </MenuItem>
  );
};

const MenuListWithSubMenu = ({
  path,
  name,
  icon,
  child,
  active,
  currentPath,
  $display,
  hoverDisplay,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(active);

  const toggleOpen = () => {
    setOpen(!open);
  };

  useEffect(() => {
    $display === false && hoverDisplay === false && setOpen(false);
  }, [$display, hoverDisplay]);

  return (
    <>
      <MenuItem onClick={toggleOpen}>
        <MenuItemButton as='button' open={open}>
          <img crossOrigin='anonymous' src={icon} alt={name + ' icon'} />
          <span>{t(name)}</span>
          <span className='arrow' role='presentation' aria-hidden='true'>
            <FaCaretRight />
          </span>
        </MenuItemButton>
      </MenuItem>
      <SubMenu open={open}>
        {child
          .filter((prop) => prop.name)
          .map((prop, key) => {
            const href = `${path}${prop.path}`;
            const active = currentPath.includes(href);

            return (
              <SubMenuItem key={key} active={active}>
                <Link to={href}>
                  <span>{t(prop.name)}</span>
                </Link>
              </SubMenuItem>
            );
          })}
      </SubMenu>
    </>
  );
};

const Menu = styled.ul`
  overflow-y: auto;
  overflow-x: hidden;
  height: 100%;

  span {
    color: var(--font-on-primary);
    font-size: var(--font-body1);
  }

  li {
    transition: 0.3s;

    &:hover {
      background: var(--color-hover);
    }
  }

  ${scrollbar}

  ${media.tablet`
    height: calc(100% - ${headerStyle.height});
  `};
`;

const MenuItem = styled.li`
  ${(props) =>
    props.active === true &&
    css`
      background: var(--color-active);
    `};
`;

const MenuItemLink = styled(Link)`
  display: flex;
  align-items: center;
  padding: 0 var(--spacing);
  height: 48px;

  img {
    width: 24px;
    height: 24px;
    margin-right: var(--spacing);
    flex: 0 0 24px;
  }
`;

const MenuItemButton = styled(MenuItemLink)`
  background-color: transparent;
  border: none;
  cursor: pointer;
  width: 100%;
  text-align: left;

  .arrow {
    margin-left: auto;
    color: var(--color-primary);

    ${({ open }) =>
      open === true &&
      css`
        transform: rotate(90deg);
        transition: all 0.4s;
      `};
  }
`;

const SubMenu = styled.ul`
  overflow: hidden;
  height: ${(props) => (props.open === true ? 'auto' : '0px')};
`;

const SubMenuItem = styled.li`
  a {
    display: flex;
    align-items: center;
    padding: 0 calc(var(--spacing) * 2 + 1.5rem);
    height: 48px;

    ${({ active }) =>
      active === true &&
      css`
        background: var(--color-active);
      `};
  }
`;

export default Sidebar;
