import { callApi } from '../../services/api';

const getLogList = (data) => {
  const params = {
    page: data.page,
    limit: data.limit,
    sort_column: data.sort_column,
    sort_type: data.sort_type,
    start_time: data.start_time,
    end_time: data.end_time,
    u_id: data.u_id,
    action: data.action,
  };

  return callApi.get('/get_log.php', {
    params: params,
  });
};

export { getLogList };
