import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from "react-router-dom";
import "./provider/i18next/i18n";
import { AppContextProvider } from "./provider/context/app.context";
import { AuthenticateContextProvider } from './provider/context/authenticate.context';
import App from "./App";
import "./assets/css/global.css";
import "animate.css";
import "react-toastify/dist/ReactToastify.css";

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <AppContextProvider>
        <AuthenticateContextProvider>
          <App />
        </AuthenticateContextProvider>
      </AppContextProvider>
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root')
);
