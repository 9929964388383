import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFilter } from 'react-icons/fa';
import { MdMoreHoriz } from 'react-icons/md';
import { FiFileText, FiLink } from 'react-icons/fi';
import { FaFilePdf } from 'react-icons/fa';
import {
  getWorkOrderList,
  getPendingReportList,
  getWorkOrderReportInformation,
  linkReportToWorkOrder,
  getWorkOrderTypeList,
  getUrgencyList,
  getWorkOrderStatusList,
} from '../../module/workOrder/api';
import { getMerchantSimpleList } from '../../module/customer/api';
import { getCustomerServiceSimpleList } from '../../module/customerService/api';
import { getEngineerSimpleList } from '../../module/engineer/api';
import { getOwnerList } from '../../module/owner/api';
import { success, error } from '../../module/message';
import handleApiResponse from '../../utils/api/handleApiResponse';
import downloadFile from '../../utils/downloadFile';
import formatDate from '../../utils/format/formatDate';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Section from '../../components/section/Section';
import SectionHeader from '../../components/section/SectionHeader';
import SectionBody from '../../components/section/SectionBody';
import SectionToolbar from '../../components/section/SectionToolbar';
import Heading1 from '../../components/heading/Heading1';
import SearchBox from '../../components/form/Searchbox';
import Table from '../../components/table/Table';
import {
  useLimitChange,
  usePageChange,
  useSortChange,
} from '../../components/table/hooks';
import Dropdown, {
  DropdownItem,
  DropdownButtonOption,
} from '../../components/Dropdown';
import IconButton from '../../components/button/IconButton';
import Button from '../../components/button/Button';
import ButtonGroup from '../../components/button/ButtonGroup';
import TitleModal from '../../components/modal/TitleModal';
import { Grid, Column } from '../../components/grid/Grid';
import FormItem from '../../components/form/FormItem';
import Label from '../../components/form/Label';
import Input from '../../components/form/Input';
import Textarea from '../../components/form/Textarea';
import Slideshow from '../../components/slideshow/Slideshow';
import Checkbox from '../../components/form/Checkbox';
import Select from '../../components/select/Select';
import RadioButton from '../../components/form/RadioButton';
import DatePicker from '../../components/datetimePicker/DatePicker';
import MediaBlock from '../../components/media/MediaBlock';
import PDFViewer from '../../components/pdf/PDFViewer';

const PendingReportList = () => {
  const { t } = useTranslation();
  const [ownerList, setOwnerList] = useState([]);
  const [listData, setListData] = useState([]);
  const [total, setTotal] = useState(1);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
    sort_column: 'create_time',
    sort_type: 'desc',
    search: '',
    owner_id: -1,
  });

  const pathData = useMemo(() => {
    return [{ name: t('WorkOrder') }, { name: t('PendingReportList') }];
  }, [t]);

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);
  const onSortChange = useSortChange(
    setListParams,
    listParams.sort_column,
    listParams.sort_type
  );

  const handleGetPendingReportList = useCallback(() => {
    const params = {
      page: listParams.page,
      limit: listParams.limit,
      sort_column: listParams.sort_column,
      sort_type: listParams.sort_type,
    };

    if (listParams.owner_id >= 0) {
      params.owner_id = listParams.owner_id;
    }

    if (listParams.search) {
      params.search = listParams.search;
    }

    handleApiResponse(getPendingReportList(params), (response) => {
      const { report, total } = response.data.data;
      setListData(report || []);
      setTotal(total);
    });
  }, [listParams]);

  const showDetails = useCallback((id) => {
    handleApiResponse(
      getWorkOrderReportInformation({ report_id: id }),
      (response) => {
        const { data } = response.data;

        TitleModal({
          size: 'normal',
          children: <ReportDetailsModal data={data} />,
        });
      }
    );
  }, []);

  const showLinkWorkOrderModal = useCallback(
    (id, MID) => {
      TitleModal({
        size: 'normal',
        children: (
          <LinkWorkOrderModal
            reportId={id}
            MID={MID}
            handleGetPendingReportList={handleGetPendingReportList}
          />
        ),
      });
    },
    [handleGetPendingReportList]
  );

  const generateActionDropdown = useCallback(
    ({ report_id, MID }) => {
      return (
        <Dropdown key={report_id}>
          <Dropdown.Toggle>
            <IconButton>
              <MdMoreHoriz />
            </IconButton>
          </Dropdown.Toggle>
          <Dropdown.Content>
            <DropdownItem onClick={() => showDetails(report_id)}>
              <DropdownButtonOption>
                <FiFileText />
                {t('Details')}
              </DropdownButtonOption>
            </DropdownItem>
            <DropdownItem
              onClick={() => showLinkWorkOrderModal(report_id, MID)}
            >
              <DropdownButtonOption>
                <FiLink />
                {t('Link')}
              </DropdownButtonOption>
            </DropdownItem>
          </Dropdown.Content>
        </Dropdown>
      );
    },
    [t, showDetails, showLinkWorkOrderModal]
  );

  const showFilterPanel = () => {
    TitleModal({
      size: 'small',
      children: (
        <PendingReportListFilter
          listParams={listParams}
          setListParams={setListParams}
          ownerList={ownerList}
        />
      ),
    });
  };

  useEffect(() => {
    handleApiResponse(getOwnerList(), (response) => {
      const { owner } = response.data.data;
      const data = owner.map((owner) => {
        return { id: owner.owner_id, text: owner.owner };
      });

      if (data.length > 0) {
        setListParams((previous) => {
          return {
            ...previous,
            owner_id: data[0].id,
          };
        });
      }

      setOwnerList(data);
    });
  }, [t]);

  useEffect(() => {
    handleGetPendingReportList();
  }, [handleGetPendingReportList]);

  return (
    <>
      <Breadcrumb path={pathData} />
      <Section noPadding>
        <SectionHeader sticky backgroundFill inline>
          <Heading1>{t('PendingReport')}</Heading1>
          <SectionToolbar>
            <SearchBox
              border
              onSearch={(keyword) => {
                setListParams((previousParams) => {
                  return {
                    ...previousParams,
                    page: 1,
                    search: keyword,
                  };
                });
              }}
            />
            <Button secondary onClick={showFilterPanel}>
              <FaFilter />
              {t('Filter')}
            </Button>
          </SectionToolbar>
        </SectionHeader>
        <SectionBody>
          <Table
            columns={[
              {
                title: t('Bank'),
                fieldName: 'owner',
                sortable: true,
              },
              {
                title: t('TerminalSerialNumber'),
                fieldName: 'TID',
                sortable: false,
              },
              {
                title: t('MerchantNumber'),
                fieldName: 'MID',
                sortable: false,
              },
              {
                title: t('Name'),
                fieldName: 'name',
                sortable: true,
              },
              {
                title: t('Type'),
                fieldName: 'status',
                sortable: true,
                render(data) {
                  return data || '';
                },
              },
              {
                title: t('Time'),
                fieldName: 'create_time',
                sortable: true,
              },
              {
                title: t('Message'),
                fieldName: 'message',
                sortable: false,
              },
              {
                title: t('Action'),
                custom: true,
                width: '10%',
                align: 'center',
                render(data) {
                  return generateActionDropdown(data);
                },
              },
            ]}
            data={listData}
            currentPage={listParams.page}
            limit={listParams.limit}
            onLimitChange={onLimitChange}
            total={total}
            sortColumn={listParams.sort_column}
            sortType={listParams.sort_type}
            onSortChange={onSortChange}
            onPageChange={onPageChange}
            translation={{ info: t('table.info'), empty: t('table.empty') }}
          />
        </SectionBody>
      </Section>
    </>
  );
};

const PendingReportListFilter = ({
  listParams,
  setListParams,
  ownerList,
  close,
}) => {
  const { t } = useTranslation();
  const [bank, setBank] = useState(listParams.owner_id);

  const handleSearch = () => {
    setListParams((previousParams) => {
      return {
        ...previousParams,
        page: 1,
        owner_id: bank,
      };
    });
    close();
  };

  return (
    <>
      <TitleModal.Header handleClose={close}>{t('Filter')}</TitleModal.Header>
      <TitleModal.Body noPaddingBottom>
        <FormItem>
          <Label>{t('Bank')}</Label>
          <Select
            fullWidth
            options={ownerList}
            selected={bank}
            onSelect={setBank}
          />
        </FormItem>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={close}>
            {t('button:Cancel')}
          </Button>
          <Button success onClick={handleSearch}>
            {t('button:Search')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

const ReportDetailsModal = ({ data, close }) => {
  const { t } = useTranslation();

  const showPreviewPDFModal = ({ title, source }) => {
    TitleModal({
      size: 'normal',
      children: <PreviewPDFModal title={title} source={source} />,
    });
  };

  return (
    <>
      <TitleModal.Header handleClose={close}>
        {t('ReportDetails')}
      </TitleModal.Header>
      <TitleModal.Body noPaddingBottom>
        <Grid columns={2}>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='type'>{t('Type')}</Label>
              <Input
                type='text'
                id='type'
                value={
                  data.trouble ? `${data.status}(${data.trouble})` : data.status
                }
                disabled
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='time'>{t('Time')}</Label>
              <Input type='text' id='time' value={data.create_time} disabled />
            </FormItem>
          </Column>
          <Column desktop={2}>
            <FormItem>
              <Label htmlFor='content'>{t('Content')}</Label>
              <Textarea id='content' rows='4' value={data.content} disabled />
            </FormItem>
          </Column>

          {data?.photo?.length > 0 && (
            <Column desktop={2}>
              <FormItem>
                <Label htmlFor='photo'>{t('Photo')}</Label>
                <Section>
                  <SectionBody noPadding>
                    <Slideshow images={data.photo} />
                  </SectionBody>
                </Section>
              </FormItem>
            </Column>
          )}

          {(data.engineer_sign || data.customer_sign) && (
            <>
              <Column desktop={1} className='margin-bottom'>
                <Label htmlFor='engineerSignature'>
                  {t('EngineerSignature')}
                </Label>
                <Section noPaddingBottom fullHeight>
                  <SectionBody noPadding>
                    {data.engineer_sign && (
                      <img
                        src={data.engineer_sign}
                        alt='engineer signature'
                        style={{ maxWidth: '100%' }}
                      />
                    )}
                  </SectionBody>
                </Section>
              </Column>
              <Column desktop={1} className='margin-bottom'>
                <Label htmlFor='customerSignature'>
                  {t('CustomerSignature')}
                </Label>
                <Section noPaddingBottom fullHeight>
                  <SectionBody noPadding>
                    {data.customer_sign && (
                      <img
                        src={data.customer_sign}
                        alt='customer signature'
                        style={{ maxWidth: '100%' }}
                      />
                    )}
                  </SectionBody>
                </Section>
              </Column>
            </>
          )}

          {(data.electronic_worksheet || data.electronic_receipt) && (
            <Column desktop={2} className='margin-bottom'>
              <Label htmlFor='attachment'>{t('Attachment')}</Label>
              {data.electronic_worksheet && (
                <MediaBlock
                  icon={<FaFilePdf />}
                  title={t('InstallationWorkOrder')}
                  description={data.electronic_worksheet.split('/').pop()}
                  tools={
                    <ButtonGroup alignCenter>
                      <Button
                        onClick={() => {
                          showPreviewPDFModal({
                            title: t('InstallationWorkOrder'),
                            source: data.electronic_worksheet,
                          });
                        }}
                      >
                        {t('button:Preview')}
                      </Button>
                      <Button
                        onClick={() => {
                          downloadFile(
                            data.electronic_worksheet,
                            'application/pdf'
                          );
                        }}
                      >
                        {t('button:Download')}
                      </Button>
                    </ButtonGroup>
                  }
                />
              )}

              {data.electronic_receipt && (
                <MediaBlock
                  icon={<FaFilePdf />}
                  title={t('InstallationReceipt')}
                  description={data.electronic_receipt.split('/').pop()}
                  tools={
                    <ButtonGroup alignCenter>
                      <Button
                        onClick={() => {
                          showPreviewPDFModal({
                            title: t('InstallationReceipt'),
                            source: data.electronic_receipt,
                          });
                        }}
                      >
                        {t('button:Preview')}
                      </Button>
                      <Button
                        onClick={() => {
                          downloadFile(
                            data.electronic_receipt,
                            'application/pdf'
                          );
                        }}
                      >
                        {t('button:Download')}
                      </Button>
                    </ButtonGroup>
                  }
                />
              )}
            </Column>
          )}
        </Grid>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={close}>
            {t('button:Close')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

const PreviewPDFModal = ({ title, source, close }) => {
  const { t } = useTranslation();

  return (
    <>
      <TitleModal.Header handleClose={close}>{title}</TitleModal.Header>
      <TitleModal.Body noPaddingBottom>
        <PDFViewer source={source} loadingText={t('Loading')} />
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={close}>
            {t('button:Close')}
          </Button>
          <Button
            onClick={() => {
              downloadFile(source, 'application/pdf');
            }}
          >
            {t('button:Download')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

const LinkWorkOrderModal = ({
  reportId,
  MID,
  handleGetPendingReportList,
  close,
}) => {
  const { t } = useTranslation();
  const [listData, setListData] = useState([]);
  const [total, setTotal] = useState(1);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
    sort_column: 'wo_id',
    sort_type: 'desc',
    search: MID,
    type: -1,
    merchant: -1,
    lifephase_id: -1,
    ur_id: -1,
    principal: -1,
    time_mode: 'all',
    start_time: formatDate(Date.now(), 'date'),
    end_time: formatDate(Date.now(), 'date'),
  });
  const [typeList, setTypeList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [urgencyList, setUrgencyList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const [customerServiceList, setCustomerServiceList] = useState([]);
  const [engineerList, setEngineerList] = useState([]);
  const [assigneeList, setAssigneeList] = useState([]);
  const [selected, setSelected] = useState();

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);
  const onSortChange = useSortChange(
    setListParams,
    listParams.sort_column,
    listParams.sort_type
  );

  const handleSelectedChange = (id) => {
    if (id === selected) {
      setSelected('');
    } else {
      setSelected(id);
    }
  };

  const handleConfirm = () => {
    if (!selected) {
      error({ message: t('error:PleaseSelectAWorkOrder') });
      return;
    }

    handleApiResponse(
      linkReportToWorkOrder({
        wo_id: selected,
        report_id: reportId,
      }),
      (response) => {
        if (response) {
          success({ message: t('success:LinkWorkOrder') });
          close();
          handleGetPendingReportList();
        }
      }
    );
  };

  const showFilterPanel = () => {
    TitleModal({
      size: 'normal',
      children: (
        <LinkWorkOrderModalFilter
          listParams={listParams}
          setListParams={setListParams}
          typeList={typeList}
          customerList={customerList}
          urgencyList={urgencyList}
          statusList={statusList}
          assigneeList={assigneeList}
        />
      ),
    });
  };

  useEffect(() => {
    const params = {
      page: listParams.page,
      limit: listParams.limit,
      sort_column: listParams.sort_column,
      sort_type: listParams.sort_type,
    };

    if (listParams.time_mode !== 'all') {
      params.start_time = listParams.start_time;
      params.end_time = listParams.end_time;
    }

    if (listParams.type >= 0) {
      params.type = listParams.type;
    }

    if (listParams.merchant !== -1) {
      params.merchant = listParams.merchant.split('_')[1];
    }

    if (listParams.lifephase_id !== -1) {
      params.lifephase_id = listParams.lifephase_id;
    }

    if (listParams.ur_id !== -1) {
      params.ur_id = listParams.ur_id;
    }

    if (listParams.principal !== -1) {
      const [principalType, principal] = listParams.principal.split('_');
      params.principal_type = Number(principalType);
      params.principal = Number(principal);
    }

    if (listParams.search) {
      params.search = listParams.search;
    }

    handleApiResponse(getWorkOrderList(params), (response) => {
      const { workorder, total } = response.data.data;
      setListData(workorder || []);
      setTotal(total);
    });
  }, [listParams]);

  useEffect(() => {
    handleApiResponse(getWorkOrderTypeList(), (response) => {
      let { wo_type } = response.data.data;

      wo_type = wo_type || [];
      setTypeList(
        [].concat(
          [{ id: -1, text: t('All') }],
          wo_type.map((type) => {
            return { id: type.type_id, text: type.type };
          })
        )
      );
    });

    handleApiResponse(getMerchantSimpleList(), (response) => {
      const { data } = response.data;

      const merchants = data || [];
      setCustomerList(
        [].concat(
          [{ id: -1, text: t('All') }],
          merchants.map((merchant) => {
            return {
              id: `${merchant.m_id}_${merchant.name}`,
              text: merchant.name,
            };
          })
        )
      );
    });

    handleApiResponse(getUrgencyList(), (response) => {
      let { urgency } = response.data.data;

      urgency = urgency || [];
      setUrgencyList(
        [].concat(
          [{ id: -1, text: t('All') }],
          urgency.map((urgency) => {
            return { id: urgency.ur_id, text: urgency.urgency };
          })
        )
      );
    });

    handleApiResponse(getCustomerServiceSimpleList(), (response) => {
      const { data } = response.data;

      const users = data || [];
      setCustomerServiceList(
        users.map((user) => {
          return {
            id: `1_${user.u_id}`,
            text: `${user.first_name} ${user.last_name} (${t(
              'CustomerService'
            )})`,
          };
        })
      );
    });

    handleApiResponse(getEngineerSimpleList(), (response) => {
      const { data } = response.data;

      const engineers = data || [];
      setEngineerList(
        engineers.map((engineer) => {
          return {
            id: `2_${engineer.en_id}`,
            text: `${engineer.first_name} ${engineer.last_name} (${t(
              'Engineer'
            )})`,
          };
        })
      );
    });

    handleApiResponse(getWorkOrderStatusList(), (response) => {
      let { wo_status } = response.data.data;

      wo_status = wo_status || [];
      const data = wo_status
        .filter((type) => {
          return (
            type.lifephase_id !== 7 &&
            type.lifephase_id !== '7' &&
            type.lifephase_id !== 8 &&
            type.lifephase_id !== '8'
          );
        })
        .map((type) => {
          return { id: parseInt(type.lifephase_id, 10), text: type.lifephase };
        });

      setStatusList([].concat([{ id: -1, text: t('All') }], data));
    });
  }, [t]);

  useEffect(() => {
    setAssigneeList(
      [].concat([{ id: -1, text: t('All') }], customerServiceList, engineerList)
    );
  }, [t, customerServiceList, engineerList]);

  return (
    <>
      <TitleModal.Header handleClose={close}>
        {t('LinkWorkOrder')}
      </TitleModal.Header>
      <TitleModal.Body>
        <Section noPadding noMarginBottom backgroundReverse>
          <SectionHeader>
            <SectionToolbar>
              <div></div>
              <Button reverse onClick={showFilterPanel}>
                <FaFilter />
                {t('Filter')}
              </Button>
            </SectionToolbar>
          </SectionHeader>
          <SectionBody>
            <Table
              backgroundReverse
              columns={[
                {
                  title: '',
                  fieldName: 'wo_id',
                  sortable: false,
                  width: '10%',
                  render(data) {
                    return (
                      <Checkbox
                        noMargin
                        onChange={() => {
                          handleSelectedChange(data);
                        }}
                        checked={data === selected}
                      />
                    );
                  },
                },
                {
                  title: t('OrderNumber'),
                  fieldName: 'number',
                  sortable: true,
                },
                {
                  title: t('TerminalSerialNumber'),
                  fieldName: 'TID',
                  sortable: true,
                },
                {
                  title: t('HardwareSerialNumber'),
                  fieldName: 'hwsn',
                  sortable: true,
                  render(data) {
                    if (!data) {
                      return '';
                    }

                    return data;
                  },
                },
                {
                  title: t('MerchantNumber'),
                  fieldName: 'MID',
                  sortable: true,
                },
                {
                  title: t('Customer'),
                  fieldName: 'merchant',
                  sortable: true,
                },
                {
                  title: t('Status'),
                  fieldName: 'lifephase',
                  sortable: true,
                },
                {
                  title: t('Assignee'),
                  fieldName: 'first_name',
                  sortable: false,
                  render(data, full) {
                    return `${full.first_name} ${full.last_name}`;
                  },
                },
              ]}
              data={listData}
              currentPage={listParams.page}
              limit={listParams.limit}
              onLimitChange={onLimitChange}
              total={total}
              sortColumn={listParams.sort_column}
              sortType={listParams.sort_type}
              onSortChange={onSortChange}
              onPageChange={onPageChange}
              translation={{ info: t('table.info'), empty: t('table.empty') }}
              select={{ type: 'single', matcher: 'wo_id' }}
              selected={selected}
            />
          </SectionBody>
        </Section>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={close}>
            {t('button:Cancel')}
          </Button>
          <Button success onClick={handleConfirm}>
            {t('button:Confirm')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

const LinkWorkOrderModalFilter = ({
  listParams,
  setListParams,
  typeList,
  customerList,
  urgencyList,
  statusList,
  assigneeList,
  close,
}) => {
  const { t } = useTranslation();
  const [type, setType] = useState(listParams.type);
  const [customer, setCustomer] = useState(listParams.merchant);
  const [lifephaseId, setLifephaseId] = useState(listParams.lifephase_id);
  const [urgency, setUrgency] = useState(listParams.ur_id);
  const [principal, setPrincipal] = useState(listParams.principal);
  const [mode, setMode] = useState(listParams.time_mode);
  const [startTime, setStartTime] = useState(listParams.start_time);
  const [endTime, setEndTime] = useState(listParams.end_time);

  const handleSearch = () => {
    setListParams((previousParams) => {
      return {
        ...previousParams,
        page: 1,
        type: type,
        merchant: customer,
        lifephase_id: lifephaseId,
        ur_id: urgency,
        principal: principal,
        time_mode: mode,
        start_time: startTime,
        end_time: endTime,
      };
    });
    close();
  };

  useEffect(() => {
    const startDate = new Date(Date.now());
    const endDate = new Date(Date.now());
    if (mode === 'today') {
      setStartTime(formatDate(startDate, 'date'));
      setEndTime(formatDate(endDate, 'date'));
    }

    if (mode === 'lastThreeDay') {
      startDate.setDate(startDate.getDate() - 3);
      setStartTime(formatDate(startDate, 'date'));
      setEndTime(formatDate(endDate, 'date'));
    }
  }, [mode]);

  return (
    <>
      <TitleModal.Header handleClose={close}>{t('Filter')}</TitleModal.Header>
      <TitleModal.Body noPaddingBottom>
        <Grid columns={2}>
          <Column desktop={1}>
            <FormItem>
              <Label>{t('Type')}</Label>
              <Select
                fullWidth
                options={typeList}
                selected={type}
                onSelect={setType}
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label>{t('Customer')}</Label>
              <Select
                fullWidth
                options={customerList}
                selected={customer}
                onSelect={setCustomer}
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label>{t('Urgency')}</Label>
              <Select
                fullWidth
                options={urgencyList}
                selected={urgency}
                onSelect={setUrgency}
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label>{t('Status')}</Label>
              <Select
                fullWidth
                options={statusList}
                selected={lifephaseId}
                onSelect={setLifephaseId}
              />
            </FormItem>
          </Column>
          <Column desktop={2}>
            <FormItem>
              <Label>{t('Assignee')}</Label>
              <Select
                fullWidth
                options={assigneeList}
                selected={principal}
                onSelect={setPrincipal}
              />
            </FormItem>
          </Column>
          <Column desktop={2}>
            <FormItem>
              <Label>{t('ExpectFinishDate')}</Label>
              <div>
                <RadioButton
                  htmlFor='all'
                  text={t('All')}
                  value='all'
                  checked={mode === 'all'}
                  onChange={() => {
                    setMode('all');
                  }}
                />
                <RadioButton
                  htmlFor='today'
                  text={t('Today')}
                  value='today'
                  checked={mode === 'today'}
                  onChange={() => {
                    setMode('today');
                  }}
                />
                <RadioButton
                  htmlFor='lastThreeDay'
                  text={t('LastThreeDay')}
                  value='lastThreeDay'
                  checked={mode === 'lastThreeDay'}
                  onChange={() => {
                    setMode('lastThreeDay');
                  }}
                />
                <RadioButton
                  htmlFor='custom'
                  text={t('Custom')}
                  value='custom'
                  checked={mode === 'custom'}
                  onChange={() => {
                    setMode('custom');
                  }}
                />
              </div>
              {mode === 'custom' && (
                <Grid columns={2}>
                  <Column desktop={1}>
                    <DatePicker selected={startTime} onConfirm={setStartTime} />
                  </Column>
                  <Column desktop={1}>
                    <DatePicker selected={endTime} onConfirm={setEndTime} />
                  </Column>
                </Grid>
              )}
            </FormItem>
          </Column>
        </Grid>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={close}>
            {t('button:Cancel')}
          </Button>
          <Button success onClick={handleSearch}>
            {t('button:Search')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

export default PendingReportList;
