import { callApi } from '../../services/api';

const getCustomerServiceList = (data) => {
  const params = {
    page: data.page,
    limit: data.limit,
    sort_column: data.sort_column,
    sort_type: data.sort_type,
    search: data.search,
  };

  return callApi.get('/get_user.php', {
    params: params,
  });
};

const getCustomerServiceSimpleList = () => {
  return callApi.get('/get_user_simple.php');
};

const getCustomerServiceInformation = (data) => {
  const params = {
    u_id: data.u_id,
  };

  return callApi.get('/get_userinfo.php', {
    params: params,
  });
};

const createCustomerService = (data) => {
  const params = {
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    phone: data.phone,
    r_id: data.r_id,
  };

  return callApi.post('/create_user.php', params);
};

const modifyCustomerService = (data) => {
  const params = {
    u_id: data.u_id,
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    phone: data.phone,
  };

  return callApi.post('/set_user.php', params);
};

const removeCustomerService = (data) => {
  const params = {
    u_id: data.u_id,
  };

  return callApi.post('/del_user.php', params);
};

const modifyCustomerServicePassword = (data) => {
  const params = {
    account: data.account,
    old_password: data.old_password,
    new_password: data.new_password,
  };

  return callApi.post('/set_user_password.php', params);
};

const setCustomerServicePassword = (data) => {
  const params = {
    u_id: data.u_id,
    password: data.password,
    ticket: data.ticket,
  };

  return callApi.post('/new_password.php', params);
};

export {
  getCustomerServiceList,
  getCustomerServiceSimpleList,
  getCustomerServiceInformation,
  createCustomerService,
  modifyCustomerService,
  removeCustomerService,
  modifyCustomerServicePassword,
  setCustomerServicePassword,
};
