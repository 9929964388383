import CustomerIcon from '../assets/icons/customer.png';
import List from '../pages/customer/List';
import Detail from '../pages/customer/Detail';

const CustomerRoute = (userInformation) => {
  return [
    {
      path: '/customer',
      name: 'CustomerManagement',
      icon: CustomerIcon,
      parent: true,
      checkPermission() {
        return true;
      },
      child: [
        {
          path: '/list',
          name: 'CustomerList',
          component: List,
          checkPermission() {
            return true;
          },
        },
        {
          path: '/:id',
          component: Detail,
          checkPermission() {
            return true;
          },
        },
      ],
    },
  ];
};

export default CustomerRoute;
