import styled from "styled-components";

const SelectedSingle = styled.div`
  color: var(--font-on-primary);
  overflow: hidden;
  text-overflow: ellipsis;
`;
SelectedSingle.displayName = "SelectedSingle";

export default SelectedSingle;
