import { useContext, useMemo } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import getRoutes from "../../routes/routes";
import AuthenticateContext from "../../provider/context/authenticate.context";

const RouterView = () => {
  const { userInformation } = useContext(AuthenticateContext);

  const routes = useMemo(() => {
    return getRoutes(userInformation);
  }, [userInformation]);

  return (
    <Switch>
      {routes.map(({ path, parent, child, component, ...props }, key) => {
        if (parent === true) {
          return child.map(
            ({ path: subPath, component, ...childProps }, key) => {
              return (
                <PrivateRoute
                  exact
                  path={`${path}${subPath}`}
                  component={component}
                  key={key}
                  {...childProps}
                />
              );
            }
          );
        }

        return (
          <PrivateRoute
            exact
            path={path}
            component={component}
            key={key}
            {...props}
          />
        );
      })}
    </Switch>
  );
};

const PrivateRoute = ({ component: Component, checkPermission, ...rest }) => {
  const { isAuthenticated, userInformation } = useContext(AuthenticateContext);

  return (
    <Route
      {...rest}
      render={() => {
        if (!isAuthenticated) {
          return <Redirect to={{ pathname: "/login" }} />;
        }

        // check permission if required
        if (
          !checkPermission ||
          (typeof checkPermission === "function" && checkPermission())
        ) {
          return <Component userInformation={userInformation} />;
        } else {
          return <Redirect to={{ pathname: "/403" }} />;
        }
      }}
    />
  );
};

export default RouterView;
