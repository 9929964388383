import { useCallback, useContext, useMemo } from 'react';
import { IoMdArrowDropup } from 'react-icons/io';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import styled, { css } from 'styled-components';
import datetimeTFormat from '../../../utils/format/datetimeFormat';
import DateTimePicker from '../DateTimePickerContext';
import { MONTH } from '../provider/constant';

const Month = ({
  expand,
  handleDisplayAccordionContent,
  handleChangeTitle,
}) => {
  const {
    selectedDate,
    setSelectedDate,
    minDate,
    maxDate,
    fixedYear,
    setAccordionCurrentDisplayType,
  } = useContext(DateTimePicker);

  const handleChangeMonth = useCallback(
    (month) => {
      setSelectedDate(() => {
        const date = new Date(selectedDate);
        date.setDate(1);
        date.setMonth(month);

        return datetimeTFormat(date);
      });
      setAccordionCurrentDisplayType(null);
    },
    [setAccordionCurrentDisplayType, setSelectedDate, selectedDate]
  );
  const handleMonthItemDisable = useCallback(
    (month) => {
      let minDayDate;
      let maxDayDate;

      if (minDate) {
        minDayDate = new Date(datetimeTFormat(minDate));
        minDayDate.setMonth(month);
        minDayDate.setFullYear(new Date(selectedDate).getFullYear());
      }
      if (maxDate) {
        maxDayDate = maxDate && new Date(datetimeTFormat(maxDate));
        maxDayDate.setFullYear(new Date(selectedDate).getFullYear());
        maxDayDate.setMonth(month);
      }

      if (minDate !== undefined && maxDate !== undefined) {
        return new Date(datetimeTFormat(minDate)) <= minDayDate &&
          maxDayDate <= new Date(datetimeTFormat(maxDate))
          ? false
          : true;
      } else if (minDate) {
        return datetimeTFormat(minDate) <= datetimeTFormat(minDayDate)
          ? false
          : true;
      } else if (maxDate) {
        return datetimeTFormat(maxDayDate) <= datetimeTFormat(maxDate)
          ? false
          : true;
      }

      return false;
    },
    [maxDate, minDate, selectedDate]
  );

  const handleDisableArrow = useCallback(
    (arrowType) => {
      const date = new Date(selectedDate);
      const selectedMonth = date.getMonth();
      date.setDate(1);

      if (arrowType === 'previous') {
        date.setMonth(selectedMonth - 1 < 0 ? 11 : selectedMonth - 1);
      } else {
        date.setMonth(selectedMonth + 1 > 11 ? 0 : selectedMonth + 1);
      }

      if ((fixedYear && !minDate && !maxDate) || (!minDate && !maxDate)) {
        return false;
      } else {
        const minDate1st = minDate && new Date(datetimeTFormat(minDate));
        const maxDate1st = maxDate && new Date(datetimeTFormat(maxDate));

        if (minDate && !maxDate) {
          minDate1st.setDate(1);
          minDate1st.setHours(0, 0, 0, 0);

          return minDate1st <= date ? false : true;
        } else if (maxDate && !minDate) {
          maxDate1st.setDate(1);

          return maxDate1st >= date ? false : true;
        } else if (minDate && maxDate) {
          minDate1st.setHours(0, 0, 0, 0);
          maxDate1st.setDate(1);
          minDate1st.setDate(1);
          return minDate1st <= date && maxDate1st >= date ? false : true;
        }
      }
    },
    [fixedYear, maxDate, minDate, selectedDate]
  );

  const monthContent = useMemo(() => {
    const selectedMonth = new Date(selectedDate).getMonth();
    return MONTH.map((month, index) => (
      <Item
        selected={selectedMonth === index}
        onClick={() => {
          handleChangeMonth(index);
        }}
        key={month.number}
        disabled={handleMonthItemDisable(index)}
      >
        {month.text}
      </Item>
    ));
  }, [selectedDate, handleChangeMonth, handleMonthItemDisable]);

  return (
    <>
      <Header>
        <IconButton
          onClick={() => {
            handleChangeTitle('previous');
          }}
          disabled={handleDisableArrow('previous')}
        >
          <MdArrowBackIosNew />
        </IconButton>
        <div>{MONTH[new Date(selectedDate).getMonth()].text}</div>

        <IconButton
          onClick={() => {
            handleChangeTitle('next');
          }}
          disabled={handleDisableArrow('next')}
        >
          <MdArrowForwardIos />
        </IconButton>
        <IconButton
          rotate={expand}
          right
          onClick={handleDisplayAccordionContent}
        >
          <IoMdArrowDropup />
        </IconButton>
      </Header>

      <Body expand={expand} fixedYear={fixedYear}>
        {monthContent}
      </Body>
    </>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;

  > div {
    width: 72px;
    line-height: 36px;
    text-align: center;
  }

  &::before {
    content: '';
    border-bottom: var(--border-width) solid var(--border-color);
    position: absolute;
    width: 300px;
    bottom: 0;
    z-index: 1;
    right: -16px;
  }
`;

const IconButton = styled.button`
  width: 36px;
  height: 36px;
  background: var(--color-background1);
  border: none;
  color: var(--color-white);
  padding: 0;
  transition: 0.3s;

  &:hover {
    color: var(--color-primary);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;

      &:hover {
        color: var(--color-white);
      }
    `}

  ${({ right, rotate }) =>
    right &&
    `
    position: absolute;
    right: 0;
    ${
      rotate &&
      css`
        transform: rotate(180deg);
      `
    }
  `}
`;

const Body = styled.div`
  flex-wrap: wrap;
  height: 0;
  width: 100%;
  display: ${({ expand }) => (expand ? 'flex' : 'none')};

  ${({ fixedYear }) =>
    css`
      min-height: ${fixedYear ? '192' : '144'}px;
    `}
  transition: .3s;

  transition: 0.3s;
`;

const Item = styled.button`
  width: calc(100% / 3);
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: var(--border-radius-s);
  padding: 0;
  border: none;
  color: var(--whiet);
  background: var(--color-background1);

  &:hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        background: var(--color-primary);
      `}
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--font-on-mute);
    `}

  ${({ selected }) =>
    selected &&
    css`
      background: var(--color-hover);
    `}
`;

export default Month;
