import { callApi, callGoApi } from '../../services/api';

const getReportExport = (data) => {
  const params = {
    owner: data.owner,
    type: data.type,
    detail: data.detail,
    start_time: data.start_time,
    end_time: data.end_time,
  };

  return callApi.get('/get_report_export.php', {
    params: params,
  });
};

const exportXmlReport = (fileType, data) => {
  return callGoApi.get(`/workorder/finished/export/${fileType}`, {
    params: data,
    responseType: 'blob',
  });
};

export { getReportExport, exportXmlReport };
