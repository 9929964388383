import styled, { css } from "styled-components";
import headerStyle from "../../styles/header.style";
import sidebarStyle from "../../styles/sidebar.style";
import media from "../../styles/mixin/media";
import bgAside from "../../assets/images/bg-aside.png";

const Aside = styled.aside`
  width: ${({ $display }) =>
    $display ? `${sidebarStyle.open.width}` : `${sidebarStyle.close.width}`};

  padding-top: var(--spacing-s);
  height: calc(100vh - ${headerStyle.height} - var(--spacing));
  background: url(${bgAside}), var(--color-background2);
  background-size: 356px 436px;
  background-repeat: no-repeat;
  background-position: bottom;
  border-top-right-radius: var(--border-radius);
  position: fixed;
  top: calc(${headerStyle.height} + var(--spacing));
  transition: 0.2s;
  z-index: 60;

  ${media.tablet`
    height: 100%;
    top: 0;
    padding-top:0;
    border-top-right-radius:0;
    left: 0;
    box-shadow: var(--box-shadow);
    
    ${(props) =>
      props.$display === false &&
      css`
        left: -${sidebarStyle.open.width};
      `}
  `};
`;

export default Aside;
