import { useState, useContext, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FaExternalLinkAlt } from 'react-icons/fa';
import headerStyle from '../../styles/header.style';
import media from '../../styles/mixin/media';
import MenuIcon from './MenuIcon';
import Logo from './Logo';
import Select from '../select/Select';
import UserInfo from './UserInfo';
import AuthenticateContext from '../../provider/context/authenticate.context';
import { LANGUAGES } from '../../provider/constant';
import Button from '../button/Button';

const Header = ({ toggleSidebar }) => {
  const { t, i18n } = useTranslation();
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const { userInformation } = useContext(AuthenticateContext);

  const handleChangeLanguage = (value) => {
    setSelectedLanguage(value);
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [i18n, selectedLanguage]);

  return (
    <>
      <Wrapper>
        <Brand>
          <MenuIcon toggleSidebar={toggleSidebar} />
          <Logo />
        </Brand>
        <Topbar>
          <Button
            type='button'
            reverse
            onClick={() => {
              window.open(
                `${userInformation.inventory}?u_id=${userInformation.u_id}&ticket=${userInformation.ticket}`,
                '_blank'
              );
            }}
          >
            <FaExternalLinkAlt />
            {t('InventorySystem')}
          </Button>
          <Select
            large
            fillReverse
            allowSearch={false}
            options={LANGUAGES}
            selected={selectedLanguage}
            onSelect={handleChangeLanguage}
          />

          <UserInfo />
        </Topbar>
      </Wrapper>
    </>
  );
};

const Wrapper = styled.header`
  background: var(--color-background2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: fixed;
  z-index: 40;
  width: 100%;
  height: ${headerStyle.height};
  padding: 0 calc(var(--spacing-s) + 0.25rem) 0 var(--spacing-s);

  ${media.tablet`
    padding: 0 var(--spacing-s) 0 calc(var(--spacing-s) - 0.25rem);
  `}
`;

const Brand = styled.div`
  display: flex;

  > * {
    flex: none;
  }
`;

const Topbar = styled.div`
  display: flex;

  > *:not(:last-child) {
    margin-right: calc(var(--spacing) - 0.5rem);
  }
`;

export default Header;
