import { Fragment } from 'react';
import styled from 'styled-components';
import LinkSpan from '../link/LinkSpan';
import media from '../../styles/mixin/media';

const AccountButton = ({ buttons }) => {
  return (
    <Container data-testid='account-button'>
      {buttons.map(({ text, delimiter, onClick, ...prop }, index) => {
        return (
          <Fragment key={index}>
            <LinkSpan onClick={onClick} {...prop}>
              {text}
            </LinkSpan>
            {delimiter ? delimiter : ''}
          </Fragment>
        );
      })}
    </Container>
  );
};

const Container = styled.div`
  text-align: center;
  margin-top: 1.5rem;
  color: #fff;

  ${media.mobile`
    margin-top: 1.125rem;
  `}
`;

export default AccountButton;
