import styled from 'styled-components';

const HiddenFileInput = styled.input.attrs(() => ({
  'data-testid': 'hiddenFileInput',
  type: 'file',
}))`
  display: none;
`;

export default HiddenFileInput;
