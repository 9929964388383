import { useContext } from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import AppContext from './provider/context/app.context';
import AuthenticateContext from './provider/context/authenticate.context';
import Login from './pages/login/';
import SetUserPassword from './pages/profile/SetUserPassword';
import SetEngineerPassword from './pages/profile/SetEngineerPassword';
import ForbiddenPage from './pages/error/forbidden';
import NotFoundPage from './pages/error/notFound';
import Layout from './components/layout/Layout';
import RouterView from './components/route/RouterView';
import Loader from './components/animate/Loader';
import CustomToastContainer from './components/toast/CustomToastContainer';

const Router = () => {
  const { loading } = useContext(AppContext);
  const { isGetting, isAuthenticated } = useContext(AuthenticateContext);

  return !isGetting ? (
    <>
      <Switch>
        <Route exact path='/login' component={Login} />
        <Route
          exact
          path='/setengineerpassword'
          component={SetEngineerPassword}
        />
        <Route exact path='/setuserpassword' component={SetUserPassword} />
        <Route exact path='/403' component={ForbiddenPage} />
        <Route exact path='/404' component={NotFoundPage} />

        <Route
          path='/'
          render={() => {
            return isAuthenticated ? (
              <Layout>
                <RouterView />
              </Layout>
            ) : (
              <Redirect
                to={{
                  pathname: '/login',
                }}
              />
            );
          }}
        />
      </Switch>

      <Loader $loading={loading} />
      <CustomToastContainer />
    </>
  ) : (
    <Loader text='LOADING...' backgroundFill />
  );
};

export default Router;
