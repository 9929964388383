import styled from 'styled-components';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react';
import 'swiper/css';

const Slideshow = ({ images }) => {
  return (
    <Container>
      <Swiper spaceBetween={0} slidesPerView='auto'>
        <SlidePreviousButton />
        {images.map((image) => {
          return (
            <SwiperSlide>
              <Image src={image} alt='' />
            </SwiperSlide>
          );
        })}
        <SlideNextButton />
      </Swiper>
    </Container>
  );
};

const SlidePreviousButton = () => {
  const swiper = useSwiper();

  return (
    <Icon onClick={() => swiper.slidePrev()} className='left'>
      <FaAngleLeft />
    </Icon>
  );
};

const SlideNextButton = () => {
  const swiper = useSwiper();

  return (
    <Icon onClick={() => swiper.slideNext()} className='right'>
      <FaAngleRight />
    </Icon>
  );
};

const Container = styled.div`
  position: relative;

  .swiper-slide {
    width: auto;
  }
`;

const Icon = styled.span`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: var(--font-body1);
  position: absolute;
  top: 0;
  z-index: 2;

  &.left {
    left: 4px;
  }

  &.right {
    right: 4px;
  }

  > svg {
    border-radius: var(--border-radius-round);
    background: var(--color-background2);
    height: 32px;
    width: 32px;
  }
`;

const Image = styled.img`
  width: 180px;
  height: 320px;
  margin-right: var(--spacing-s);
  border-radius: var(--border-radius);
`;

export default Slideshow;
