import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { getLogList } from '../../module/log/api';
import { getCustomerServiceSimpleList } from '../../module/customerService/api';
import { error } from '../../module/message';
import handleApiResponse from '../../utils/api/handleApiResponse';
import formatDate from '../../utils/format/formatDate';
import Section from '../../components/section/Section';
import SectionHeader from '../../components/section/SectionHeader';
import SectionBody from '../../components/section/SectionBody';
import Heading1 from '../../components/heading/Heading1';
import { Grid, Column } from '../../components/grid/Grid';
import FormItem from '../../components/form/FormItem';
import Label from '../../components/form/Label';
import DatetimePickerV2 from '../../components/datetimePicker/DatetimePickerV2';
import Select from '../../components/select/Select';
import Checkbox from '../../components/form/Checkbox';
import Table from '../../components/table/Table';
import {
  useLimitChange,
  usePageChange,
  useSortChange,
} from '../../components/table/hooks';
import Button from '../../components/button/Button';
import ButtonGroup from '../../components/button/ButtonGroup';

const Search = () => {
  const { t } = useTranslation();
  const [filter, setFilter] = useState({
    start_time: formatDate(new Date().setHours(0, 0, 0, 0)),
    end_time: formatDate(new Date()),
    u_id: -1,
  });
  const [option, setOption] = useState([
    { text: 'CustomerService', checked: 1, name: 'user' },
    { text: 'Action', checked: 1, name: 'action' },
    { text: 'Status', checked: 1, name: 'status' },
    { text: 'Time', checked: 1, name: 'create_time' },
  ]);
  const [listData, setListData] = useState(null);
  const [total, setTotal] = useState(1);
  const [columns, setColumns] = useState([]);
  const [listParams, setListParams] = useState({
    searched: false,
    page: 1,
    limit: 10,
    sort_column: 'create_time',
    sort_type: 'desc',
    start_time: '',
    end_time: '',
    u_id: '',
  });
  const [userList, setUserList] = useState([]);

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);
  const onSortChange = useSortChange(
    setListParams,
    listParams.sort_column,
    listParams.sort_type
  );

  const handleGetLog = useCallback(() => {
    const requestData = {
      page: listParams.page,
      limit: listParams.limit,
      sort_column: listParams.sort_column,
      sort_type: listParams.sort_type,
      start_time: listParams.start_time,
      end_time: listParams.end_time,
    };

    if (listParams.u_id >= 0) {
      requestData.u_id = listParams.u_id;
    }

    handleApiResponse(getLogList(requestData), (response) => {
      const { logs, total } = response.data.data;
      setListData(logs);
      setTotal(total);
    });
  }, [listParams]);

  const handleChangefilter = (value, key) => {
    if (key === 'end_date' && value <= filter.start_date) return;

    setFilter((previous) => {
      const newFilter = { ...previous };
      newFilter[key] = value;

      return newFilter;
    });
  };

  const handleChangeOption = (index) => {
    option[index].checked = option[index].checked === 0 ? 1 : 0;

    setOption([...option]);
  };

  const updateColumns = useCallback(() => {
    return option
      .filter((item) => {
        return item.checked === 1;
      })
      .map((item) => {
        switch (item.text) {
          case 'CustomerService':
            return {
              title: t('CustomerService'),
              fieldName: 'first_name',
              sortable: true,
              render(data, full) {
                return `${full.first_name} ${full.last_name}`;
              },
            };
          case 'Action':
            return {
              title: t('Action'),
              fieldName: 'action',
              sortable: true,
            };
          case 'Status':
            return {
              title: t('Status'),
              fieldName: 'status',
              sortable: true,
            };
          case 'Time':
            return {
              title: t('Time'),
              fieldName: 'create_time',
              sortable: true,
            };
          default:
            return null;
        }
      });
  }, [option, t]);

  const handleSearch = () => {
    let checked = false;
    for (let key in option) {
      if (option[key].checked === 1) {
        checked = true;
        break;
      }
    }

    if (checked) {
      setColumns(updateColumns());
      for (let i in filter) {
        if (filter[i] !== listParams[i]) {
          setListParams((previousParams) => {
            return {
              ...previousParams,
              searched: true,
              page: 1,
              limit: 10,
              sort_column: 'create_time',
              sort_type: 'asc',
              start_time: filter.start_time,
              end_time: filter.end_time,
              u_id: filter.u_id,
            };
          });
          break;
        }
      }
      return false;
    } else {
      error({ message: t('error:LogOptionNotSelected') });
    }
  };

  const handleDownloadLog = (currentPage, data) => {
    const columns = option
      .filter((item) => {
        return item.checked === 1;
      })
      .map((item) => item.name);

    // do not export when no selected columns
    if (columns.length === 0) {
      return;
    }

    const requestData = {
      page: currentPage,
      limit: 1000,
      sort_column: listParams.sort_column,
      sort_type: listParams.sort_type,
      start_time: listParams.start_time,
      end_time: listParams.end_time,
    };

    if (listParams.u_id >= 0) {
      requestData.u_id = listParams.u_id;
    }

    handleApiResponse(getLogList(requestData), (response) => {
      const { logs, last_page } = response.data.data;

      let list = [];
      if (logs) {
        list = [...data, ...logs];
      }

      if (last_page > currentPage) {
        handleDownloadLog(currentPage + 1, list);
      } else {
        const headers = {};
        if (columns.indexOf('user') !== -1) {
          headers.user = t('CustomerService');
        }
        if (columns.indexOf('action') !== -1) {
          headers.action = t('Action');
        }
        if (columns.indexOf('status') !== -1) {
          headers.status = t('Status');
        }
        if (columns.indexOf('create_time') !== -1) {
          headers.create_time = t('Time');
        }

        // format the data
        const itemsFormatted = [];
        list.forEach((item) => {
          const data = {};
          if (columns.indexOf('user') !== -1) {
            data.user = `${item.first_name} ${item.last_name}`;
          }
          if (columns.indexOf('action') !== -1) {
            data.action = item.action;
          }
          if (columns.indexOf('status') !== -1) {
            data.status = item.status;
          }
          if (columns.indexOf('create_time') !== -1) {
            data.create_time = item.create_time;
          }
          itemsFormatted.push(data);
        });

        // call the exportCSVFile() function to process the JSON and trigger the download
        exportCSVFile(headers, itemsFormatted, 'Logs');
      }
    });
  };

  // call get log when searching
  useEffect(() => {
    if (!listParams.searched) return;
    handleGetLog();
  }, [handleGetLog, listParams.searched]);

  useEffect(() => {
    handleApiResponse(getCustomerServiceSimpleList(), (response) => {
      const { data } = response.data;

      const users = data || [];
      setUserList(
        [].concat(
          [{ id: -1, text: t('All') }],
          users.map((user) => {
            return {
              id: user.u_id,
              text: `${user.first_name} ${user.last_name}`,
            };
          })
        )
      );
    });
  }, [t]);

  return (
    <Section noPadding>
      <SectionHeader>
        <Heading1>{t('Log')}</Heading1>
      </SectionHeader>
      <SectionBody>
        <Section backgroundReverse>
          <SectionBody noPadding>
            <Grid columns={2}>
              <Column desktop={1}>
                <Grid columns={2}>
                  <Column desktop={1} tablet={2}>
                    <FormItem>
                      <Label htmlFor='startDate'>{t('StartDate')}</Label>
                      <DatetimePickerV2
                        selected={filter.start_time}
                        onConfirm={(date) => {
                          handleChangefilter(date, 'start_time');
                        }}
                      />
                    </FormItem>
                  </Column>

                  <Column desktop={1} tablet={2}>
                    <FormItem>
                      <Label htmlFor='endDate'>{t('EndDate')}</Label>
                      <DatetimePickerV2
                        selected={filter.end_time}
                        onConfirm={(date) => {
                          handleChangefilter(date, 'end_time');
                        }}
                        minDate={formatDate(
                          new Date(filter.start_time).setSeconds(1)
                        )}
                      />
                    </FormItem>
                  </Column>

                  <Column desktop={2}>
                    <FormItem>
                      <Label htmlFor='customerService'>
                        {t('CustomerService')}
                      </Label>
                      <Select
                        fullWidth
                        options={userList}
                        selected={filter.u_id}
                        onSelect={(id) => handleChangefilter(id, 'u_id')}
                      />
                    </FormItem>
                  </Column>
                </Grid>
              </Column>

              <Column desktop={1}>
                <Section backgroundReverse noPadding checkboxGroup>
                  <Label>{t('LogOption')}</Label>
                  {option.map((item, index) => {
                    return (
                      <div key={index}>
                        <Checkbox
                          htmlFor={item.text}
                          text={t(item.text)}
                          onChange={() => {
                            handleChangeOption(index);
                          }}
                          checked={option[index].checked}
                        />
                      </div>
                    );
                  })}
                </Section>
              </Column>
            </Grid>

            <ButtonGroup alignCenter>
              <Button onClick={handleSearch}>{t('button:Search')}</Button>
              <Button
                success
                onClick={() => {
                  handleDownloadLog(1, []);
                }}
                disabled={listData === null || listData.length === 0}
              >
                {t('button:Export')}
              </Button>
            </ButtonGroup>

            {listData !== null && (
              <Section backgroundReverse noPadding>
                <SectionBody>
                  <Table
                    backgroundReverse
                    columns={columns}
                    data={listData}
                    currentPage={listParams.page}
                    limit={listParams.limit}
                    onLimitChange={onLimitChange}
                    total={total}
                    onPageChange={onPageChange}
                    sortColumn={listParams.sort_column}
                    sortType={listParams.sort_type}
                    onSortChange={onSortChange}
                    translation={{
                      info: t('table.info'),
                      empty: t('table.empty'),
                    }}
                  />
                </SectionBody>
              </Section>
            )}
          </SectionBody>
        </Section>
      </SectionBody>
    </Section>
  );
};

const exportCSVFile = (headers, items, fileTitle) => {
  if (headers) items.unshift(headers);

  // Convert Object to JSON
  const jsonObject = JSON.stringify(items);
  const csv = convertToCSV(jsonObject);
  const exportedFilenmae = `${fileTitle}.csv` || 'export.csv';
  const blob = new Blob([csv], {
    type: 'text/csv;charset=utf-8;',
  });

  if (navigator.msSaveBlob) {
    // IE 10+
    navigator.msSaveBlob(blob, exportedFilenmae);
  } else {
    const link = document.createElement('a');
    if (link.download !== undefined) {
      // feature detection
      // Browsers that support HTML5 download attribute
      const url = URL.createObjectURL(blob);
      link.setAttribute('href', url);
      link.setAttribute('download', exportedFilenmae);
      link.style.visibility = 'hidden';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }
};

const convertToCSV = (objArray) => {
  const array = typeof objArray !== 'object' ? JSON.parse(objArray) : objArray;
  let str = '';
  for (let i = 0; i < array.length; i += 1) {
    let line = '';
    Object.values(array[i]).forEach((value) => {
      if (line !== '') line += ',';
      line += value;
    });
    str += `${line}\r\n`;
  }
  return str;
};

export default Search;
