import { useContext, useCallback } from 'react';
import styled from 'styled-components';
import TabContext from './TabContext';

const TabTab = ({ children, name, ...props }) => {
  const { selected, onChange } = useContext(TabContext);

  const handleClick = useCallback(() => {
    if (selected !== name) {
      onChange(name);
    }
  }, [name, selected, onChange]);

  return (
    <Tab role='tab' onClick={handleClick} active={selected === name} {...props}>
      {children}
    </Tab>
  );
};

const Tab = styled.button`
  background: transparent;
  color: var(--font-on-mute);
  padding: 10px var(--spacing-s);
  border: none;
  border-bottom: 4px solid transparent;
  transition: color 0.3s, border 0.3s;
  cursor: pointer;

  &:hover {
    color: var(--color-white);
  }

  ${({ active }) =>
    active &&
    `
      border-color: var(--color-primary);
      color: var(--color-white);
    `}
`;

export default TabTab;
