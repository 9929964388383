import { Redirect } from "react-router";

const DefaultRoute = () => {
  return [
    {
      path: "/",
      parent: false,
      component: () => {
        return <Redirect to="/work-order/in-progress/list" />;
      },
    },
  ];
};

export default DefaultRoute;
