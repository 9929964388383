import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    --color-white: ${({ theme }) => theme.colorWhite};
    --color-dark: ${({ theme }) => theme.colorDark};
    --color-grey: ${({ theme }) => theme.colorGrey};
    --color-primary: ${({ theme }) => theme.colorPrimary};
    --color-success: ${({ theme }) => theme.colorSuccess};
    --color-warning: ${({ theme }) => theme.colorWarning};
    --color-danger: ${({ theme }) => theme.colorDanger};
    --color-hover: ${({ theme }) => theme.colorHover};
    --color-active: ${({ theme }) => theme.colorActive};
    --color-scroll-bar: ${({ theme }) => theme.scrollBarColor};
    --color-background1: ${({ theme }) => theme.bgColor1};
    --color-background2: ${({ theme }) => theme.bgColor2};
    --color-background3: ${({ theme }) => theme.bgColor3};
    --color-background4: ${({ theme }) => theme.bgColor4};
    --color-backdrop: ${({ theme }) => theme.backdropBg};

    --font-h1: ${({ theme }) => theme.fontH1};
    --font-h2: ${({ theme }) => theme.fontH2};
    --font-h3: ${({ theme }) => theme.fontH3};
    --font-body1: ${({ theme }) => theme.fontBody1};
    --font-body2: ${({ theme }) => theme.fontBody2};
    --font-button: ${({ theme }) => theme.fontButton};
    --font-caption: ${({ theme }) => theme.fontCaption};
    --font-family: ${({ theme }) => theme.fontFamily};
    --font-normal: ${({ theme }) => theme.fontWeightNormal};
    --font-bold: ${({ theme }) => theme.fontWeightBold};
    --font-on-primary: ${({ theme }) => theme.fontOnPrimary};
    --font-on-success: ${({ theme }) => theme.fontOnSuccess};
    --font-on-warning: ${({ theme }) => theme.fontOnWarning};
    --font-on-danger: ${({ theme }) => theme.fontOnDanger};
    --font-on-background: ${({ theme }) => theme.fontOnBg};
    --font-on-mute: ${({ theme }) => theme.fontMutedColor};

    --box-shadow: ${({ theme }) => theme.boxShadow};
    --border-color: ${({ theme }) => theme.borderColor};
    --border-width: ${({ theme }) => theme.borderWidth};
    --border-radius-s: ${({ theme }) => theme.borderRadiusS};
    --border-radius: ${({ theme }) => theme.borderRadius};
    --border-radius-l: ${({ theme }) => theme.borderRadiusL};
    --border-radius-round: ${({ theme }) => theme.borderRadiusRound};

    --spacing-xs: ${({ theme }) => theme.spacerXS};
    --spacing-s: ${({ theme }) => theme.spacerS};
    --spacing: ${({ theme }) => theme.spacer};
    --spacing-l: ${({ theme }) => theme.spacerL};
  }

  .margin-bottom {
    margin-bottom: 16px;
  }
`;

export default GlobalStyle;
