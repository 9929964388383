import styled from 'styled-components';

const Message = styled.div.attrs(() => ({
  'data-testid': 'message-container',
}))`
  text-align: center;

  > svg {
    margin-bottom: var(--spacing-s);
    font-size: var(--font-h2);
  }

  ${({ dragging }) => (dragging ? 'opacity: 0.5' : '')};
`;

export default Message;
