import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import DropdownContext from "./DropdownContext";

const DropdownContent = ({ children, onShow, onClose }) => {
  const { display, setContentElement, styles, attributes, update } =
    useContext(DropdownContext);
  const [isToggle, setIsToggle] = useState(false);

  useEffect(() => {
    if (isToggle) {
      if (display && onShow) {
        onShow();
      }

      if (!display && onClose) {
        onClose();
      }

      setIsToggle(false);
    }
  }, [isToggle, display, onShow, onClose]);

  useEffect(() => {
    setIsToggle(true);
  }, [display]);

  //rerender時不會更新位置，所以補上children讓rerender時可以更新位置
  useEffect(() => {
    if (update) {
      update();
    }
  }, [update, children]);
  return (
    <Wrapper
      ref={setContentElement}
      $display={display}
      style={styles.popper}
      {...attributes.popper}
    >
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  visibility: ${({ $display }) => ($display === true ? "visible" : "hidden")};
  min-width: 160px;
  max-width: 320px;
  border: 1px solid var(--border-color);
  color: var(--font-on-background);
  background: var(--color-background2);
  box-shadow: var(--box-shadow);
  border-radius: var(--border-radius);
  padding: var(--spacing-s) 0;
  z-index: 20;
  margin: 0 !important;
`;

export default DropdownContent;
