import { useTranslation } from 'react-i18next';
import Section from '../../../components/section/Section';
import SectionBody from '../../../components/section/SectionBody';
import { Grid, Column } from '../../../components/grid/Grid';
import FormItem from '../../../components/form/FormItem';
import Label from '../../../components/form/Label';
import Input from '../../../components/form/Input';

const CustomerInformation = ({ data, handleChangeValue }) => {
  const { t } = useTranslation();

  return (
    <>
      <Section backgroundReverse noPaddingBottom>
        <SectionBody noPadding>
          <Grid columns={2}>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='nameCH' required>{`${t('Name')}(${t(
                  'CH'
                )})`}</Label>
                <Input
                  type='text'
                  id='nameCH'
                  value={data.name_ch}
                  maxLength='60'
                  onChange={(e) => handleChangeValue(e.target.value, 'name_ch')}
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='nameEN' required>{`${t('Name')}(${t(
                  'EN'
                )})`}</Label>
                <Input
                  type='text'
                  id='nameEN'
                  value={data.name_en}
                  maxLength='60'
                  onChange={(e) => handleChangeValue(e.target.value, 'name_en')}
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='number' required>
                  {t('Number')}
                </Label>
                <Input
                  type='text'
                  id='number'
                  value={data.MID}
                  maxLength='15'
                  onChange={(e) => handleChangeValue(e.target.value, 'MID')}
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='contactPerson' required>
                  {t('ContactPerson')}
                </Label>
                <Input
                  type='text'
                  id='contactPerson'
                  value={data.contact}
                  maxLength='60'
                  onChange={(e) => handleChangeValue(e.target.value, 'contact')}
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='phone' required>
                  {t('Phone')}
                </Label>
                <Input
                  type='text'
                  id='phone'
                  value={data.phone}
                  maxLength='15'
                  onChange={(e) => handleChangeValue(e.target.value, 'phone')}
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='address' required>
                  {t('Address')}
                </Label>
                <Input
                  type='text'
                  id='address'
                  value={data.address}
                  maxLength='200'
                  onChange={(e) => handleChangeValue(e.target.value, 'address')}
                />
              </FormItem>
            </Column>
          </Grid>
        </SectionBody>
      </Section>
    </>
  );
};

export default CustomerInformation;
