import { useRef, useState, useEffect, cloneElement } from 'react';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import styled from 'styled-components';
import { MdClose } from 'react-icons/md';
import media from '../../styles/mixin/media';
import scrollbar from '../../styles/mixin/scrollbar';
import appendToBody from '../../utils/appendToBody';
import isReactComponent from '../../utils/react/isReactComponent';
import Heading3 from '../heading/Heading3';
import ModalBackdrop from '../backdrop/ModalBackdrop';

const TitleModal = (props) => {
  const rootElement = appendToBody('TitleModalContainer');
  const Container = document.createElement('div');
  rootElement.appendChild(Container);

  const handleClose = (dismiss = false) => {
    // unmount component and clear event handler and state before remove container
    unmountComponentAtNode(Container);
    Container.remove();

    if (props.onClose) {
      props.onClose(dismiss);
    }
  };

  const methods = {
    close: handleClose,
  };

  ReactDOM.render(
    <ModalContent {...props} onClose={handleClose} methods={methods} />,
    Container
  );
};

const ModalContent = ({ children, onClose, methods, size }) => {
  const refWrapper = useRef(null);
  const [clickTarget, setClickTarget] = useState(null);

  const handleDismiss = (dismiss) => {
    if (clickTarget === refWrapper.current) {
      onClose(dismiss);
    }
  };

  return (
    <Wrapper
      ref={refWrapper}
      onClick={() => {
        handleDismiss(true);
      }}
      onMouseDown={(e) => {
        setClickTarget(e.target);
      }}
    >
      <Container
        size={size}
        onMouseDown={(e) => {
          setClickTarget(e.target);
        }}
      >
        {isReactComponent(children)
          ? cloneElement(children, methods)
          : children}
      </Container>
    </Wrapper>
  );
};

const ModalHeader = ({ handleClose, children }) => {
  return (
    <Header>
      <MdClose onClick={handleClose} />
      <Heading3>{children}</Heading3>
    </Header>
  );
};

const ModalBody = ({ children, ...props }) => {
  const bodyRef = useRef(null);

  const recalculateHeight = () => {
    // body element not render yet
    if (!bodyRef.current) return;
    const ModalBodyElement = bodyRef.current;
    const ModalHeaderElement = ModalBodyElement.previousSibling;
    const ModalFooterElement = ModalBodyElement.nextSibling;

    let needSubtractHeight = 0;
    if (ModalHeaderElement) {
      needSubtractHeight += ModalHeaderElement.offsetHeight;
    }

    if (ModalFooterElement) {
      needSubtractHeight += ModalFooterElement.offsetHeight;
    }

    ModalBodyElement.style.maxHeight = `calc(90vh - ${needSubtractHeight}px)`;
  };

  useEffect(() => {
    recalculateHeight();
    window.addEventListener('resize', recalculateHeight);

    return () => window.removeEventListener('resize', recalculateHeight);
  }, []);

  return (
    <Body ref={bodyRef} {...props}>
      {children}
    </Body>
  );
};

const ModalFooter = ({ children }) => {
  return <Footer>{children}</Footer>;
};

const Wrapper = styled(ModalBackdrop)`
  z-index: 80;
`;

const Container = styled.div`
  position: relative;
  border-radius: var(--border-radius);
  background: var(--color-background2);
  box-shadow: var(--box-shadow);
  border: var(--border-width) solid var(--border-color);
  width: 100%;

  ${({ size }) => {
    switch (size) {
      case 'large':
        return 'max-width: 75%;';
      case 'normal':
        return 'max-width: 50%;';
      case 'small':
        return 'max-width: 30%;';
      default:
        break;
    }
  }}

  ${media.tablet`
    ${({ size }) => {
      switch (size) {
        case 'large':
          return 'max-width: 90%;';
        case 'normal':
          return 'max-width: 75%;';
        case 'small':
          return 'max-width: 50%;';
        default:
          break;
      }
    }}
  `}

  ${media.mobile`
    ${({ size }) => {
      switch (size) {
        case 'normal':
          return 'max-width: 90%;';
        case 'small':
          return 'max-width: 70%;';
        default:
          break;
      }
    }}
  `}
`;

const Header = styled.div`
  display: flex;
  align-items: center;
  padding: var(--spacing-s) 56px var(--spacing-s) var(--spacing-s);
  border-bottom: var(--border-width) solid var(--border-color);
  min-height: 64px;
  color: var(--font-on-primary);

  > svg {
    position: absolute;
    top: 24px;
    right: calc(12px + var(--spacing));
    color: var(--font-on-mute);
    font-size: var(--font-body1);
    cursor: pointer;
  }
`;

const Body = styled.div`
  padding: var(--spacing);
  overflow-y: auto;
  overflow-x: hidden;

  ${scrollbar}

  ${({ noPaddingBottom }) => noPaddingBottom && 'padding-bottom: 0;'}
`;

const Footer = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 var(--spacing);
  border-top: var(--border-width) solid var(--border-color);
  height: 64px;
  line-height: 64px;
`;

export default Object.assign(TitleModal, {
  Header: ModalHeader,
  Body: ModalBody,
  Footer: ModalFooter,
});
