import { callApi } from '../../services/api';

const getEngineerList = (data) => {
  const params = {
    page: data.page,
    limit: data.limit,
    sort_column: data.sort_column,
    sort_type: data.sort_type,
    search: data.search,
    district_id: data.district_id,
  };

  return callApi.get('/get_engineer.php', {
    params: params,
  });
};

const getEngineerSimpleList = () => {
  return callApi.get('/get_engineer_simple.php');
};

const getDistrictSimpleList = () => {
  return callApi.get('/get_district.php');
};

const getEngineerInformation = (data) => {
  const params = {
    engineer_id: data.engineer_id,
  };

  return callApi.get('/get_engineer_info.php', {
    params: params,
  });
};

const createEngineer = (data) => {
  const params = {
    email: data.email,
    first_name: data.first_name,
    last_name: data.last_name,
    phone: data.phone,
    district_id: data.district_id,
    memo: data.memo,
  };

  return callApi.post('/create_engineer.php', params);
};

const modifyEngineer = (data) => {
  const params = {
    engineer_id: data.engineer_id,
    first_name: data.first_name,
    last_name: data.last_name,
    phone: data.phone,
    district_id: data.district_id,
    memo: data.memo,
  };

  return callApi.post('/set_engineer.php', params);
};

const removeEngineer = (data) => {
  const params = {
    engineer_id: data.engineer_id,
  };

  return callApi.post('/del_engineer.php', params);
};

const setEngineerPassword = (data) => {
  const params = {
    en_id: data.en_id,
    password: data.password,
    ticket: data.ticket,
  };

  return callApi.post('/new_engineer_password.php', params);
};

export {
  getEngineerList,
  getEngineerSimpleList,
  getDistrictSimpleList,
  getEngineerInformation,
  createEngineer,
  modifyEngineer,
  removeEngineer,
  setEngineerPassword,
};
