import { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import AppContext from '../../provider/context/app.context';
import appConfig from '../../config/app.config';
import Header from './Header';
import Container from './Container';
import Footer from './Footer';
import Sidebar from './Sidebar';
import Backdrop from './Backdrop';
import ScrollToTopBtn from '../button/ScrollToTopBtn';

const Layout = ({ children }) => {
  const { mode } = useContext(AppContext);
  const [display, setDisplay] = useState(true);

  const toggleSidebar = () => {
    setDisplay(!display);
  };

  // hide sidebar
  useEffect(() => {
    if (mode === 'tablet') {
      setDisplay(false);
    }
  }, [mode, setDisplay]);

  return (
    <Wrapper>
      <Header toggleSidebar={toggleSidebar} />
      <Main>
        <Backdrop $display={display} onClick={toggleSidebar}></Backdrop>
        <Sidebar $display={display} toggleSidebar={toggleSidebar} />
        <Container $display={display}>{children}</Container>
        <ScrollToTopBtn />
      </Main>
      <Footer $display={display}>{appConfig.version}</Footer>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background: var(--color-background1);
`;

const Main = styled.div`
  display: flex;
  flex: 1;
  background: var(--color-background1);
  position: relative;
`;

export default Layout;
