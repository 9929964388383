import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MdMoreHoriz, MdDelete } from 'react-icons/md';
import { FiFileText } from 'react-icons/fi';
import { FaFilter } from 'react-icons/fa';
import { success } from '../../module/message';
import {
  getEngineerList,
  getDistrictSimpleList,
  removeEngineer,
} from '../../module/engineer/api';
import handleApiResponse from '../../utils/api/handleApiResponse';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Section from '../../components/section/Section';
import SectionHeader from '../../components/section/SectionHeader';
import SectionBody from '../../components/section/SectionBody';
import SectionToolbar from '../../components/section/SectionToolbar';
import Heading1 from '../../components/heading/Heading1';
import Table from '../../components/table/Table';
import {
  useLimitChange,
  usePageChange,
  useSortChange,
} from '../../components/table/hooks';
import Dropdown, {
  DropdownItem,
  DropdownButtonOption,
  DropdownLinkOption,
} from '../../components/Dropdown';
import IconButton from '../../components/button/IconButton';
import TitleModal from '../../components/modal/TitleModal';
import StateModal from '../../components/modal/StateModal';
import SearchBox from '../../components/form/Searchbox';
import Button from '../../components/button/Button';
import ButtonGroup from '../../components/button/ButtonGroup';
import FormItem from '../../components/form/FormItem';
import Label from '../../components/form/Label';
import Select from '../../components/select/Select';

const List = () => {
  const { t } = useTranslation();
  const [areaSimpleList, setAreaSimpleList] = useState([]);
  const [listData, setListData] = useState([]);
  const [total, setTotal] = useState(1);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
    sort_column: 'first_name',
    sort_type: 'asc',
    search: '',
    district_id: -1,
  });

  const pathData = useMemo(() => {
    return [{ name: t('Engineer') }, { name: t('List') }];
  }, [t]);

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);
  const onSortChange = useSortChange(
    setListParams,
    listParams.sort_column,
    listParams.sort_type
  );

  const handleGetEngineer = useCallback(() => {
    const params = {
      page: listParams.page,
      limit: listParams.limit,
      sort_column: listParams.sort_column,
      sort_type: listParams.sort_type,
    };

    if (listParams.district_id >= 0) {
      params.district_id = listParams.district_id;
    }

    if (listParams.search) {
      params.search = listParams.search;
    }

    handleApiResponse(getEngineerList(params), (response) => {
      const { engineer, total } = response.data.data;
      setListData(engineer || []);
      setTotal(total);
    });
  }, [listParams]);

  const handleRemove = useCallback(
    (engineerId) => {
      StateModal({
        type: 'warning',
        text: t('YouWontBeAbleToRevertThis'),
        showCancelButton: true,
        confirmButtonText: t('button:Confirm'),
        cancelButtonText: t('button:Cancel'),
        onConfirm() {
          handleApiResponse(removeEngineer({ engineer_id: engineerId }), () => {
            success({
              message: t('success:RemoveEngineer'),
            });
            handleGetEngineer();
          });
        },
      });
    },
    [t, handleGetEngineer]
  );

  const generateActionDropdown = useCallback(
    ({ en_id }) => {
      return (
        <Dropdown key={en_id}>
          <Dropdown.Toggle>
            <IconButton>
              <MdMoreHoriz />
            </IconButton>
          </Dropdown.Toggle>
          <Dropdown.Content>
            <DropdownItem>
              <DropdownLinkOption to={`/engineer/${en_id}`}>
                <FiFileText />
                {t('Details')}
              </DropdownLinkOption>
            </DropdownItem>
            <DropdownItem onClick={() => handleRemove(en_id)}>
              <DropdownButtonOption>
                <MdDelete />
                {t('Remove')}
              </DropdownButtonOption>
            </DropdownItem>
          </Dropdown.Content>
        </Dropdown>
      );
    },
    [t, handleRemove]
  );

  const showFilterPanel = () => {
    TitleModal({
      size: 'small',
      children: (
        <EngineerListFilter
          listParams={listParams}
          setListParams={setListParams}
          areaSimpleList={areaSimpleList}
        />
      ),
    });
  };

  useEffect(() => {
    handleGetEngineer();
  }, [handleGetEngineer]);

  useEffect(() => {
    handleApiResponse(getDistrictSimpleList(), (response) => {
      const { district } = response.data.data;

      setAreaSimpleList(
        [].concat(
          [{ id: -1, text: t('All') }],
          district.map((district) => {
            return { id: district.district_id, text: district.district };
          })
        )
      );
    });
  }, [t]);

  return (
    <>
      <Breadcrumb path={pathData} />
      <Section noPadding>
        <SectionHeader sticky backgroundFill inline>
          <Heading1>{t('Engineer')}</Heading1>
          <SectionToolbar>
            <div>
              <SearchBox
                border
                onSearch={(keyword) => {
                  setListParams((previousParams) => {
                    return {
                      ...previousParams,
                      page: 1,
                      search: keyword,
                    };
                  });
                }}
              />

              <Button secondary onClick={showFilterPanel}>
                <FaFilter />
                {t('Filter')}
              </Button>
            </div>
          </SectionToolbar>
        </SectionHeader>
        <SectionBody>
          <Table
            columns={[
              {
                title: t('Name'),
                fieldName: 'first_name',
                sortable: true,
                render(data, full) {
                  return `${full.first_name} ${full.last_name}`;
                },
              },
              {
                title: t('District'),
                fieldName: 'district',
                sortable: true,
              },
              {
                title: t('Email'),
                fieldName: 'email',
                sortable: true,
              },
              {
                title: t('Phone'),
                fieldName: 'phone',
                sortable: true,
              },
              {
                title: t('Action'),
                custom: true,
                width: '10%',
                align: 'center',
                render(data) {
                  return generateActionDropdown(data);
                },
              },
            ]}
            data={listData}
            currentPage={listParams.page}
            limit={listParams.limit}
            onLimitChange={onLimitChange}
            total={total}
            sortColumn={listParams.sort_column}
            sortType={listParams.sort_type}
            onSortChange={onSortChange}
            onPageChange={onPageChange}
            translation={{ info: t('table.info'), empty: t('table.empty') }}
          />
        </SectionBody>
      </Section>
    </>
  );
};

const EngineerListFilter = ({
  listParams,
  setListParams,
  areaSimpleList,
  close,
}) => {
  const { t } = useTranslation();
  const [area, setArea] = useState(listParams.district_id);

  const handleSearch = () => {
    setListParams((previousParams) => {
      return {
        ...previousParams,
        page: 1,
        district_id: area,
      };
    });
    close();
  };

  return (
    <>
      <TitleModal.Header handleClose={close}>{t('Filter')}</TitleModal.Header>
      <TitleModal.Body noPaddingBottom>
        <FormItem>
          <Label>{t('District')}</Label>
          <Select
            fullWidth
            options={areaSimpleList}
            selected={area}
            onSelect={(area) => setArea(parseInt(area, 10))}
          />
        </FormItem>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={close}>
            {t('button:Cancel')}
          </Button>
          <Button success onClick={handleSearch}>
            {t('button:Search')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

export default List;
