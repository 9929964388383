import styled from 'styled-components';

const TableFooterPager = styled.div`
  display: flex;

  > :last-child {
    margin-left: var(--spacing-xs);
  }
`;

export default TableFooterPager;
