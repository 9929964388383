import { useCallback } from 'react';
import pad from '../utils/pad';

const useIncrease = (min, max, setState, config = {}) => {
  const { name, length, padString } = config;

  return useCallback(() => {
    setState((previous) => {
      if (name && typeof previous === 'object') {
        let value = parseInt(previous[name], 10) + 1;
        if (value > max) {
          value = min;
        }

        const newState = { ...previous };
        newState[name] = pad(value, length, padString);

        return newState;
      }

      let value = parseInt(previous, 10) + 1;
      if (value > max) {
        value = min;
      }

      return pad(value, length, padString);
    });
  }, [min, max, setState, name, length, padString]);
};

export default useIncrease;
