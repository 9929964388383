import styled from "styled-components";
import BaseBackdrop from "../backdrop/BaseBackdrop";
import media from "../../styles/mixin/media";

const Backdrop = styled(BaseBackdrop)`
  visibility: hidden;

  ${media.tablet`
    visibility: ${({ $display }) => ($display === true ? "visible" : "hidden")};
  `}
`;

export default Backdrop;
