import { useContext, useEffect, useState, useMemo } from 'react';
import styled from 'styled-components';
import TabContext from './TabContext';

const TabPanel = ({ children, name, ...props }) => {
  const { selected } = useContext(TabContext);
  const [rendered, setRendered] = useState(false);

  const tabContent = useMemo(() => {
    if (rendered) {
      return children;
    } else {
      return null;
    }
  }, [rendered, children]);

  useEffect(() => {
    if (name === selected) {
      setRendered(true);
    }
  }, [selected, name]);

  return (
    <Panel role='tabpanel' $display={selected === name} {...props}>
      {tabContent}
    </Panel>
  );
};

const Panel = styled.div`
  display: ${({ $display }) => ($display ? 'block' : 'none')};
  position: relative;
`;

export default TabPanel;
