const MONTH = [
  { number: '01', text: 'Jan' },
  { number: '02', text: 'Feb' },
  { number: '03', text: 'Mar' },
  { number: '04', text: 'Apr' },
  { number: '05', text: 'May' },
  { number: '06', text: 'Jun' },
  { number: '07', text: 'Jul' },
  { number: '08', text: 'Aug' },
  { number: '09', text: 'Sep' },
  { number: '10', text: 'Oct' },
  { number: '11', text: 'Nov' },
  { number: '12', text: 'Dec' },
];

export { MONTH };
