import styled, { css } from "styled-components";
import headerStyle from "../../styles/header.style";
import sidebarStyle from "../../styles/sidebar.style";
import media from "../../styles/mixin/media";

const Container = styled.div`
  transition: 0.3s;
  width: calc(100% - ${sidebarStyle.close.width});
  margin-left: ${sidebarStyle.close.width};
  padding: calc(${headerStyle.height} + var(--spacing)) var(--spacing)
    var(--spacing);

  ${({ $display }) =>
    $display === true &&
    css`
      width: calc(100% - ${sidebarStyle.open.width});
      margin-left: ${sidebarStyle.open.width};
    `}

  ${media.tablet`
    width: 100%;
    margin-left: 0;
  `}
`;

export default Container;
