import { useState, useMemo, useEffect, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { getDistrictSimpleList } from '../../module/engineer/api';
import {
  getEngineerInformation,
  modifyEngineer,
} from '../../module/engineer/api';
import { success, error } from '../../module/message';
import handleApiResponse from '../../utils/api/handleApiResponse';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Section from '../../components/section/Section';
import SectionHeader from '../../components/section/SectionHeader';
import SectionBody from '../../components/section/SectionBody';
import Heading1 from '../../components/heading/Heading1';
import { Grid, Column } from '../../components/grid/Grid';
import FormItem from '../../components/form/FormItem';
import Label from '../../components/form/Label';
import Input from '../../components/form/Input';
import Select from '../../components/select/Select';
import Textarea from '../../components/form/Textarea';
import Button from '../../components/button/Button';
import ButtonGroup from '../../components/button/ButtonGroup';

const Detail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [areaSimpleList, setAreaSimpleList] = useState([]);
  const [modifyMode, setModifyMode] = useState(false);
  const [previousData, setPreviousData] = useState({});
  const [data, setData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    district_id: '',
    memo: '',
  });

  const pathData = useMemo(() => {
    return [
      { name: t('Engineer') },
      { name: t('List'), url: '/engineer/list' },
      { name: t('Details') },
    ];
  }, [t]);

  const handleGetEngineerInformation = useCallback(() => {
    handleApiResponse(
      getEngineerInformation({ engineer_id: id }),
      (response) => {
        const { data } = response.data;
        const information = {
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          phone: data.phone,
          district_id: data.district_id,
          memo: data.memo,
        };
        setData(information);
        setPreviousData(information);
      }
    );
  }, [id]);

  const handleChangeValue = (value, key) => {
    const newData = { ...data };
    newData[key] = value;

    setData(newData);
  };

  const handleCancelModify = () => {
    setData(previousData);
    setModifyMode(false);
  };

  const handleModify = () => {
    if (!data.first_name.trim()) {
      error({ message: t('error:PleaseFillUpFirstName') });
      return;
    }

    if (!data.last_name.trim()) {
      error({ message: t('error:PleaseFillUpLastName') });
      return;
    }

    if (!data.phone.trim()) {
      error({ message: t('error:PleaseFillUpPhoneNumber') });
      return;
    }

    if (!data.district_id) {
      error({ message: t('error:PleaseSelectADistrict') });
      return;
    }

    handleApiResponse(
      modifyEngineer({
        engineer_id: id,
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phone,
        district_id: data.district_id,
        memo: data.memo,
      }),
      () => {
        success({ message: t('success:ModifyEngineer') });
        handleGetEngineerInformation();
        setModifyMode(false);
      }
    );
  };

  useEffect(() => {
    handleApiResponse(getDistrictSimpleList(), (response) => {
      const { district } = response.data.data;
      const data = district.map((district) => {
        return { id: district.district_id, text: district.district };
      });
      setAreaSimpleList(data);
    });
  }, []);

  useEffect(() => {
    handleGetEngineerInformation();
  }, [handleGetEngineerInformation]);

  return (
    <>
      <Breadcrumb path={pathData} />
      <Section noPadding>
        <SectionHeader>
          <Heading1>{t('EngineerDetails')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Section backgroundReverse noPaddingBottom>
            <SectionBody noPadding>
              <Grid columns={2}>
                <Column desktop={2}>
                  <FormItem>
                    <Label htmlFor='email'>{t('Email')}</Label>
                    <Input type='text' id='email' value={data.email} disabled />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='firstName' required={modifyMode}>
                      {t('FirstName')}
                    </Label>
                    <Input
                      type='text'
                      id='firstName'
                      value={data.first_name}
                      disabled={!modifyMode}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'first_name')
                      }
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='lastName' required={modifyMode}>
                      {t('LastName')}
                    </Label>
                    <Input
                      type='text'
                      id='lastName'
                      value={data.last_name}
                      disabled={!modifyMode}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'last_name')
                      }
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='phone' required={modifyMode}>
                      {t('Phone')}
                    </Label>
                    <Input
                      type='text'
                      id='phone'
                      value={data.phone}
                      disabled={!modifyMode}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'phone')
                      }
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='district' required={modifyMode}>
                      {t('District')}
                    </Label>
                    <Select
                      fullWidth
                      options={areaSimpleList}
                      selected={data.district_id}
                      disabled={!modifyMode}
                      onSelect={(district) =>
                        handleChangeValue(district, 'district_id')
                      }
                    />
                  </FormItem>
                </Column>
                <Column desktop={2}>
                  <FormItem>
                    <Label htmlFor='memo'>{t('Memo')}</Label>
                    <Textarea
                      id='memo'
                      rows='4'
                      value={data.memo}
                      disabled={!modifyMode}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'memo')
                      }
                    />
                  </FormItem>
                </Column>
              </Grid>
            </SectionBody>
          </Section>

          <ButtonGroup alignRight>
            {modifyMode ? (
              <>
                <Button danger onClick={handleCancelModify}>
                  {t('button:Cancel')}
                </Button>
                <Button success onClick={handleModify}>
                  {t('button:Save')}
                </Button>
              </>
            ) : (
              <Button
                warning
                onClick={() => {
                  setModifyMode(true);
                }}
              >
                {t('button:Modify')}
              </Button>
            )}
          </ButtonGroup>
        </SectionBody>
      </Section>
    </>
  );
};

export default Detail;
