/**
 * Convert the amount of data in byte to the largest unit
 *
 * @param {String|Integer} bytes Amount of data in byte
 *
 * @return {String}
 */
 const formatSize = (bytes) => {
  let str;

  if (bytes >= 1073741824) {
    str = `${(bytes / 1073741824).toFixed(2)} GB`;
  } else if (bytes >= 1048576) {
    str = `${(bytes / 1048576).toFixed(2)} MB`;
  } else if (bytes >= 1024) {
    str = `${(bytes / 1024).toFixed(2)} KB`;
  } else if (bytes > 1) {
    str = `${(bytes / 1).toFixed(2)} bytes`;
  } else if (bytes === 1) {
    str = `${bytes} byte`;
  } else {
    str = '0 bytes';
  }

  return str;
};

export default formatSize;
