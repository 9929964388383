import styled, { css } from 'styled-components';
import sidebarStyle from '../../styles/sidebar.style';
import media from '../../styles/mixin/media';

const Footer = styled.footer`
  transition: 0.3s;
  border-top: var(--border-width) solid var(--border-color);
  color: var(--font-on-mute);
  height: 64px;
  line-height: 64px;
  font-size: var(--font-body2);
  padding: 0 var(--spacing);
  margin: 0 var(--spacing) 0 calc(var(--spacing) + ${sidebarStyle.close.width});

  ${({ $display }) =>
    $display &&
    css`
      margin: 0 var(--spacing) 0
        calc(var(--spacing) + ${sidebarStyle.open.width});
    `}

  ${media.tablet`
    margin:0 ;
  `}
`;

export default Footer;
