import styled from "styled-components";
import media from "../../styles/mixin/media";

const Label = styled.label`
  display: block;
  width: 100%;
  color: #bdbfd0;
  font-size: 1.5rem;
  line-height: 1.5rem;

  ${media.mobile`
    font-size: 1.125rem;
    line-height: 1.125rem;
  `}
`;

export default Label;
