import {
  useState,
  cloneElement,
  forwardRef,
  useMemo,
  useCallback,
} from "react";
import styled from "styled-components";
import OutsideClickHandler from "react-outside-click-handler";
import useForkRef from "../../utils/hooks/useForkRef";
import useCustomPopper from "../../utils/hooks/useCustomPopper";

const Tooltip = forwardRef(
  (
    { children, id, title, showArrow = false, mode = "hover", fixed, inline },
    ref
  ) => {
    const [display, setDisplay] = useState(false);
    const [targetElement, setTargetElement] = useState(null);
    const [tooltipElement, setTooltipElement] = useState(null);
    const [arrowElement, setArrowElement] = useState(null);
    const ownRef = useForkRef(setTargetElement, ref);

    const { styles, attributes, state, update } = useCustomPopper(
      targetElement,
      tooltipElement,
      {
        strategy: fixed ? "fixed" : "absolute",
        arrowElement,
      }
    );

    const handleOpen = useCallback(() => {
      setDisplay(true);
      update();
    }, [update]);

    const handleClose = useCallback(() => {
      setDisplay(false);
      update();
    }, [update]);

    const toggle = useCallback(() => {
      setDisplay((display) => !display);
      update();
    }, [update]);

    const wraperProps = useMemo(() => {
      if (mode === "click") {
        return {
          onClick: toggle,
        };
      } else {
        return {
          onClick: handleClose,
          onMouseOver: handleOpen,
          onMouseLeave: handleClose,
        };
      }
    }, [mode, handleClose, handleOpen, toggle]);

    const childrenProps = {
      ...children.props,
      ref: ownRef,
    };

    return (
      <OutsideClickHandler
        onOutsideClick={handleClose}
        display={inline ? "inline-block" : "block"}
      >
        <div {...wraperProps}>
          {cloneElement(children, childrenProps)}
          <TooltipTooltip
            id={id}
            $display={display}
            ref={setTooltipElement}
            style={styles.popper}
            {...attributes.popper}
            placement={state && state.placement}
          >
            {title}
            <TooltipArrow
              ref={setArrowElement}
              style={styles.arrow}
              $display={showArrow}
            />
          </TooltipTooltip>
        </div>
      </OutsideClickHandler>
    );
  }
);

const TooltipTooltip = styled.div`
  white-space: pre-line;
  visibility: ${({ $display }) => ($display ? "visible" : "hidden")};
  border: 1px solid var(--border-color);
  color: var(--font-on-background);
  background: var(--color-background2);
  box-shadow: var(--box-shadow);
  border-radius: calc(var(--border-radius) / 2);
  padding: var(--spacing-xs);
  z-index: 80;
  text-align: left;
  font-size: var(--font-body1);
  font-weight: var(--font-normal);
  line-height: 1.5;
  word-break: break-word;
  max-width: 50vw;
`;

const TooltipArrow = styled.div`
  display: ${({ $display }) => ($display ? "block" : "none")};
  top: -0.5rem;
  left: 0;
  width: 1rem;
  height: 1rem;

  &::before {
    position: absolute;
    display: block;
    content: "";
    border-color: transparent;
    border-style: solid;
    top: 0;
    border-width: 0 0.5rem 0.5rem 0.5rem;
    border-bottom-color: var(--border-color);
  }
`;

export default Tooltip;
