import datetimeTFormat from '../../../utils/format/datetimeFormat';
import formatDate from '../../../utils/format/formatDate';

const GMTDate = (date) => {
  if (!date) return;

  let newDate = new Date(datetimeTFormat(date));
  newDate.setTime(newDate.getTime() + newDate.getTimezoneOffset() * 60 * 1000);
  newDate = formatDate(newDate);

  return newDate;
};

export default GMTDate;
