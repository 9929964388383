import { toast } from "react-toastify";
import MessageContainer from "../../components/toast/MessageContainer";

const success = ({ title, message, containHTML = false }) => {
  toast.success(
    <MessageContainer
      title={title}
      message={message}
      containHTML={containHTML}
    />
  );
};

const error = ({ title, message, containHTML = false }) => {
  toast.error(
    <MessageContainer
      title={title}
      message={message}
      containHTML={containHTML}
    />
  );
};

const warning = ({ title, message, containHTML = false }) => {
  toast.warning(
    <MessageContainer
      title={title}
      message={message}
      containHTML={containHTML}
    />
  );
};

export { success, error, warning };
