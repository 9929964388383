import styled from "styled-components";

const Divider = styled.hr`
  border: none;
  border-top: 1px solid var(--border-color);
  margin: var(--spacing-s);

  ${({ noMarginX }) => {
    return (
      noMarginX &&
      `
        margin-left: 0;
        margin-right: 0;
      `
    );
  }}
`;

export default Divider;
