import { css } from "styled-components";

const rounded = css`
  ${({ rounded }) => rounded && "border-radius: var(--border-radius);"}

  ${({ roundedCircle }) =>
    roundedCircle && "border-radius: var(--border-radius-round);"}
`;

export default rounded;
