import { useMemo, cloneElement, useRef, useState, useEffect } from 'react';
import { Transition } from 'react-transition-group';

const Collapse = ({
  in: inProp,
  duration = 1000,
  children,
  onEntered,
  onExit,
  onExited,
}) => {
  const childRef = useRef(null);
  const [state, setState] = useState({
    show: inProp,
    height: 0,
  });

  const defaultStyle = useMemo(() => {
    const style = {
      transition: `height ${duration}ms ease-in-out`,
      height: state.height,
      overflow: 'hidden',
    };

    return style;
  }, [duration, state.height]);

  const transitionStyles = useMemo(() => {
    return {
      entering: { height: state.height },
      entered: { height: state.height },
      exiting: { height: 0 },
      exited: { height: 0 },
    };
  }, [state.height]);

  useEffect(() => {
    setState({
      show: inProp,
      height: childRef.current.clientHeight,
    });
  }, [inProp]);

  return (
    <Transition
      in={state.show}
      timeout={duration}
      onEntered={onEntered}
      onExit={onExit}
      onExited={onExited}
    >
      {(state) => (
        <div
          style={{
            ...defaultStyle,
            ...transitionStyles[state],
          }}
        >
          {cloneElement(children, {
            ref: childRef,
          })}
        </div>
      )}
    </Transition>
  );
};

export default Collapse;
