import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import { IoMdArrowDropup } from 'react-icons/io';
import styled, { css } from 'styled-components';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import generateRange from '../../utils/array/generateRange';
import scrollbar from '../../styles/mixin/scrollbar';
import formatDate from '../../utils/format/formatDate';

const monthArr = [
  { number: '01', name: 'Jan' },
  { number: '02', name: 'Feb' },
  { number: '03', name: 'Mar' },
  { number: '04', name: 'Apr' },
  { number: '05', name: 'May' },
  { number: '06', name: 'Jun' },
  { number: '07', name: 'Jul' },
  { number: '08', name: 'Aug' },
  { number: '09', name: 'Sep' },
  { number: '10', name: 'Oct' },
  { number: '11', name: 'Nov' },
  { number: '12', name: 'Dec' },
];

const Accordion = ({
  type,
  handleDisplayAccordionContent,
  handleChangeTitle,
  setCurrentDisplayType,
  currentDisplayType,
  selectedYear,
  setSelectedYear,
  selectedMonth,
  setSelectedMonth,
  fixedYear,
  minDate,
  maxDate,
  selectedDay,
}) => {
  const bodyRef = useRef(null);
  const selectedYearRef = useRef(null);
  const [display, setDisplay] = useState(false);
  const [yearArr, setYearArr] = useState([]);

  const minYear = minDate ? new Date(minDate).getFullYear() : 1;
  const minMonth = minDate ? new Date(minDate).getMonth() : null;
  const maxYear = maxDate ? new Date(maxDate).getFullYear() : null;
  const maxMonth = maxDate ? new Date(maxDate).getMonth() : null;

  const accordionTitle = () => {
    if (type === 'year') {
      return selectedYear;
    } else if (type === 'month') {
      return monthArr[selectedMonth].name;
    }
  };
  const handleChangeMonth = useCallback(
    (month) => {
      setSelectedMonth(month);
      setCurrentDisplayType(null);
    },
    [setCurrentDisplayType, setSelectedMonth]
  );

  const handleChangeYear = useCallback(
    (year) => {
      setSelectedYear(year);
      setCurrentDisplayType(null);
    },
    [setCurrentDisplayType, setSelectedYear]
  );

  const handleYearItemDisable = useCallback(
    (year) => {
      if (minDate !== undefined && maxDate !== undefined) {
        return !(minYear <= year && year <= maxYear);
      }
      if (minDate) {
        return minYear > year;
      } else if (maxDate) {
        return maxYear < year;
      }

      return false;
    },
    [minYear, maxYear, maxDate, minDate]
  );

  const handleMonthItemDisable = useCallback(
    (month) => {
      const date = formatDate(new Date(selectedYear, month, selectedDay));

      if (minDate !== undefined && maxDate !== undefined) {
        return !(minDate <= date && date <= maxDate);
      } else if (minDate) {
        return !(minDate <= date);
      } else if (maxDate) {
        return !(date <= maxDate);
      }
      return false;
    },
    [maxDate, minDate, selectedYear, selectedDay]
  );

  const handleDisableArrow = useCallback(
    (arrowType) => {
      if (type === 'year') {
        if (arrowType === 'previous') {
          return minYear > selectedYear - 1;
        } else if (arrowType === 'next') {
          if (maxYear) {
            return maxYear < selectedYear + 1;
          }

          return false;
        }
      } else if (type === 'month') {
        const date =
          arrowType === 'previous'
            ? formatDate(
                new Date(
                  selectedYear,
                  selectedMonth - 1 < 0 ? 11 : selectedMonth - 1,
                  1
                )
              )
            : formatDate(
                new Date(
                  selectedYear,
                  selectedMonth + 1 > 11 ? 0 : selectedMonth + 1,
                  1
                )
              );

        if (fixedYear) {
          if (fixedYear && !minDate && !maxDate) {
            return false;
          }
        } else if (!minDate && !maxDate) {
          return false;
        } else {
          const minDate1st = formatDate(new Date(minYear, minMonth, 1));
          const maxDate1st = formatDate(new Date(maxYear, maxMonth, 1));

          if (minDate && !maxDate) {
            return !(minDate1st <= date);
          } else if (maxDate && !minDate) {
            return !(maxDate1st >= date);
          } else if (minDate && maxDate) {
            return !(minDate1st <= date && maxDate1st >= date);
          }
        }
      }
    },
    [
      minDate,
      selectedYear,
      selectedMonth,
      minYear,
      type,
      fixedYear,
      maxDate,
      maxYear,
      maxMonth,
      minMonth,
    ]
  );

  const MonthContent = useMemo(() => {
    return monthArr.map((month, index) => (
      <Item
        selected={selectedMonth === index}
        onClick={() => {
          handleChangeMonth(index);
        }}
        key={month.number}
        disabled={handleMonthItemDisable(index)}
      >
        {month.name}
      </Item>
    ));
  }, [handleChangeMonth, selectedMonth, handleMonthItemDisable]);

  const YearContent = useMemo(() => {
    return yearArr.map((year, index) => (
      <Item
        ref={selectedYear === year ? selectedYearRef : null}
        selected={selectedYear === year}
        onClick={() => {
          handleChangeYear(year);
        }}
        key={index}
        disabled={handleYearItemDisable(year)}
      >
        {year}
      </Item>
    ));
  }, [selectedYear, yearArr, handleChangeYear, handleYearItemDisable]);

  useEffect(() => {
    if (!fixedYear) {
      const start = selectedYear - 16 ? selectedYear - 16 : 1;
      const end = selectedYear + 16;
      const yearRange = generateRange(start, end);
      setYearArr(yearRange);
    }
  }, [fixedYear, selectedYear]);

  const scroll = useCallback(() => {
    if (bodyRef?.current && selectedYearRef?.current) {
      const body = bodyRef.current;

      if (body.scrollTop < 200) {
        body.removeEventListener('scroll', scroll);
        setTimeout(() => {
          body.scrollTo({
            top: body.scrollTop + 200,
          });
        }, 400);
        setTimeout(() => {
          setYearArr((previousParams) => {
            body.addEventListener('scroll', scroll);
            const addYearRange = generateRange(
              previousParams[0] - 15,
              previousParams[0] - 1
            );
            return [...addYearRange, ...previousParams];
          });
        }, 400);
      } else if (body.scrollTop + body.clientHeight >= body.scrollHeight) {
        body.removeEventListener('scroll', scroll);

        setTimeout(() => {
          setYearArr((previousParams) => {
            body.addEventListener('scroll', scroll);
            const addYearRange = generateRange(
              previousParams[previousParams.length - 1] + 1,
              previousParams[previousParams.length - 1] + 15
            );
            return [...previousParams, ...addYearRange];
          });
        }, 400);
      }
    }
  }, []);

  useEffect(() => {
    bodyRef.current.addEventListener('scroll', scroll);
  }, [scroll]);

  useEffect(() => {
    setDisplay(currentDisplayType === type);
  }, [currentDisplayType, type]);

  useEffect(() => {
    if (
      display &&
      bodyRef?.current &&
      currentDisplayType === 'year' &&
      selectedYearRef?.current
    ) {
      bodyRef.current.scrollTo({
        top: selectedYearRef.current.offsetTop - bodyRef.current.offsetTop,
      });
    }
  }, [currentDisplayType, display]);
  return (
    <>
      <Header>
        <IconButton
          onClick={() => {
            handleChangeTitle('previous');
          }}
          disabled={handleDisableArrow('previous')}
        >
          <MdArrowBackIosNew />
        </IconButton>
        <div>{accordionTitle()}</div>
        <IconButton
          onClick={() => {
            handleChangeTitle('next');
          }}
          disabled={handleDisableArrow('next')}
        >
          <MdArrowForwardIos />
        </IconButton>

        <IconButton
          $display={display}
          right
          onClick={handleDisplayAccordionContent}
        >
          <IoMdArrowDropup />
        </IconButton>
      </Header>
      <Body fixedYear={!fixedYear} ref={bodyRef} type={type} $display={display}>
        {type === 'month' && MonthContent}
        {type === 'year' && YearContent}
      </Body>
    </>
  );
};

const IconButton = styled.button`
  width: 36px;
  height: 36px;
  background: var(--color-background1);
  border: none;
  color: var(--color-white);
  padding: 0;
  transition: 0.3s;

  &:hover {
    color: var(--color-primary);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;

      &:hover {
        color: var(--color-white);
      }
    `}

  ${({ right, $display }) =>
    right &&
    `
    position: absolute;
    right: 0;
    ${
      $display &&
      css`
        transform: rotate(180deg);
      `
    }
  `}
`;

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;

  > div {
    width: 72px;
    line-height: 36px;
    text-align: center;
  }

  &::before {
    content: '';
    border-bottom: var(--border-width) solid var(--border-color);
    position: absolute;
    width: 300px;
    bottom: 0;
    z-index: 1;
    right: -16px;
  }
`;

const Body = styled.div`
  flex-wrap: wrap;
  height: 0;
  width: 100%;
  display: ${({ $display }) => ($display ? 'flex' : 'none')};
  ${({ type, fixedYear }) =>
    type &&
    css`
  min-height: ${type === 'year' ? '197' : fixedYear ? '144' : '192'}px};
  transition: .3s;
  
  ${
    type === 'year' &&
    css`
      overflow-y: scroll;
      gap: 12px 0;
      ${scrollbar}
    `
  };
  `}

  transition: 0.3s;
`;

const Item = styled.button`
  width: 84px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: var(--border-radius-s);
  padding: 0;
  border: none;
  color: var(--whiet);
  background: var(--color-background1);

  &:hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        background: var(--color-primary);
      `}
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--font-on-mute);
    `}

  ${({ selected }) =>
    selected &&
    css`
      background: var(--color-hover);
    `}
`;

export default Accordion;
