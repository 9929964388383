import { useState, useMemo, useEffect, useCallback, useContext } from 'react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  getCustomerInformation,
  modifyCustomer,
} from '../../module/customer/api';
import { success } from '../../module/message';
import handleApiResponse from '../../utils/api/handleApiResponse';
import AuthenticateContext from '../../provider/context/authenticate.context';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Section from '../../components/section/Section';
import SectionHeader from '../../components/section/SectionHeader';
import SectionBody from '../../components/section/SectionBody';
import Heading1 from '../../components/heading/Heading1';
import { Grid, Column } from '../../components/grid/Grid';
import FormItem from '../../components/form/FormItem';
import Label from '../../components/form/Label';
import Input from '../../components/form/Input';
import Textarea from '../../components/form/Textarea';
import Button from '../../components/button/Button';
import ButtonGroup from '../../components/button/ButtonGroup';

const Detail = () => {
  const { t } = useTranslation();
  const { userInformation } = useContext(AuthenticateContext);
  const { id } = useParams();
  const [modifyMode, setModifyMode] = useState(false);
  const [previousData, setPreviousData] = useState({});
  const [data, setData] = useState({
    merchant_id: '',
    name: '',
    contact: '',
    phone: '',
    address: '',
    memo: '',
  });

  const pathData = useMemo(() => {
    return [
      { name: t('Customer') },
      { name: t('List'), url: '/customer/list' },
      { name: t('Details') },
    ];
  }, [t]);

  const handleGetCustomerInformation = useCallback(() => {
    handleApiResponse(getCustomerInformation({ m_id: id }), (response) => {
      const { data } = response.data;
      const information = {
        merchant_id: data.merchant_id,
        name: data.name_ch,
        contact: data.contact,
        phone: data.phone,
        address: data.address,
        memo: data.Memo,
      };
      setData(information);
      setPreviousData(information);
    });
  }, [id]);

  const handleChangeValue = (value, key) => {
    const newData = { ...data };
    newData[key] = value;

    setData(newData);
  };

  const handleCancelModify = () => {
    setData(previousData);
    setModifyMode(false);
  };

  const handleModify = () => {
    handleApiResponse(
      modifyCustomer({
        m_id: id,
        memo: data.memo,
      }),
      () => {
        success({ message: t('success:ModifyCustomer') });
        handleGetCustomerInformation();
        setModifyMode(false);
      }
    );
  };

  useEffect(() => {
    handleGetCustomerInformation();
  }, [handleGetCustomerInformation]);

  return (
    <>
      <Breadcrumb path={pathData} />
      <Section noPadding>
        <SectionHeader>
          <Heading1>{t('CustomerDetails')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Section backgroundReverse noPaddingBottom>
            <SectionBody noPadding>
              <Grid columns={2}>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='merchantId'>{t('MerchantId')}</Label>
                    <Input
                      type='text'
                      id='merchantId'
                      value={data.merchant_id}
                      disabled
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='name'>{t('Name')}</Label>
                    <Input type='text' id='name' value={data.name} disabled />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='contact'>{t('Contact')}</Label>
                    <Input
                      type='text'
                      id='contact'
                      value={data.contact}
                      disabled
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='phone'>{t('Phone')}</Label>
                    <Input type='text' id='phone' value={data.phone} disabled />
                  </FormItem>
                </Column>
                <Column desktop={2}>
                  <FormItem>
                    <Label htmlFor='address'>{t('Address')}</Label>
                    <Input
                      type='text'
                      id='address'
                      value={data.address}
                      disabled
                    />
                  </FormItem>
                </Column>
                <Column desktop={2}>
                  <FormItem>
                    <Label htmlFor='memo'>{t('Memo')}</Label>
                    <Textarea
                      id='memo'
                      rows='4'
                      value={data.memo}
                      disabled={!modifyMode}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'memo')
                      }
                    />
                  </FormItem>
                </Column>
              </Grid>
            </SectionBody>
          </Section>

          {parseInt(userInformation.r_id, 10) !== 3 && (
            <ButtonGroup alignRight>
              {modifyMode ? (
                <>
                  <Button danger onClick={handleCancelModify}>
                    {t('button:Cancel')}
                  </Button>
                  <Button success onClick={handleModify}>
                    {t('button:Save')}
                  </Button>
                </>
              ) : (
                <Button
                  warning
                  onClick={() => {
                    setModifyMode(true);
                  }}
                >
                  {t('button:Modify')}
                </Button>
              )}
            </ButtonGroup>
          )}
        </SectionBody>
      </Section>
    </>
  );
};

export default Detail;
