import styled from "styled-components";
import scrollbar from "../../styles/mixin/scrollbar";

const Options = styled.div`
  position: relative;
  padding: var(--spacing-xs) 0;
  max-height: min(320px, calc(50vh - 36px - 20px - 36px));
  overflow-y: auto;
  overflow-x: visible;
  border-radius: var(--border-radius);

  ${({ allowSearch }) =>
    allowSearch &&
    `
      padding-top: 0;
      border-top-left-radius: 0;
      border-top-right-radius: 0;
    `}

  ${scrollbar}
`;
Options.displayName = "Options";

export default Options;
