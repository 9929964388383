import Tab from './Tab';
import TabList from './TabList';
import TabTab from './TabTab';
import TabPanel from './TabPanel';

export default Object.assign(Tab, {
  List: TabList,
  Tab: TabTab,
  Panel: TabPanel,
});
