import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MdMoreHoriz } from 'react-icons/md';
import { FiFileText } from 'react-icons/fi';
import { getCustomerList } from '../../module/customer/api';
import handleApiResponse from '../../utils/api/handleApiResponse';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Section from '../../components/section/Section';
import SectionHeader from '../../components/section/SectionHeader';
import SectionBody from '../../components/section/SectionBody';
import SectionToolbar from '../../components/section/SectionToolbar';
import Heading1 from '../../components/heading/Heading1';
import Table from '../../components/table/Table';
import {
  useLimitChange,
  usePageChange,
  useSortChange,
} from '../../components/table/hooks';
import Dropdown, {
  DropdownItem,
  DropdownLinkOption,
} from '../../components/Dropdown';
import IconButton from '../../components/button/IconButton';
import SearchBox from '../../components/form/Searchbox';

const List = () => {
  const { t } = useTranslation();
  const [listData, setListData] = useState([]);
  const [total, setTotal] = useState(1);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
    sort_column: 'merchant_id',
    sort_type: 'asc',
    search: '',
  });

  const pathData = useMemo(() => {
    return [{ name: t('Customer') }, { name: t('List') }];
  }, [t]);

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);
  const onSortChange = useSortChange(
    setListParams,
    listParams.sort_column,
    listParams.sort_type
  );

  const handleGetCustomer = useCallback(() => {
    const params = {
      page: listParams.page,
      limit: listParams.limit,
      sort_column: listParams.sort_column,
      sort_type: listParams.sort_type,
    };

    if (listParams.search) {
      params.search = listParams.search;
    }

    handleApiResponse(getCustomerList(params), (response) => {
      const { merchant, total } = response.data.data;
      setListData(merchant);
      setTotal(total);
    });
  }, [listParams]);

  const generateActionDropdown = useCallback(
    ({ m_id }) => {
      return (
        <Dropdown key={m_id}>
          <Dropdown.Toggle>
            <IconButton>
              <MdMoreHoriz />
            </IconButton>
          </Dropdown.Toggle>
          <Dropdown.Content>
            <DropdownItem>
              <DropdownLinkOption to={`/customer/${m_id}`}>
                <FiFileText />
                {t('Details')}
              </DropdownLinkOption>
            </DropdownItem>
          </Dropdown.Content>
        </Dropdown>
      );
    },
    [t]
  );

  useEffect(() => {
    handleGetCustomer();
  }, [handleGetCustomer]);

  return (
    <>
      <Breadcrumb path={pathData} />
      <Section noPadding>
        <SectionHeader sticky backgroundFill inline>
          <Heading1>{t('Customer')}</Heading1>
          <SectionToolbar>
            <div>
              <SearchBox
                border
                onSearch={(keyword) => {
                  setListParams((previousParams) => {
                    return {
                      ...previousParams,
                      page: 1,
                      search: keyword,
                    };
                  });
                }}
              />
            </div>
          </SectionToolbar>
        </SectionHeader>
        <SectionBody>
          <Table
            columns={[
              {
                title: t('MerchantId'),
                fieldName: 'merchant_id',
                sortable: true,
              },
              {
                title: t('Name'),
                fieldName: 'name',
                sortable: true,
              },
              {
                title: t('Contact'),
                fieldName: 'contact',
                sortable: true,
              },
              {
                title: t('Address'),
                fieldName: 'address',
                sortable: true,
              },
              {
                title: t('Action'),
                custom: true,
                width: '10%',
                align: 'center',
                render(data) {
                  return generateActionDropdown(data);
                },
              },
            ]}
            data={listData}
            currentPage={listParams.page}
            limit={listParams.limit}
            onLimitChange={onLimitChange}
            total={total}
            sortColumn={listParams.sort_column}
            sortType={listParams.sort_type}
            onSortChange={onSortChange}
            onPageChange={onPageChange}
            translation={{ info: t('table.info'), empty: t('table.empty') }}
          />
        </SectionBody>
      </Section>
    </>
  );
};

export default List;
