import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getDistrictSimpleList } from '../../module/engineer/api';
import { success, error } from '../../module/message';
import { createEngineer } from '../../module/engineer/api';
import handleApiResponse from '../../utils/api/handleApiResponse';
import { testEmail } from '../../utils/regex/check';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Section from '../../components/section/Section';
import SectionHeader from '../../components/section/SectionHeader';
import SectionBody from '../../components/section/SectionBody';
import Heading1 from '../../components/heading/Heading1';
import { Grid, Column } from '../../components/grid/Grid';
import FormItem from '../../components/form/FormItem';
import Label from '../../components/form/Label';
import Input from '../../components/form/Input';
import Button from '../../components/button/Button';
import ButtonGroup from '../../components/button/ButtonGroup';
import Select from '../../components/select/Select';
import Textarea from '../../components/form/Textarea';

const Create = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [areaSimpleList, setAreaSimpleList] = useState([]);
  const [data, setData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    district_id: '',
    memo: '',
  });

  const pathData = useMemo(() => {
    return [{ name: t('Engineer') }, { name: t('Create') }];
  }, [t]);

  const handleChangeValue = (value, key) => {
    const newData = { ...data };
    newData[key] = value;

    setData(newData);
  };

  const handleReset = () => {
    setData({
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
      district_id: '',
      memo: '',
    });
  };

  const handleCreate = () => {
    if (!data.email.trim()) {
      error({ message: t('error:PleaseFillUpEmail') });
      return;
    }

    if (!testEmail(data.email.trim())) {
      error({ message: t('error:PleaseFillUpValidEmailAddress') });
      return;
    }

    if (!data.first_name.trim()) {
      error({ message: t('error:PleaseFillUpFirstName') });
      return;
    }

    if (!data.last_name.trim()) {
      error({ message: t('error:PleaseFillUpLastName') });
      return;
    }

    if (!data.phone.trim()) {
      error({ message: t('error:PleaseFillUpPhoneNumber') });
      return;
    }

    if (!data.district_id) {
      error({ message: t('error:PleaseSelectADistrict') });
      return;
    }

    handleApiResponse(
      createEngineer({
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phone,
        district_id: data.district_id,
        memo: data.memo,
      }),
      () => {
        success({ message: t('success:CreateEngineer') });
        history.push({ pathname: '/engineer/list' });
      }
    );
  };

  useEffect(() => {
    handleApiResponse(getDistrictSimpleList(), (response) => {
      const { district } = response.data.data;
      const data = district.map((district) => {
        return { id: district.district_id, text: district.district };
      });
      setAreaSimpleList(data);
    });
  }, []);

  return (
    <>
      <Breadcrumb path={pathData} />
      <Section noPadding>
        <SectionHeader>
          <Heading1>{t('CreateEngineer')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Section backgroundReverse noPaddingBottom>
            <SectionBody noPadding>
              <Grid columns={2}>
                <Column desktop={2}>
                  <FormItem>
                    <Label htmlFor='email' required>
                      {t('Email')}
                    </Label>
                    <Input
                      type='text'
                      id='email'
                      value={data.email}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'email')
                      }
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='firstName' required>
                      {t('FirstName')}
                    </Label>
                    <Input
                      type='text'
                      id='firstName'
                      value={data.first_name}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'first_name')
                      }
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='lastName' required>
                      {t('LastName')}
                    </Label>
                    <Input
                      type='text'
                      id='lastName'
                      value={data.last_name}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'last_name')
                      }
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='phone' required>
                      {t('Phone')}
                    </Label>
                    <Input
                      type='text'
                      id='phone'
                      value={data.phone}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'phone')
                      }
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='district' required>
                      {t('District')}
                    </Label>
                    <Select
                      fullWidth
                      options={areaSimpleList}
                      selected={data.district_id}
                      onSelect={(district) =>
                        handleChangeValue(district, 'district_id')
                      }
                    />
                  </FormItem>
                </Column>
                <Column desktop={2}>
                  <FormItem>
                    <Label htmlFor='memo'>{t('Memo')}</Label>
                    <Textarea
                      id='memo'
                      rows='4'
                      value={data.memo}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'memo')
                      }
                    />
                  </FormItem>
                </Column>
              </Grid>
            </SectionBody>
          </Section>

          <ButtonGroup alignRight>
            <Button danger onClick={handleReset}>
              {t('button:Reset')}
            </Button>
            <Button success onClick={handleCreate}>
              {t('button:Create')}
            </Button>
          </ButtonGroup>
        </SectionBody>
      </Section>
    </>
  );
};

export default Create;
