import MockAdapter from 'axios-mock-adapter';

const MockGo = (axiosInstance) => {
  const mock = new MockAdapter(axiosInstance);

  mock.onPost('/auth/token').reply(200, {
    status: 200,
    data: {
      token:
        'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyIjoiYWRtaW4iLCJpYXQiOjE2MzIwNzIwNzIsImV4cCI6MTYzMjA3MjA3M30.    ',
    },
  });

  mock.onPost('/workorder/preview').reply(() => {
    return [
      200,
      {
        status: 200,
        data: {
          items: [
            {
              merchant: {
                name_ch: '商店中文名稱',
                name_en: 'merchant name',
                contact: 'Janaka',
                address: '地址',
                phone: '02-XXXXXXXX',
              },
              workorder: {
                type: 'P',
                Number: 'P220121385062001',
                'Request Class': 'B',
                MerchantID: '15838506200',
                Time: '2022-01-22',
                'Expect Instal. Date': '2022-01-25 17:00',
                'MCC Code': '7011',
                'Contact Person': '韓R',
                Phone: '28964506',
                Address: '台北市北投區光明路224號',
                'Header 1': '水美溫泉會館-訂房部',
                'Header 2': 'SWEET ME HOTSPRING PESORE',
                'Header 3': '',
                GPN: 'F',
                Service: 'On_Site_Service',
                Memo: ':「 修改表頭一 」',
              },
              terminal: {
                TerminalID: '50852797',
                type: 'Y',
                eq_type: 'EDC',
                Model: 'A910',
                TSN: '',
                'EDC Download Version': '520059',
                Handset: '1',
                Printer: '1',
                Logo: '0',
                '4DBC': 'N',
                ECR: 'N',
                'communication method': 'GPRS',
                Receipt_type: '2P',
                'IP Addr': '',
                'Host IP': '',
                Submask: '',
                Gateway: '',
                HostPort: '',
                AEPort: '',
                DinerPort: '',
              },
              parameter: {
                'FunctionRequest-Normal': {
                  S: 'O',
                  V: 'O',
                  Sett: 'O',
                  Refund: 'O',
                  Pre: 'O',
                  Tip: 'N',
                  Adj: 'N',
                  Referral: 'O',
                  Offline: 'O',
                  ManualPan: 'O',
                  'Ref No': 'N',
                  'Pre Com': 'N',
                  'Pre Inc': 'N',
                  'Pre Rev': 'N',
                  IdVer: 'N',
                  DRS_Flag: 'N',
                },
                AE_MID: '108104284',
                AE_TID: '77213261',
                AE_NewMid: '',
                'FunctionRequest-AMEX': {
                  S: 'O',
                  V: 'O',
                  Sett: 'O',
                  Refund: 'O',
                  Pre: 'O',
                  Tip: 'N',
                  Adj: 'N',
                  Referral: 'O',
                  Offline: 'O',
                  ManualPan: 'O',
                  'Ref No': 'O',
                  'Pre Com': 'N',
                  'Pre Inc': 'N',
                  'Pre Rev': 'N',
                  IdVer: 'N',
                  DRS_Flag: 'N',
                },
                'DFS Card': 'O',
                MO_MID: '',
                MO_TID: '',
                MO_NewMid: '',
                'FunctionRequest-MailOrder': {
                  S: 'N',
                  V: 'N',
                  Sett: 'N',
                  Refund: 'N',
                  Pre: 'N',
                  Tip: 'N',
                  Adj: 'N',
                  Referral: 'N',
                  Offline: 'N',
                  ManualPan: 'N',
                  'Ref No': 'N',
                  'No Swi-Pre': 'N',
                  'Pre Com': 'N',
                  'Pre Inc': 'N',
                  'Pre Rev': 'N',
                  IdVer: 'N',
                  DRS_Flag: 'N',
                },
                Choics_MID: '',
                Choics_TID: '',
                Choics_NewMid: '',
                'FunctionRequest-ChoiceCard': {
                  S: 'N',
                  V: 'N',
                  Sett: 'N',
                  Refund: 'N',
                  Pre: 'N',
                  Tip: 'N',
                  Adj: 'N',
                  Referral: 'N',
                  Offline: 'N',
                  ManualPan: 'N',
                  'Ref No': 'N',
                  'No Swi-Pre': 'N',
                  'Pre Com': 'N',
                  'Pre Inc': 'N',
                  'Pre Rev': 'N',
                  IdVer: 'N',
                  DRS_Flag: 'N',
                },
                DCC_MID: '15870414500',
                DCC_TerminalID: '50910406',
                DCC_MID2: '',
                'FunctionRequest-DCC': {
                  S: 'N',
                  V: 'N',
                  Sett: 'N',
                  Refund: 'N',
                  Pre: 'N',
                  Tip: 'N',
                  Adj: 'N',
                  Referral: 'N',
                  Offline: 'N',
                  ManualPan: 'N',
                  'Ref No': 'N',
                  'Pre Com': 'N',
                  'Pre Inc': 'N',
                  'Pre Rev': 'N',
                  IdVer: 'N',
                  DRS_Flag: 'N',
                },
                CUP_MID: '15880099600',
                CUP_TID: '50910406',
                CUP_MID2: '674015870110161',
                CUP_functions: {
                  Pre: 'O',
                  'Pre Com': 'O',
                  'Pre Rev': 'O',
                  'Pre Inc': 'O',
                  Sale: 'O',
                  'Sales Cancelled': 'O',
                  Return: 'N',
                  Manual: 'N',
                },
                FISC_MID: '',
                FISC_TID: '50852797',
                FISC_MID2: '',
                FISC_functions: {
                  Con: 'O',
                  Refund: 'O',
                  Reversal: 'O',
                  Checkout: 'O',
                  'Cabinet number': 'O',
                  IP: 'N',
                  PORT: 'N',
                },
                'ESUN wallet': 'X',
                ESUN_MID: '',
                ESUNWallet_functions: {
                  Alipay: 'X',
                  'WeChat Pay': 'X',
                  'LINE Pay': 'X',
                  jkopay: 'X',
                  GAMAPAY: 'X',
                  OPAY: 'X',
                },
                'easy wallet': 'X',
                easy_MID: '',
                Memo: '',
              },
            },
          ],
        },
        msg: 'success',
      },
    ];
  });

  mock
    .onGet(/\/workorder\/finished\/export\/[^/]+/)
    .reply(({ url, params }) => {
      console.log('Get: /workorder/finished/export/{fileType}', url, params);

      return [
        200,
        new Blob(['<InstallDispatchReply></InstallDispatchReply>'], {
          type: 'application/xml',
        }),
        { 'Content-Disposition': 'attachment; filename=I_001.xml' },
      ];
    });
};

export default MockGo;
