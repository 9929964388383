import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './resources/en';
import zhTW from './resources/zh-TW';

const getDomainWithoutSubdomain = (url) => {
  const urlParts = new URL(url).hostname.split('.');

  return urlParts
    .slice(0)
    .slice(-(urlParts.length === 4 ? 3 : 2))
    .join('.');
};

const supportedLngs = ['en', 'zh-TW'];
const resources = {
  en: en,
  'zh-TW': zhTW,
};

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    // debug: true,
    resources: resources,
    fallbackLng: supportedLngs[0],
    supportedLngs: supportedLngs,

    keySeparator: 'false',

    interpolation: {
      escapeValue: false,
    },
    detection: {
      order: ['cookie', 'navigator'],
      caches: ['cookie'],
      cookieDomain: getDomainWithoutSubdomain(window.location.href),
    },
  });

export default i18n;
