import { error } from "../../module/message";

const handleApiResponse = (promise, onSuccess, onFailure) => {
  promise
    .then((response) => {
      if (response && typeof onSuccess === "function") {
        onSuccess(response);
      }

      if (!response && typeof onFailure === "function") {
        onFailure();
      }
    })
    .catch((e) => {
      if (typeof onFailure === "function") {
        onFailure(e);
      }

      error({
        title: e.name,
        message: e.message,
      });
    });
};

export default handleApiResponse;
