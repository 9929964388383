import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import useQuery from '../../utils/hooks/useQuery';
import { replaceNonLatinSymbol } from '../../utils/regex/replace';
import handleApiResponse from '../../utils/api/handleApiResponse';
import { success, error } from '../../module/message';
import { setEngineerPassword } from '../../module/engineer/api';
import Wrapper from '../../components/login/Wrapper';
import Container from '../../components/login/Container';
import Logo from '../../components/login/Logo';
import Form from '../../components/login/Form';
import InputGroup from '../../components/login/InputGroup';
import Label from '../../components/login/Label';
import Input from '../../components/login/Input';
import FormButton from '../../components/login/FormButton';

const SetPassword = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const ticket = useQuery().get('ticket');
  const id = useQuery().get('en_id');
  const [password, setPassword] = useState('');

  const handleSubmit = (event) => {
    event.preventDefault();

    const trimedPassword = replaceNonLatinSymbol(password.trim());
    if (trimedPassword.length === 0) {
      error({ message: t('error:PleaseFillUpPassword') });
      return;
    }

    handleApiResponse(
      setEngineerPassword({
        en_id: id,
        password: trimedPassword,
        ticket: ticket,
      }),
      () => {
        success({ message: t('success:SetPassword') });
        history.push('/login');
      }
    );
  };

  return (
    <Wrapper>
      <Container>
        <Logo />

        <Form onSubmit={handleSubmit} $display={true}>
          <InputGroup>
            <Label htmlFor='password'>{t('Password')}:</Label>
            <Input
              id='password'
              type='password'
              value={password}
              placeholder={t('placeholder:password')}
              onChange={(e) => setPassword(e.target.value)}
              autoComplete='new-password'
            />
          </InputGroup>

          <FormButton
            buttons={[{ type: 'submit', text: t('button:SetPassword') }]}
          ></FormButton>
        </Form>
      </Container>
    </Wrapper>
  );
};

export default SetPassword;
