import { useRef, useState, useEffect } from 'react';
import styled from 'styled-components';
import { pdfjs, Document, Page } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';

pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const PDFViewer = ({ source, loadingText }) => {
  const documentRef = useRef();
  const [pagesCount, setPagesCount] = useState();
  const [documentWidth, setDocumentWidth] = useState(0);

  const handleDocumentLoadSuccess = ({ numPages: pagesCount }) => {
    setPagesCount(pagesCount);
  };

  const saveDocumentWidth = () => {
    setDocumentWidth(documentRef.current.getBoundingClientRect().width);
  };

  useEffect(() => {
    saveDocumentWidth();
    window.addEventListener('resize', saveDocumentWidth);

    return () => window.removeEventListener('resize', saveDocumentWidth);
  }, []);

  return (
    <Wrapper>
      <Document
        inputRef={documentRef}
        file={source}
        onLoadSuccess={handleDocumentLoadSuccess}
        options={options}
        loading={loadingText}
      >
        {Array.from(new Array(pagesCount), (el, index) => (
          <Page
            className='margin-bottom'
            key={`page_${index + 1}`}
            pageNumber={index + 1}
            width={documentWidth}
          />
        ))}
      </Document>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  .react-pdf__Document .react-pdf__message {
    padding: var(--font-body1);
    color: var(--font-on-background);
    text-align: center;
  }
`;

export default PDFViewer;
