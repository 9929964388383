import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { getDemandOrderInformation } from '../../module/demandOrder/api';
import handleApiResponse from '../../utils/api/handleApiResponse';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Section from '../../components/section/Section';
import SectionHeader from '../../components/section/SectionHeader';
import SectionBody from '../../components/section/SectionBody';
import Heading1 from '../../components/heading/Heading1';
import Tab from '../../components/Tab';
import BasicInformation from './detail/BasicInformation';
import WorkOrder from './detail/WorkOrder';

const Detail = () => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [selectedTab, setSelectedTab] = useState('basicInformation');
  const [previousData, setPreviousData] = useState({});
  const [data, setData] = useState({
    owner: '',
    owner_name: '',
    type_id: '',
    error_type: '',
    sales_id: '',
    problem: '',
    process_result: '',
  });

  const pathData = useMemo(() => {
    return [
      { name: t('DemandOrder') },
      { name: t('List'), url: '/demand-order/list' },
      { name: t('Details') },
    ];
  }, [t]);

  const handleGetDemandOrderInformation = useCallback(() => {
    handleApiResponse(getDemandOrderInformation({ cs_id: id }), (response) => {
      const { data } = response.data;
      const information = {
        owner: data.owner,
        owner_name: data.owner_name,
        type_id: data.type_id,
        error_type: data.troubleshooting_id,
        sales_id: data.sales_id,
        problem: data.problem,
        process_result: data.process_result,
      };
      setData(information);
      setPreviousData(information);
    });
  }, [id]);

  useEffect(() => {
    handleGetDemandOrderInformation();
  }, [handleGetDemandOrderInformation]);

  return (
    <>
      <Breadcrumb path={pathData} />
      <Section noPadding>
        <SectionHeader>
          <Heading1>{t('DemandOrderDetails')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Tab selected={selectedTab} onChange={setSelectedTab}>
            <Tab.List>
              <Tab.Tab name='basicInformation'>{t('BasicInformation')}</Tab.Tab>
              <Tab.Tab name='workOrder'>{t('WorkOrder')}</Tab.Tab>
            </Tab.List>

            <Tab.Panel name='basicInformation'>
              <BasicInformation
                previousData={previousData}
                data={data}
                setData={setData}
                handleGetDemandOrderInformation={
                  handleGetDemandOrderInformation
                }
              />
            </Tab.Panel>

            <Tab.Panel name='workOrder'>
              <WorkOrder owner={data.owner} />
            </Tab.Panel>
          </Tab>
        </SectionBody>
      </Section>
    </>
  );
};

export default Detail;
