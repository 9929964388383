import { useState, useCallback, useEffect, useMemo, useRef } from 'react';
import styled, { css } from 'styled-components';
import media from '../../styles/mixin/media';
import TH from './TableHeadCell';
import TableSort from './TableSort';
import TableFooter from './TableFooter';
import TableFooterInfo from './TableFooterInfo';
import TableFooterPager from './TableFooterPager';
import Paginate from '../paginate/Paginate';
import Select from '../select/Select';
import Popover from '../popover/Popover';

const Table = ({
  columns,
  data,
  currentPage,
  limit,
  total,
  onPageChange,
  onLimitChange,
  sortColumn,
  sortType,
  onSortChange,
  select,
  selected,
  onRowClick,
  backgroundReverse,
  translation,
  beforeCreateRow,
}) => {
  const tableRef = useRef(null);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(0);

  const texts = useMemo(() => {
    const defaultTexts = {
      info: 'Showing _START_ to _END_ of _TOTAL_ entries',
      empty: 'No data available in table',
    };

    return { ...defaultTexts, ...translation };
  }, [translation]);

  const handleSortChange = useCallback(
    (columnName) => {
      if (onSortChange) {
        onSortChange(columnName);
      }
    },
    [onSortChange]
  );

  const handleRowClick = useCallback(
    (e, rowData) => {
      if (onRowClick) {
        onRowClick(e, rowData);
      }
    },
    [onRowClick]
  );

  const showFullValue = useCallback((e) => {
    let id = e.target.id;
    if (!id) {
      id = e.currentTarget.id;
      e.target.id = id;
    }

    const containerLength = e.target.offsetWidth;
    const textLength = e.target.scrollWidth;
    if (textLength > containerLength) {
      e.stopPropagation();
      Popover({
        target: e.target,
        content: e.target.innerText,
        id: id,
        boundary: tableRef.current,
      });
    }
  }, []);

  // generate sort icon area
  const generateSort = useCallback(
    (fieldName) => {
      let isActiveAsc = false;
      let isActiveDesc = false;
      if (Array.isArray(sortColumn) && Array.isArray(sortType)) {
        const sortColumnIndex = sortColumn.indexOf(fieldName);
        if (sortColumnIndex !== -1) {
          if (sortType[sortColumnIndex] === 'asc') {
            isActiveAsc = true;
          }

          if (sortType[sortColumnIndex] === 'desc') {
            isActiveDesc = true;
          }
        }
      } else if (fieldName === sortColumn && sortType === 'asc') {
        isActiveAsc = true;
      } else if (fieldName === sortColumn && sortType === 'desc') {
        isActiveDesc = true;
      }

      return (
        <TableSort>
          <TableSort.UpIcon $active={isActiveAsc} />
          <TableSort.DownIcon $active={isActiveDesc} />
        </TableSort>
      );
    },
    [sortColumn, sortType]
  );

  const tableHeads = useMemo(() => {
    return columns.map((column, index) => {
      const { title, fieldName, width, sortable, align } = column;

      return (
        <TH
          width={width}
          key={`TH${index}`}
          sortable={sortable}
          onClick={() => {
            sortable && handleSortChange(fieldName);
          }}
          align={align}
        >
          <div>
            <span id={fieldName} onClick={showFullValue}>
              {title}
            </span>

            {sortable && generateSort(fieldName)}
          </div>
        </TH>
      );
    });
  }, [columns, handleSortChange, generateSort, showFullValue]);

  const tableRows = useMemo(() => {
    if (data.length === 0) {
      return null;
    }

    const { matcher, type } = select || {};
    return data.map((row, rowIndex) => {
      let active = false;
      let rowConfig = {};

      if (beforeCreateRow) {
        rowConfig = beforeCreateRow(row) || {};
      }

      // row data cell
      const tds = columns.map((column, columnIndex) => {
        const { title, fieldName, render, custom, ...props } = column;
        const key = rowIndex + columnIndex.toString();

        // 如果有matcher，比對當前 column 的 fieldName 是不是等於 matcher
        if (matcher && fieldName === matcher) {
          if (type === 'single' && selected === row[fieldName]) {
            active = true;
          }
          if (type === 'multiple' && selected.includes(row[fieldName])) {
            active = true;
          }
        }

        let isCustom = false;
        let renderedData = null;
        if (custom && render) {
          // call render function to get custom data(fully)
          isCustom = true;
          renderedData = render(row);
        } else {
          // call render function to get custom data(part)
          renderedData = (
            <span id={key} onClick={showFullValue}>
              {render ? render(row[fieldName], row) : String(row[fieldName])}
            </span>
          );
        }

        return (
          <TableDataCell
            key={columnIndex}
            data-label={title}
            backgroundReverse={backgroundReverse}
            custom={isCustom}
            {...props}
          >
            {renderedData}
          </TableDataCell>
        );
      });

      return (
        <BodyTR
          key={rowIndex}
          clickable={onRowClick}
          active={active}
          primary={rowConfig.primary}
          backgroundReverse={backgroundReverse}
          onClick={(e) => {
            handleRowClick(e, row);
          }}
        >
          {tds}
        </BodyTR>
      );
    });
  }, [
    backgroundReverse,
    beforeCreateRow,
    columns,
    data,
    handleRowClick,
    onRowClick,
    select,
    selected,
    showFullValue,
  ]);

  const generateEmptyMessage = useCallback(() => {
    return (
      <BodyTR noData backgroundReverse={backgroundReverse}>
        <TableDataCell
          noData
          center
          colSpan={columns.length}
          backgroundReverse={backgroundReverse}
        >
          {texts.empty}
        </TableDataCell>
      </BodyTR>
    );
  }, [backgroundReverse, columns, texts]);

  // calculate data range
  useEffect(() => {
    let start = 0;
    let end = currentPage * limit;

    if (total !== 0) {
      start = (currentPage - 1) * limit + 1;
    }

    if (end > total) {
      end = total;
    }

    setStart(start);
    setEnd(end);
  }, [currentPage, limit, total]);

  return (
    <>
      <ResponsiveTable ref={tableRef}>
        <TableHead>
          <tr>{tableHeads}</tr>
        </TableHead>
        <tbody>{tableRows || generateEmptyMessage()}</tbody>
      </ResponsiveTable>
      <TableFooter>
        <TableFooterInfo>
          {texts.info
            .replace('_START_', start)
            .replace('_END_', end)
            .replace('_TOTAL_', total)}
        </TableFooterInfo>
        <TableFooterPager>
          <Paginate
            currentPage={currentPage}
            totalCount={total}
            pageSize={limit}
            onPageChange={onPageChange}
          />
          {/* fix: page limit style */}
          <Select
            fill
            fillReverse={backgroundReverse}
            allowSearch={false}
            options={[
              { id: 10, text: '10' },
              { id: 20, text: '20' },
              { id: 30, text: '30' },
              { id: 40, text: '40' },
              { id: 50, text: '50' },
            ]}
            selected={limit}
            onSelect={onLimitChange}
          />
        </TableFooterPager>
      </TableFooter>
    </>
  );
};

const ResponsiveTable = styled.table`
  width: 100%;
  border-collapse: separate;
  margin-top: calc(var(--spacing-s) * -1);
  border-spacing: 0 var(--spacing-s);
  color: var(--font-on-primary);
  table-layout: fixed;
`;

const TableHead = styled.thead`
  ${media.mobile`
    display: none;
  `}
`;

const BodyTR = styled.tr`
  &:hover {
    > td {
      border-color: var(--color-primary);
    }
  }

  ${({ active, primary }) =>
    (active || primary) &&
    css`
      > td {
        background: var(--color-background4);
      }
    `}

  ${({ clickable }) => clickable && `pointer: cursor;`}
    
  ${media.mobile`
    overflow: hidden;
    position: relative;
    transition: .3s;
    display: block;
    margin-bottom: 0.75rem;
    padding: var(--spacing-xs) var(--spacing-s);
    background: var(
      --color-background${({ backgroundReverse }) =>
        backgroundReverse ? 1 : 2}
    );
    border-radius: var(--border-radius);
    transition: .3s;
    border: 1px solid transparent;

    &:hover {
      border: var(--border-width) solid var(--color-primary);
    }

    ${({ active, primary }) =>
      (active || primary) &&
      css`
        background: var(--color-background4);
      `}

    ${({ noData }) =>
      noData &&
      css`
        height: 168px;

        > td {
          &:first-child:before {
            display: none;
          }
        }
      `}
  `}
`;

const TableDataCell = styled.td`
  height: 48px;
  background: ${({ backgroundReverse }) =>
    backgroundReverse
      ? 'var(--color-background1)'
      : 'var(--color-background2)'};
  padding: 0 var(--spacing-xs);
  max-width: 0px;
  text-align: ${({ center }) => (center ? 'center' : 'left')};
  vertical-align: middle;
  border-top: var(--border-width) solid transparent;
  border-bottom: var(--border-width) solid transparent;
  transition: 0.3s;

  &:first-child {
    border-left: var(--border-width) solid transparent;
    border-top-left-radius: var(--border-radius-l);
    border-bottom-left-radius: var(--border-radius-l);
    padding-left: var(--spacing);
  }

  &:last-child {
    border-right: var(--border-width) solid transparent;
    border-top-right-radius: var(--border-radius-l);
    border-bottom-right-radius: var(--border-radius-l);
    padding-right: var(--spacing);
  }

  > span {
    display: inline-block;
    width: 100%;
    padding: 0 2px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  ${({ noData }) =>
    noData &&
    css`
      padding: 20px 0;
    `}

  ${media.mobile`
    display: flex;
    height: auto;
    margin-bottom: var(--spacing-xs);
    background: transparent !important;
    border: none !important;
    min-width: 100%;
    
    &:first-child {
      padding: var(--spacing-xs) 0 0 var(--spacing-xs);
    }

    &:last-child {
      padding-right: var(--spacing-xs);
    }

    &:before {
      content: attr(data-label) ": ";
      font-weight: bold;
      padding-right:  var(--spacing-xs);
    }

    > span {
      flex: 1;
    }
    
    ${({ custom }) =>
      custom &&
      css`
        &:last-child:before {
          display: none;
        }

        &:last-child {
          padding: 0;
          position: absolute;
          right: 0.75rem;
          top: var(--spacing-xs);
          justify-content: flex-end;
        }
      `}
  `}
`;

export default Table;
