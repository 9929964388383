import { useState } from 'react';
import ReactDOM, { unmountComponentAtNode } from 'react-dom';
import styled from 'styled-components';
import OutsideClickHandler from 'react-outside-click-handler';
import appendToBody from '../../utils/appendToBody';
import useCustomPopper from '../../utils/hooks/useCustomPopper';

const Popover = ({ id, ...props }) => {
  const rootElement = appendToBody('PopoverContainer');

  const handleClose = () => {
    // unmount component and clear event handler and state before remove container
    unmountComponentAtNode(rootElement);
    rootElement.remove();
  };

  const result = document.getElementById(`popover${id}`);
  // found previous element mean want to close popover now, so remove it
  if (result) {
    handleClose();
    return;
  }

  ReactDOM.render(
    <Box {...props} id={`popover${id}`} handleClose={handleClose} />,
    rootElement
  );
};

const Box = ({ target, id, handleClose, content, boundary }) => {
  const [popoverElement, setTooltipElement] = useState(null);

  const { styles, attributes, state } = useCustomPopper(
    target,
    popoverElement,
    {
      boundary,
    }
  );

  return (
    <OutsideClickHandler
      onOutsideClick={(e) => {
        if (`popover${e.target.id}` !== id) {
          handleClose();
        }
      }}
    >
      <PopoverBox
        id={id}
        ref={setTooltipElement}
        style={styles.popper}
        {...attributes.popper}
        placement={state && state.placement}
      >
        {content}
      </PopoverBox>
    </OutsideClickHandler>
  );
};

const PopoverBox = styled.div`
  border: 1px solid var(--border-color);
  color: var(--font-on-background);
  background: var(--color-background2);
  box-shadow: var(--box-shadow);
  border-radius: calc(var(--border-radius) / 2);
  padding: var(--spacing-xs);
  z-index: 80;
  font-size: var(--font-body1);
  font-weight: var(--font-normal);
  max-width: 50vw;
  word-break: break-all;
`;

export default Popover;
