import styled from 'styled-components';

const ButtonGroup = styled.div`
  display: flex;
  align-items: center;

  > button:not(:first-child),
  *:not(:first-child) {
    margin-left: var(--spacing-s);
  }

  ${({ alignCenter }) => alignCenter && 'justify-content: center;'}

  ${({ alignRight }) => alignRight && 'justify-content: flex-end;'}

  ${({ marginBottom }) => marginBottom && 'margin-bottom: var(--spacing);'}

  ${({ vertical }) =>
    vertical &&
    `
    flex-direction: column;

    > button,
    > * {
      margin-left: 0 !important;
      margin-bottom: var(--spacing-s);
    }
  `}

  ${({ height100 }) => height100 && 'height: 100%;'}
`;

export default ButtonGroup;
