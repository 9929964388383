import DemandOrderIcon from '../assets/icons/demand-order.png';
import Create from '../pages/demandOrder/Create';
import List from '../pages/demandOrder/List';
import Detail from '../pages/demandOrder/Detail';

const DemandRoute = (userInformation) => {
  return [
    {
      path: '/demand-order',
      name: 'DemandOrderManagement',
      icon: DemandOrderIcon,
      parent: true,
      checkPermission() {
        return true;
      },
      child: [
        {
          path: '/create',
          name: 'Create',
          component: Create,
          checkPermission() {
            if (parseInt(userInformation.r_id, 10) !== 1) return false;

            return true;
          },
        },
        {
          path: '/list',
          name: 'DemandOrderList',
          component: List,
          checkPermission() {
            return true;
          },
        },
        {
          path: '/:id',
          component: Detail,
          checkPermission() {
            return true;
          },
        },
      ],
    },
  ];
};

export default DemandRoute;
