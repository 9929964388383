import styled from 'styled-components';
import BaseBackdrop from './BaseBackdrop';

const LoadingBackdrop = styled(BaseBackdrop)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: var(--color-primary);
`;

export default LoadingBackdrop;
