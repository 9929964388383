import styled from "styled-components";
import media from "../../styles/mixin/media";

const Container = styled.div`
  width: 540px;
  margin: 0 auto;
  position: relative;
  z-index: 1;

  ${media.mobile`
    width: 100%;
    max-width: 400px;
  `}
`;

export default Container;
