import styled from 'styled-components';

const Block = styled.div`
  display: flex;
  width: 100%;
  height: 36px;
  border: var(--border-width) solid var(--border-color);
  padding: 0px var(--spacing-s);
  border-radius: var(--border-radius-l);
  background: transparent;
  color: var(--font-on-background);
  transition: 0.3s;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  align-items: center;
`;

export default Block;
