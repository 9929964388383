import { css } from 'styled-components';

const inputPlaceholder = (...args) => {
  return css`
    ::placeholder {
      ${css(...args)}
    }

    ::-moz-placeholder {
      ${css(...args)}
      opacity: 1;
    }

    // Internet Explorer 10+
    :-ms-input-placeholder {
      ${css(...args)}
    }

    // Safari and Chrome
    ::-webkit-input-placeholder {
      ${css(...args)}
    }
  `;
};

export default inputPlaceholder;
