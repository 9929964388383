/**
 * Download a file without browser popup warning
 * @param {string} url The url of the file to download
 * @param {string} mime The media type of the file
 * @param {string} filename Set a new filename for the downloaded file (optional)
 */
const downloadFile = (url, mime, filename = '') => {
  if (filename.length === 0) {
    filename = url.split('/').pop();
  }

  const req = new XMLHttpRequest();
  req.open('GET', url, true);
  req.responseType = 'blob';
  req.onload = function () {
    const blob = new Blob([req.response], {
      type: mime,
    });

    const isIE = false || !!document.documentMode;
    if (isIE) {
      window.navigator.msSaveBlob(blob, filename);
    } else {
      const windowUrl = window.URL || window.webkitURL;
      const href = windowUrl.createObjectURL(blob);
      const a = document.createElement('a');
      a.setAttribute('download', filename);
      a.setAttribute('href', href);
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  };
  req.send();
};

export default downloadFile;
