import { useTranslation } from 'react-i18next';
import Section from '../../../components/section/Section';
import SectionBody from '../../../components/section/SectionBody';
import { Grid, Column } from '../../../components/grid/Grid';
import FormItem from '../../../components/form/FormItem';
import Label from '../../../components/form/Label';
import Input from '../../../components/form/Input';

const CustomerInformation = ({ data }) => {
  const { t } = useTranslation();

  return (
    <>
      <Section backgroundReverse noPaddingBottom>
        <SectionBody noPadding>
          <Grid columns={2}>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='number'>{t('Number')}</Label>
                <Input
                  type='text'
                  id='number'
                  value={data.merchant_id}
                  disabled
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='name'>{t('Name')}</Label>
                <Input type='text' id='name' value={data.name} disabled />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='contactPerson'>{t('ContactPerson')}</Label>
                <Input
                  type='text'
                  id='contactPerson'
                  value={data.contact_person}
                  disabled
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='phone'>{t('Phone')}</Label>
                <Input type='text' id='phone' value={data.phone} disabled />
              </FormItem>
            </Column>
            <Column desktop={2}>
              <FormItem>
                <Label htmlFor='address'>{t('Address')}</Label>
                <Input type='text' id='address' value={data.address} disabled />
              </FormItem>
            </Column>
          </Grid>
        </SectionBody>
      </Section>
    </>
  );
};

export default CustomerInformation;
