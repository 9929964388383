import LogIcon from '../assets/icons/log.png';
import Search from '../pages/log/Search';

const LogRoute = () => {
  return [
    {
      path: '/log',
      name: 'Log',
      icon: LogIcon,
      parent: false,
      component: Search,
      checkPermission() {
        return true;
      },
    },
  ];
};

export default LogRoute;
