import Profile from '../pages/profile/Profile';

const ProfileRoute = [
  {
    path: '/profile',
    parent: false,
    component: Profile,
  },
];

export default ProfileRoute;
