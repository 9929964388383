import {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { IoMdArrowDropup } from 'react-icons/io';
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md';
import styled, { css } from 'styled-components';
import DateTimePicker from '../DateTimePickerContext';
import scrollbar from '../../../styles/mixin/scrollbar';
import generateRange from '../../../utils/array/generateRange';
import datetimeTFormat from '../../../utils/format/datetimeFormat';

const Year = ({ expand, handleDisplayAccordionContent, handleChangeTitle }) => {
  const {
    selectedDate,
    setSelectedDate,
    minDate,
    maxDate,
    fixedYear,
    setAccordionCurrentDisplayType,
  } = useContext(DateTimePicker);

  const bodyRef = useRef(null);
  const selectedYearRef = useRef(null);
  const [yearArr, setYearArr] = useState([]);
  const minYear = minDate
    ? new Date(datetimeTFormat(minDate)).getFullYear()
    : 1;
  const maxYear = maxDate
    ? new Date(datetimeTFormat(maxDate)).getFullYear()
    : null;

  const handleChangeYear = useCallback(
    (year) => {
      const data = new Date(selectedDate);
      data.setFullYear(year);
      setSelectedDate(datetimeTFormat(data));
      setAccordionCurrentDisplayType(null);
    },
    [selectedDate, setAccordionCurrentDisplayType, setSelectedDate]
  );

  const handleYearItemDisable = useCallback(
    (year) => {
      if (minDate !== undefined && maxDate !== undefined) {
        return minYear <= year && year <= maxYear ? false : true;
      }
      if (minDate) {
        return minYear > year ? true : false;
      } else if (maxDate) {
        return maxYear < year ? true : false;
      }

      return false;
    },
    [minYear, maxYear, maxDate, minDate]
  );

  const handleDisableArrow = useCallback(
    (arrowType) => {
      const data = new Date(selectedDate);
      if (arrowType === 'previous') {
        return minYear > data.getFullYear() - 1 ? true : false;
      } else if (arrowType === 'next') {
        if (maxYear) {
          return maxYear < data.getFullYear() + 1 ? true : false;
        } else {
          return false;
        }
      }
    },
    [selectedDate, minYear, maxYear]
  );

  const yearContent = useMemo(() => {
    const data = new Date(selectedDate);

    return yearArr.map((year, index) => (
      <Item
        ref={data.getFullYear() === year ? selectedYearRef : null}
        selected={data.getFullYear() === year}
        onClick={() => {
          handleChangeYear(year);
        }}
        key={index}
        disabled={handleYearItemDisable(year)}
      >
        {year}
      </Item>
    ));
  }, [selectedDate, yearArr, handleChangeYear, handleYearItemDisable]);

  useEffect(() => {
    const data = new Date(selectedDate);

    if (!fixedYear) {
      const start = data.getFullYear() - 16 > 0 ? data.getFullYear() - 16 : 1;
      const end = data.getFullYear() + 16;
      const yearRange = generateRange(start, end);
      setYearArr(yearRange);
    }
  }, [fixedYear, selectedDate]);

  const scroll = useCallback(() => {
    if (bodyRef?.current && selectedYearRef?.current) {
      const body = bodyRef.current;
      if (body.scrollTop + body.clientHeight >= body.scrollHeight - 90) {
        body.removeEventListener('scroll', scroll);

        setTimeout(() => {
          setYearArr((previousParams) => {
            body.addEventListener('scroll', scroll);
            const addYearRange = generateRange(
              previousParams[previousParams.length - 1] + 1,
              previousParams[previousParams.length - 1] + 15
            );
            return [...previousParams, ...addYearRange];
          });
        }, 400);
      } else if (body.scrollTop < 50) {
        body.removeEventListener('scroll', scroll);

        setTimeout(() => {
          setYearArr((previousParams) => {
            body.addEventListener('scroll', scroll);
            if (previousParams[0] - 15 <= 0) {
              const addYearRange = generateRange(
                previousParams[0] - (previousParams[0] - 1),
                previousParams[0] - 1
              );
              return [...addYearRange, ...previousParams];
            } else {
              setTimeout(() => {
                body.scrollTo({
                  top: body.scrollTop + 96,
                });
              }, 400);

              const addYearRange = generateRange(
                previousParams[0] - 15,
                previousParams[0] - 1
              );

              return [...addYearRange, ...previousParams];
            }
          });
        }, 400);
      }
    }
  }, []);

  useEffect(() => {
    bodyRef.current.addEventListener('scroll', scroll);
  }, [scroll]);

  useEffect(() => {
    if (expand && bodyRef?.current && selectedYearRef?.current) {
      bodyRef.current.scrollTo({
        top: selectedYearRef.current.offsetTop - bodyRef.current.offsetTop,
      });
    }
  }, [expand]);
  return (
    <>
      <Header>
        <IconButton
          onClick={() => {
            handleChangeTitle('previous');
          }}
          disabled={handleDisableArrow('previous')}
        >
          <MdArrowBackIosNew />
        </IconButton>
        <div>{new Date(selectedDate).getFullYear()}</div>

        <IconButton
          onClick={() => {
            handleChangeTitle('next');
          }}
          disabled={handleDisableArrow('next')}
        >
          <MdArrowForwardIos />
        </IconButton>
        <IconButton
          rotate={expand}
          right
          onClick={handleDisplayAccordionContent}
        >
          <IoMdArrowDropup />
        </IconButton>
      </Header>
      <Body expand={expand} fixedYear={fixedYear} ref={bodyRef}>
        {yearContent}
      </Body>
    </>
  );
};

const Header = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  position: relative;

  > div {
    width: 72px;
    line-height: 36px;
    text-align: center;
  }

  &::before {
    content: '';
    border-bottom: var(--border-width) solid var(--border-color);
    position: absolute;
    width: 300px;
    bottom: 0;
    z-index: 1;
    right: -16px;
  }
`;

const IconButton = styled.button`
  width: 36px;
  height: 36px;
  background: var(--color-background1);
  border: none;
  color: var(--color-white);
  padding: 0;
  transition: 0.3s;

  &:hover {
    color: var(--color-primary);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;

      &:hover {
        color: var(--color-white);
      }
    `}

  ${({ right, rotate }) =>
    right &&
    `
    position: absolute;
    right: 0;
    ${
      rotate &&
      css`
        transform: rotate(180deg);
      `
    }
  `}
`;

const Body = styled.div`
  flex-wrap: wrap;
  height: 0;
  width: 100%;
  display: ${({ expand }) => (expand ? 'flex' : 'none')};

  ${({ fixedYear }) =>
    css`
    min-height: ${fixedYear ? '144' : '192'}px};
    transition: .3s;
    overflow-y: scroll;
    gap: 12px 0;
    ${scrollbar}
  `}

  transition: 0.3s;
`;

const Item = styled.button`
  width: 84px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: var(--border-radius-s);
  padding: 0;
  border: none;
  color: var(--whiet);
  background: var(--color-background1);

  &:hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        background: var(--color-primary);
      `}
  }

  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--font-on-mute);
    `}

  ${({ selected }) =>
    selected &&
    css`
      background: var(--color-hover);
    `}
`;

export default Year;
