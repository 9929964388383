import { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ImProfile } from 'react-icons/im';
import { FaSignOutAlt } from 'react-icons/fa';
import AuthenticateContext from '../../provider/context/authenticate.context';
import { logout } from '../../module/authenticate/api';
import handleApiResponse from '../../utils/api/handleApiResponse';
import Tooltip from '../tooltip/Tooltip';
import Dropdown, {
  DropdownItem,
  DropdownButtonOption,
  DropdownLinkOption,
} from '../Dropdown';
import IconButton from '../button/IconButton';
import IconFigure from '../icon/IconFigure';
import ImageFigure from '../image/ImageFigure';
import ImageFluid from '../image/ImageFluid';
import Divider from '../divider/Divider';
import DefaultUser from '../../assets/images/default-user.jpg';

const UserInfo = () => {
  const { t } = useTranslation();
  const { userInformation, removeAuthenticate } =
    useContext(AuthenticateContext);

  const handleLogout = () => {
    handleApiResponse(
      logout({
        u_id: userInformation.u_id,
      }),
      () => {
        removeAuthenticate();
      }
    );
  };

  return (
    <Dropdown fixed>
      <Dropdown.Toggle>
        <Tooltip
          fixed
          title={`${userInformation.first_name} ${userInformation.last_name}`}
        >
          <IconButton>
            <IconFigure size='large'>
              <ImageFluid
                crossOrigin='anonymous'
                src={userInformation.avatar || DefaultUser}
                alt={`${userInformation.first_name} ${userInformation.last_name}'s avatar`}
                roundedCircle
              />
            </IconFigure>
          </IconButton>
        </Tooltip>
      </Dropdown.Toggle>
      <Dropdown.Content>
        <Information>
          <Avatar small noBorder noMarginLeft>
            <ImageFluid
              crossOrigin='anonymous'
              src={userInformation.avatar || DefaultUser}
              alt={`${userInformation.first_name} ${userInformation.last_name}'s avatar`}
              roundedCircle
            />
          </Avatar>
          <Name>{`${userInformation.first_name} ${userInformation.last_name}`}</Name>
        </Information>

        <Divider />

        <DropdownItem>
          <DropdownLinkOption to='/profile'>
            <ImProfile />
            {t('Profile')}
          </DropdownLinkOption>
        </DropdownItem>

        <DropdownItem onClick={handleLogout}>
          <DropdownButtonOption>
            <FaSignOutAlt />
            {t('Logout')}
          </DropdownButtonOption>
        </DropdownItem>
      </Dropdown.Content>
    </Dropdown>
  );
};

const Information = styled.div`
  display: flex;
  align-items: center;
  padding: 0 var(--spacing-s);
`;

const Avatar = styled(ImageFigure)`
  padding: 0;
  margin-left: 0;
  margin-right: var(--spacing-s);
  flex: 0 0 auto;
`;

const Name = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export default UserInfo;
