import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import OutsideClickHandler from 'react-outside-click-handler';
import styled, { css } from 'styled-components';
import DateTimePicker from './DateTimePickerContext';
import datetimeTFormat from '../../utils/format/datetimeFormat';
import formatDate from '../../utils/format/formatDate';
import useCustomPopper from '../../utils/hooks/useCustomPopper';
import Button from '../button/Button';
import Input from '../form/Input';
import Month from './accordion/Month';
import Year from './accordion/Year';
import TimePicker from './TimePicker';
import GMTDate from './utils/GMTDate';

const DatetimePickerV2 = ({
  selected,
  fixedYear,
  maxDate,
  minDate,
  onConfirm,
  disabled,
}) => {
  const { t } = useTranslation();
  const [toggleElement, setToggleElement] = useState(null);
  const [inputValue, setInputValue] = useState(
    selected ? datetimeTFormat(selected) : ''
  );
  const [selectedDate, setSelectedDate] = useState(
    selected
      ? datetimeTFormat(GMTDate(selected))
      : datetimeTFormat(GMTDate(new Date()))
  );
  const [confirmDate, setConfirmDate] = useState(
    selected ? datetimeTFormat(selected) : ''
  );

  const [displayPicker, setDisplayPicker] = useState(false);
  const [contentElement, setContentElement] = useState(null);
  const [time, setTime] = useState(() => {
    const date = new Date(
      datetimeTFormat(selected ? selected : formatDate(new Date()))
    );
    if (!selected) date.setHours(0, 0, 0, 0);
    return `${date.getHours()}:${date.getMinutes()}:${date.getSeconds()}`;
  });
  const [accordionCurrentDisplayType, setAccordionCurrentDisplayType] =
    useState(null);
  const { styles, attributes, state, update } = useCustomPopper(
    toggleElement,
    contentElement,
    { strategy: 'absolute', placement: 'bottom-start' }
  );
  const context = {
    inputValue,
    selectedDate,
    setSelectedDate,
    minDate,
    maxDate,
    fixedYear,
    setAccordionCurrentDisplayType,
    setInputValue,
  };
  const handleToggle = () => {
    setDisplayPicker(!displayPicker);
    update();
  };

  const handleDisplayAccordionContent = useCallback(
    (type) => {
      if (accordionCurrentDisplayType === type) {
        setAccordionCurrentDisplayType(null);
      } else {
        setAccordionCurrentDisplayType(type);
      }
    },
    [accordionCurrentDisplayType]
  );

  const handleChangeYear = (selected) => {
    const date = new Date(selectedDate);
    if (selected === 'previous') {
      if (date.getFullYear() > 1) {
        date.setFullYear(date.getFullYear() - 1);
      }
    } else if (selected === 'next') {
      date.setFullYear(date.getFullYear() + 1);
    }
    setSelectedDate(datetimeTFormat(date));
    setAccordionCurrentDisplayType(null);
  };

  const handleChangeMonth = (selected) => {
    const date = new Date(selectedDate);

    if (selected === 'previous') {
      date.setMonth(date.getMonth() <= 0 ? 11 : date.getMonth() - 1);
    } else if (selected === 'next') {
      date.setMonth(date.getMonth() >= 11 ? 0 : date.getMonth() + 1);
    }

    setSelectedDate(datetimeTFormat(date));
    setAccordionCurrentDisplayType(null);
  };
  const handleConfirm = useCallback(() => {
    if (!confirmDate) return;
    setSelectedDate(confirmDate);
    onConfirm(inputValue);
    setDisplayPicker(false);
    setAccordionCurrentDisplayType(null);
  }, [confirmDate, inputValue, onConfirm]);

  const handleChangeDate = useCallback(
    (daysOfMonthForSelectedDate, firstWeekOfMonth, dayIndex, day) => {
      const date = new Date(selectedDate);
      // 先把日期設定成所有月分都有的日期，設定月份時才不會設定到非預期的date
      date.setDate(1);
      const selectedYear = date.getFullYear();
      const selectedMonth = date.getMonth();

      if (!fixedYear) {
        if (dayIndex < firstWeekOfMonth && selectedYear >= 1) {
          if (selectedMonth === 0) {
            date.setFullYear(selectedYear - 1);
            date.setMonth(11);
          } else {
            date.setMonth(selectedMonth - 1);
          }
        } else if (dayIndex >= daysOfMonthForSelectedDate + firstWeekOfMonth) {
          if (selectedMonth === 11) {
            date.setFullYear(selectedYear + 1);
            date.setMonth(0);
          } else {
            date.setMonth(selectedMonth + 1);
          }
        }
        date.setDate(day);

        setSelectedDate(datetimeTFormat(date));
        setConfirmDate(datetimeTFormat(date));
      } else if (fixedYear) {
        if (dayIndex < firstWeekOfMonth) {
          if (selectedMonth === 0) {
            return;
          }

          date.setMonth(selectedMonth - 1);
        } else if (dayIndex >= daysOfMonthForSelectedDate + firstWeekOfMonth) {
          if (selectedMonth === 11) {
            return;
          }

          date.setMonth(selectedMonth + 1);
        }

        date.setDate(day);

        setSelectedDate(datetimeTFormat(date));
        setConfirmDate(datetimeTFormat(date));
      }
    },
    [fixedYear, selectedDate]
  );

  const handleDisabled = useCallback(
    (
      firstWeekOfMonth,
      lastWeekOfMonth,
      daysOfMonthForSelectedDate,
      dayIndex,
      day
    ) => {
      let [year, month] = selectedDate.split('-');

      if (
        year === '0001' &&
        month === '01' &&
        firstWeekOfMonth >= dayIndex + 1
      ) {
        return true;
      }

      let myDay = day.toString();
      if (myDay.length === 1) myDay = `0${myDay}`;

      if (fixedYear) {
        if (month === '01' && dayIndex + 1 <= firstWeekOfMonth) {
          // disabledDateInJan
          return dayIndex < firstWeekOfMonth;
        } else if (month === '12' && day + firstWeekOfMonth < dayIndex + 1) {
          // disabledDateInDec
          return dayIndex >= daysOfMonthForSelectedDate + firstWeekOfMonth;
        }
      }

      if (
        month === '01' &&
        firstWeekOfMonth !== 0 &&
        dayIndex + 1 <= firstWeekOfMonth
      ) {
        year = (+year - 1).toString();
      } else if (
        month === '12' &&
        lastWeekOfMonth !== 6 &&
        day + firstWeekOfMonth < dayIndex + 1
      ) {
        year = (+year + 1).toString();
      }

      month =
        firstWeekOfMonth >= dayIndex + 1
          ? +month - 1
          : daysOfMonthForSelectedDate + firstWeekOfMonth < dayIndex + 1
          ? +month + 1
          : +month;

      if (month === 0) {
        month = 12;
      } else if (month === 13) {
        month = 1;
      }

      if (year.length === 1) year = `000${year}`;
      if (year.length === 2) year = `00${year}`;
      if (year.length === 3) year = `0${year}`;

      if (month.toString().length === 1) month = `0${month}`;
      else month = month.toString();

      const compareMinDateDate = formatDate(
        datetimeTFormat(
          new Date(
            GMTDate(formatDate(new Date(`${year}-${month}-${myDay}`)), 'date')
          ).setHours(23, 59, 59, 59)
        )
      );

      const compareMaxDateDate = formatDate(
        datetimeTFormat(
          new Date(
            GMTDate(formatDate(new Date(`${year}-${month}-${myDay}`)), 'date')
          ).setHours(0, 0, 0, 0)
        )
      );
      if (minDate && !maxDate && minDate > compareMinDateDate) {
        return true;
      }

      if (maxDate && !minDate && maxDate < compareMaxDateDate) {
        return true;
      }

      if (
        maxDate &&
        minDate &&
        (minDate > compareMinDateDate || maxDate < compareMaxDateDate)
      ) {
        return true;
      }
      return false;
    },
    [fixedYear, minDate, maxDate, selectedDate]
  );

  const handleSelectedDay = useCallback(
    (day, index, firstWeekOfMonth, lastWeekOfMonth, totalDays) => {
      const selectedDateObj = new Date(selectedDate);
      const confirmDateObj = new Date(confirmDate);

      if (
        confirmDateObj.getFullYear() === selectedDateObj.getFullYear() &&
        confirmDateObj.getMonth() === selectedDateObj.getMonth() &&
        confirmDateObj.getDate() === day
      ) {
        if (
          index + 1 > firstWeekOfMonth &&
          index < totalDays - (6 - lastWeekOfMonth)
        ) {
          return true;
        } else return false;
      } else return false;
    },
    [selectedDate, confirmDate]
  );

  const days = useMemo(() => {
    let date = new Date(selectedDate);
    // 先把日期設定成所有月分都有的日期，設定月份時才不會設定到非預期的date
    date.setDate(1);
    date.setMonth(date.getMonth() + 1);
    date.setDate(0);

    const daysOfMonthForSelectedDate = date.getDate();

    date = new Date(selectedDate);
    date.setMonth(date.getMonth() + 1 - 1);
    date.setDate(0);
    const daysOfPrevioueMonthForSelectedDate = date.getDate();

    date = new Date(selectedDate);
    // 先把日期設定成所有月分都有的日期，設定月份時才不會設定到非預期的date
    date.setDate(1);
    const firstWeekOfMonth = date.getDay();

    date = new Date(selectedDate);
    // 先把日期設定成所有月分都有的日期，設定月份時才不會設定到非預期的date
    date.setDate(1);
    date.setMonth(date.getMonth() + 1);
    date.setDate(0);
    const lastWeekOfMonth = date.getDay();

    const days = [];
    for (let day of [...Array(daysOfMonthForSelectedDate).keys()]) {
      days.push(day + 1);
    }

    if (firstWeekOfMonth !== 0) {
      for (
        let i = daysOfPrevioueMonthForSelectedDate;
        i > daysOfPrevioueMonthForSelectedDate - firstWeekOfMonth;
        i--
      ) {
        if (date.getFullYear() === 1 && date.getMonth() === 0) {
          days.unshift(null);
        } else days.unshift(i);
      }
    }

    if (lastWeekOfMonth !== 6) {
      for (let i = 1; i <= 6 - lastWeekOfMonth; i++) {
        days.push(i);
      }
    }

    return days.map((day, index, arr) => {
      return (
        <Day
          disabled={handleDisabled(
            firstWeekOfMonth,
            lastWeekOfMonth,
            daysOfMonthForSelectedDate,
            index,
            day
          )}
          onClick={() => {
            handleChangeDate(
              daysOfMonthForSelectedDate,
              firstWeekOfMonth,
              index,
              day
            );
          }}
          selected={handleSelectedDay(
            day,
            index,
            firstWeekOfMonth,
            lastWeekOfMonth,
            arr.length,
            daysOfMonthForSelectedDate
          )}
          key={index}
        >
          {day}
        </Day>
      );
    });
  }, [selectedDate, handleChangeDate, handleSelectedDay, handleDisabled]);

  const weeks = useMemo(() => {
    return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((week) => (
      <Week key={week}>{week}</Week>
    ));
  }, []);

  useEffect(() => {
    if (!selected) return;
    const date = datetimeTFormat(selected);
    const [tmp, time] = date.split('T');
    setSelectedDate(date);
    setConfirmDate(date);
    setTime(time);
  }, [selected]);

  useEffect(() => {
    if (!confirmDate) return;
    const date = formatDate(confirmDate, 'date');
    setInputValue(`${date} ${time}`);
  }, [confirmDate, time]);

  return (
    <DateTimePicker.Provider value={context}>
      <OutsideClickHandler onOutsideClick={handleConfirm}>
        <PickerInput
          ref={setToggleElement}
          value={inputValue}
          onClick={handleToggle}
          readOnly
          disabled={disabled}
        />
        <Wrapper
          {...attributes.popper}
          style={styles.popper}
          placement={state && state.placement}
          ref={setContentElement}
          displayPicker={displayPicker}
        >
          <Body accordionCurrentDisplayType={accordionCurrentDisplayType}>
            {!fixedYear && (
              <Year
                handleChangeTitle={handleChangeYear}
                expand={accordionCurrentDisplayType === 'year'}
                handleDisplayAccordionContent={() => {
                  handleDisplayAccordionContent('year');
                }}
              />
            )}
            <Month
              handleChangeTitle={handleChangeMonth}
              expand={accordionCurrentDisplayType === 'month'}
              handleDisplayAccordionContent={() => {
                handleDisplayAccordionContent('month');
              }}
              confirmDate={confirmDate}
            />

            <DateContent>
              {weeks}
              {days}
            </DateContent>
            <TimePicker value={time} onChange={setTime} />
          </Body>
          <Footer>
            <ConfirmButton fullWidth alignCenter onClick={handleConfirm}>
              {t('Confirm')}
            </ConfirmButton>
          </Footer>
        </Wrapper>
      </OutsideClickHandler>
    </DateTimePicker.Provider>
  );
};
const Wrapper = styled.div`
  padding-bottom: var(--spacing-s);
  background: var(--color-background1);
  border-radius: var(--border-radius);
  border: var(--border-width) solid var(--border-color);
  box-shadow: var(--box-shadow);
  overflow: hidden;
  width: 286px;
  z-index: 1;
  color: var(--color-white);

  display: ${({ displayPicker }) => (displayPicker ? 'block' : 'none')};
`;

const Week = styled.div`
  height: 36px;
  width: calc(100% / 7);
  border-radius: var(--border-radius-s);
  line-height: 36px;
  text-align: center;
`;

const Body = styled.div`
  ${({ accordionCurrentDisplayType }) =>
    accordionCurrentDisplayType &&
    css`
      height: 216px;
    `}

  overflow: hidden;
  padding: ${({ accordionCurrentDisplayType }) =>
    accordionCurrentDisplayType === 'year'
      ? ' 0 4px var(--spacing-s) var(--spacing-s)'
      : '0 var(--spacing-s) 0 var(--spacing-s)'};
`;

const Footer = styled.div`
  padding: 0 var(--spacing-s);
`;

const PickerInput = styled(Input)`
  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const Day = styled.button`
  width: calc(100% / 7);
  height: 36px;
  border-radius: var(--border-radius-s);
  line-height: 36px;
  text-align: center;
  padding: 0;
  border: 0;
  background: var(--color-background1);
  color: var(--color-white);
  &:hover {
    background: var(--color-primary);
  }
  ${({ selected }) => selected && `background: var(--color-hover);`}
  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--font-on-mute);
      background: none;
      &:hover {
        background: none;
      }
    `}
`;

const ConfirmButton = styled(Button)`
  margin: 16px auto 0;
`;

const DateContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export default DatetimePickerV2;
