import styled from 'styled-components';
import LoadingBackdrop from '../backdrop/LoadingBackdrop';
import Spinner from './Spinner';

const Loader = ({ transparent, backgroundFill, $loading = true, text }) => {
  return (
    <Wrapper
      transparent={transparent}
      backgroundFill={backgroundFill}
      $loading={$loading}
    >
      <Spinner />
      {text && <Text>{text}</Text>}
    </Wrapper>
  );
};

const Wrapper = styled(LoadingBackdrop)`
  z-index: 200;

  ${({ transparent }) => transparent && 'background: transparent;'}

  ${({ backgroundFill }) =>
    backgroundFill && 'background: var(--color-background1);'}

  ${({ $loading }) => !$loading && 'display: none;'}
`;

const Text = styled.p`
  margin: var(--spacing) 0;
  font-size: var(--font-h2);
`;

export default Loader;
