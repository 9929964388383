import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Detail from './detail/';

const InProgressDetail = () => {
  const { t } = useTranslation();

  const pathData = useMemo(() => {
    return [
      { name: t('WorkOrder') },
      { name: t('InProgressList'), url: '/work-order/in-progress/list' },
      { name: t('Details') },
    ];
  }, [t]);

  return <Detail pathData={pathData} />;
};

export default InProgressDetail;
