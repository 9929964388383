import styled from 'styled-components';

const Heading2 = styled.h2`
  font-size: var(--font-h2);
  word-break: break-word;

  ${({ center }) => center && 'text-align: center;'}

  ${({ marginBottom }) => marginBottom && 'margin-bottom: var(--spacing);'}
`;

export default Heading2;
