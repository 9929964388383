const appendToBody = (id) => {
  let result = document.getElementById(id);

  if (!result) {
    result = document.createElement('div');
    result.setAttribute('id', id);
    document.body.appendChild(result);
  }

  return result;
};

export default appendToBody;
