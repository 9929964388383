import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import {
  getWorkOrderStatusList,
  batchModifyWorkOrder,
} from '../../module/workOrder/api';
import { getCustomerServiceSimpleList } from '../../module/customerService/api';
import { getEngineerSimpleList } from '../../module/engineer/api';
import {
  success as showSuccessMessage,
  error as showErrorMessage,
} from '../../module/message';
import handleApiResponse from '../../utils/api/handleApiResponse';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Section from '../../components/section/Section';
import SectionHeader from '../../components/section/SectionHeader';
import SectionBody from '../../components/section/SectionBody';
import Heading1 from '../../components/heading/Heading1';
import { Grid, Column } from '../../components/grid/Grid';
import FormItem from '../../components/form/FormItem';
import Label from '../../components/form/Label';
import Input from '../../components/form/Input';
import Select from '../../components/select/Select';
import Textarea from '../../components/form/Textarea';
import Button from '../../components/button/Button';
import ButtonGroup from '../../components/button/ButtonGroup';

const BatchModifyWorkOrder = () => {
  const { t: trans } = useTranslation();
  const history = useHistory();
  const [customerServiceList, setCustomerServiceList] = useState([]);
  const [engineerList, setEngineerList] = useState([]);
  const [assigneeList, setAssigneeList] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [selectedWorkOrder, setSelectedWorkOrder] = useState([]);
  const [data, setData] = useState({
    wo_status: '',
    principal: '',
    memo: '',
  });

  const pathData = useMemo(() => {
    return [
      { name: trans('WorkOrder') },
      { name: trans('InProgressList') },
      { name: trans('BatchModifyWorkOrder') },
    ];
  }, [trans]);

  const handleChangeValue = (key, value) => {
    setData({ ...data, [key]: value });
  };

  const handleCancel = () => {
    history.push({ pathname: '/work-order/in-progress/list' });
  };

  const handleSave = () => {
    if (!data.wo_status) {
      showErrorMessage({ message: trans('error:PleaseSelectAStatus') });
      return;
    }

    if (!data.principal) {
      showErrorMessage({ message: trans('error:PleaseSelectAAssignee') });
      return;
    }

    const parameters = {
      wo_id: selectedWorkOrder.map((workOrder) => {
        return workOrder.wo_id;
      }),
      memo: data.memo.trim(),
    };
    if (data.wo_status !== -1) {
      parameters.wo_status = data.wo_status;
    }
    if (data.principal !== -1) {
      const [principalType, principal] = data.principal.split('_');
      parameters.principal_type = principalType;
      parameters.principal = principal;
    }

    handleApiResponse(batchModifyWorkOrder(parameters), () => {
      localStorage.removeItem('batch-modify-selected-work-order');
      showSuccessMessage({ message: trans('success:ModifyWorkOrder') });
      history.push({ pathname: '/work-order/in-progress/list' });
    });
  };

  useEffect(() => {
    let value = [];
    try {
      value = JSON.parse(
        localStorage.getItem('batch-modify-selected-work-order')
      );
      if (value === null || !Array.isArray(value)) {
        value = [];
      }
    } catch (error) {
      showErrorMessage({ message: error.message });
    }

    setSelectedWorkOrder(value);
  }, []);

  useEffect(() => {
    handleApiResponse(getWorkOrderStatusList(), (response) => {
      let { wo_status } = response.data.data;
      wo_status = wo_status || [];

      const statusOptions = wo_status.map((type) => {
        return { id: parseInt(type.lifephase_id, 10), text: type.lifephase };
      });

      statusOptions.push({ id: -1, text: trans('NoModificationOfStatus') });
      setStatusOptions(statusOptions);
    });
  }, [trans]);

  useEffect(() => {
    handleApiResponse(getCustomerServiceSimpleList(), (response) => {
      const { data } = response.data;

      const users = data || [];
      setCustomerServiceList(
        users.map((user) => {
          return {
            id: `1_${user.u_id}`,
            text: `${user.first_name} ${user.last_name} (${trans(
              'CustomerService'
            )})`,
          };
        })
      );
    });

    handleApiResponse(getEngineerSimpleList(), (response) => {
      const { data } = response.data;

      const engineers = data || [];
      setEngineerList(
        engineers.map((engineer) => {
          return {
            id: `2_${engineer.en_id}`,
            text: `${engineer.first_name} ${engineer.last_name} (${trans(
              'Engineer'
            )})`,
          };
        })
      );
    });
  }, [trans]);

  useEffect(() => {
    setAssigneeList(
      [].concat(customerServiceList, engineerList, [
        { id: -1, text: trans('NoModificationOfAssignee') },
      ])
    );
  }, [customerServiceList, engineerList, trans]);

  return (
    <>
      <Breadcrumb path={pathData} />
      <Section noPadding>
        <SectionHeader>
          <Heading1>{trans('GenerateWorkOrder')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Grid columns={2}>
            <Column desktop={2}>
              <Label>{trans('WorkOrderNumber')}</Label>
              <Grid columns={2}>
                {selectedWorkOrder.map((workOrder) => {
                  return (
                    <Column key={workOrder.wo_id} desktop={1}>
                      <FormItem>
                        <Input type='text' value={workOrder.number} disabled />
                      </FormItem>
                    </Column>
                  );
                })}
              </Grid>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='status' required>
                  {trans('Status')}
                </Label>
                <Select
                  fullWidth
                  allowSearch={false}
                  options={statusOptions}
                  selected={data.wo_status}
                  onSelect={(status) => handleChangeValue('wo_status', status)}
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='assignee' required>
                  {trans('Assignee')}
                </Label>
                <Select
                  fullWidth
                  options={assigneeList}
                  selected={data.principal}
                  onSelect={(principal) =>
                    handleChangeValue('principal', principal)
                  }
                />
              </FormItem>
            </Column>
            <Column desktop={2}>
              <FormItem>
                <Label htmlFor='memo'>{trans('Memo')}</Label>
                <Textarea
                  id='memo'
                  rows='4'
                  value={data.memo}
                  onChange={(event) =>
                    handleChangeValue('memo', event.target.value)
                  }
                />
              </FormItem>
            </Column>
          </Grid>
          <ButtonGroup alignRight>
            <Button danger onClick={handleCancel}>
              {trans('button:Cancel')}
            </Button>
            <Button success onClick={handleSave}>
              {trans('button:Save')}
            </Button>
          </ButtonGroup>
        </SectionBody>
      </Section>
    </>
  );
};

export default BatchModifyWorkOrder;
