import styled from 'styled-components';

const MediaBlock = ({ icon, title, description, tools }) => {
  return (
    <Container>
      <IconWrapper>{icon}</IconWrapper>
      <TitleWrapper>
        {title && <p>{title}</p>}
        {description && <p>{description}</p>}
      </TitleWrapper>
      <Toolbar>{tools}</Toolbar>
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  background: var(--color-background1);
  border-radius: var(--border-radius);
  color: var(--color-white);
  padding: var(--spacing);
  margin-bottom: var(--spacing-s);
`;

const IconWrapper = styled.figure`
  width: 40px;
  height: 40px;
  margin-right: var(--spacing-s);

  > * {
    width: 100%;
    height: 100%;
  }
`;

const TitleWrapper = styled.div`
  > p {
    line-height: 1.25rem;
  }
`;

const Toolbar = styled.div`
  margin-left: auto;
`;

export default MediaBlock;
