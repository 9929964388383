const replaceNonLatinSymbol = (text) => {
  let replaced = text;
  replaced = replaced.replace(/\uFF01/gu, "!");
  replaced = replaced.replace(/\uFFE5/gu, "$");
  replaced = replaced.replace(/\uFF0C/gu, ",");
  replaced = replaced.replace(/\u3002/gu, ".");
  replaced = replaced.replace(/\u2014\u2014/gu, "_");
  replaced = replaced.replace(/\u00D7/gu, "*");
  replaced = replaced.replace(/\u2026\u2026/gu, "^");
  replaced = replaced.replace(/\uFF5E/gu, "~");
  replaced = replaced.replace(/\u00B7/gu, "`");
  replaced = replaced.replace(/\uFF08/gu, "(");
  replaced = replaced.replace(/\uFF09/gu, ")");
  replaced = replaced.replace(/\u300E/gu, "{");
  replaced = replaced.replace(/\u300F/gu, "}");
  replaced = replaced.replace(/\u3010/gu, "[");
  replaced = replaced.replace(/\u3011/gu, "]");
  replaced = replaced.replace(/\u3001/gu, "\\");
  replaced = replaced.replace(/\uFF1A/gu, ":");
  replaced = replaced.replace(/\uFF1B/gu, ";");
  replaced = replaced.replace(/\u201C/gu, '"');
  replaced = replaced.replace(/\u300A/gu, "<");
  replaced = replaced.replace(/\u300B/gu, ">");
  replaced = replaced.replace(/\u2018/gu, "'");
  replaced = replaced.replace(/\uFF1F/gu, "?");

  return replaced;
};

export { replaceNonLatinSymbol };
