import { useCallback } from 'react';

const useLimitChange = (setState) => {
  return useCallback(
    (limit) => {
      setState((previous) => {
        if (typeof previous === 'object') {
          return {
            ...previous,
            page: 1,
            limit: limit,
          };
        } else {
          return limit;
        }
      });
    },
    [setState]
  );
};

export default useLimitChange;
