import styled from "styled-components";
import media from "../../styles/mixin/media";

const FormButton = ({ buttons }) => {
  return (
    <ButtonGroup>
      {buttons.map(({ type, text, ...props }, index) => (
        <Button type={type} key={index} {...props}>
          {text}
        </Button>
      ))}
    </ButtonGroup>
  );
};

const ButtonGroup = styled.div`
  text-align: center;
`;

const Button = styled.button`
  padding: ${({ tall }) => (tall === true ? "1.125rem" : "0.75rem")}
    ${({ wide }) => (wide === true ? "2.125rem" : "1.25rem")};
  font-weight: 600;
  text-transform: uppercase;
  border-radius: 1.875rem;
  background-color: #ffffff;
  border-color: #ffffff;
  color: #343a40;
  font-size: 1.25rem;
  line-height: 1.5rem;
  border: 1px solid transparent;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  cursor: pointer;
  font-family: Helvetica;

  &:hover {
    background-color: #abaab7;
    border-color: #abaab7;
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }

  ${media.mobile`
    padding:
      ${({ tall }) => (tall === true ? "0.84375rem" : "0.5625rem")}
      ${({ wide }) => (wide === true ? "1.6rem" : "0.9375rem")};
    border-radius: 1.4rem;
    font-size: 0.9375rem;
    line-height: 1.125rem;
  `}
`;

export default FormButton;
