import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import Detail from './detail/';

const ClosedDetail = () => {
  const { t } = useTranslation();

  const pathData = useMemo(() => {
    return [
      { name: t('WorkOrder') },
      { name: t('ClosedList'), url: '/work-order/closed/list' },
      { name: t('Details') },
    ];
  }, [t]);

  return <Detail pathData={pathData} />;
};

export default ClosedDetail;
