const datetimeTFormat = (timestamp) => {
  const d = new Date(timestamp);

  if (typeof timestamp === 'string') {
    let [year, month, dayAndTime] = timestamp.split('-');
    if (year.length === 1) year = `000${year}`;
    if (year.length === 2) year = `00${year}`;
    if (year.length === 3) year = `0${year}`;
    if (month.length < 2) month = `0${month}`;

    let [day, time] = dayAndTime.split(' ');
    let [hour, minutes, second] = time.split(':');
    if (day.length < 2) day = `0${day}`;
    if (hour.length < 2) hour = `0${hour}`;
    if (minutes.length < 2) minutes = `0${minutes}`;
    if (second.length < 2) second = `0${second}`;
    return `${year}-${month}-${day}T${hour}:${minutes}:${second}`;
  }
  let second = `${d.getSeconds()}`;
  let minutes = `${d.getMinutes()}`;
  let hour = `${d.getHours()}`;
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  let year = `${d.getFullYear()}`;

  if (year.length === 1) year = `000${year}`;
  if (year.length === 2) year = `00${year}`;
  if (year.length === 3) year = `0${year}`;
  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  if (hour.length < 2) hour = `0${hour}`;
  if (minutes.length < 2) minutes = `0${minutes}`;
  if (second.length < 2) second = `0${second}`;

  return `${year}-${month}-${day}T${hour}:${minutes}:${second}`;
};

export default datetimeTFormat;
