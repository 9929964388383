import styled, { css } from "styled-components";

const IconButton = styled.div.attrs(() => ({
  role: "button",
}))`
  width: 40px;
  height: 40px;
  transition: 0.3s;
  position: relative;
  border-radius: var(--border-radius-l);
  color: ${({ disabled }) =>
    disabled ? `var(--color-grey)` : `var(--color-white)`};
  cursor: ${({ disabled }) => (disabled ? "no-drop" : "pointer")};
  margin: auto;

  ${({ size }) =>
    size === "small" &&
    css`
      width: 32px;
      height: 32px;
    `}

  &:hover {
    ${({ disabled }) =>
      !disabled &&
      css`
        background: var(--color-hover);
      `}
  }

  > svg {
    font-size: 1.5rem;
    position: relative;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    ${({ size }) =>
      size === "small" &&
      css`
        font-size: 1rem;
      `}

    ${({ size }) =>
      size === "large" &&
      css`
        font-size: 2rem;
      `}
  }
`;

export default IconButton;
