import { useContext, useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { CSSTransition } from 'react-transition-group';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import AuthenticateContext from '../../provider/context/authenticate.context';
import {
  login,
  loginGo,
  getInformation,
  resetPassword,
} from '../../module/authenticate/api';
import appConfig from '../../config/app.config';
import { LANGUAGES } from '../../provider/constant';
import { testEmail } from '../../utils/regex/check';
import { replaceNonLatinSymbol } from '../../utils/regex/replace';
import Wrapper from '../../components/login/Wrapper';
import Container from '../../components/login/Container';
import Logo from '../../components/login/Logo';
import Form from '../../components/login/Form';
import Select from '../../components/login/Select';
import InputGroup from '../../components/login/InputGroup';
import Label from '../../components/login/Label';
import Input from '../../components/login/Input';
import FormButton from '../../components/login/FormButton';
import Footer from '../../components/login/Footer';
import AccountButton from '../../components/login/AccountButton';
import MessageContainer from '../../components/toast/MessageContainer';

const Login = () => {
  const { t, i18n } = useTranslation();
  const history = useHistory();
  const { updateAuthenticate } = useContext(AuthenticateContext);
  const loginFormRef = useRef(null);
  const forgetPasswordFormRef = useRef(null);
  const [selectedLanguage, setSelectedLanguage] = useState(i18n.language);
  const [mode, setMode] = useState('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [resetEmail, setResetEmail] = useState('');

  const handleChangeLanguage = (value) => {
    setSelectedLanguage(value);
  };

  const handleLogin = async (email, password) => {
    try {
      const loginResponse = await login({
        user: email,
        password: password,
      });
      if (!loginResponse) return;

      const loginGoResponse = await loginGo({
        user: email,
        password: password,
      });
      if (!loginGoResponse) return;

      const { ticket } = loginResponse.data.data;
      const { token } = loginGoResponse.data.data;
      const getInformationResponse = await getInformation({ ticket: ticket });
      if (!getInformationResponse) return;
      const { data } = getInformationResponse.data;
      updateAuthenticate({ ...data, ticket, token });
      history.push({ pathname: '/' });
    } catch (error) {
      toast.error(
        <MessageContainer title={error.name} message={error.message} />
      );
    }
  };

  const handleLoginSubmit = async (event) => {
    event.preventDefault();

    const trimedEmail = email.trim().toLowerCase();
    const trimedPassword = replaceNonLatinSymbol(password.trim());
    if (trimedEmail.length === 0) {
      toast.error(
        <MessageContainer message={t('error:PleaseFillUpEmailAddress')} />
      );
      return;
    }

    if (!testEmail(trimedEmail)) {
      toast.error(
        <MessageContainer message={t('error:PleaseFillUpValidEmailAddress')} />
      );
      return;
    }

    if (trimedPassword.length === 0) {
      toast.error(
        <MessageContainer message={t('error:PleaseFillUpPassword')} />
      );
      return;
    }

    handleLogin(trimedEmail, trimedPassword);
  };

  const handleForgetPasswordSubmit = async (event) => {
    event.preventDefault();

    if (resetEmail.trim().length === 0) {
      toast.error(
        <MessageContainer message={t('error:PleaseFillUpEmailAddress')} />
      );
    } else if (!testEmail(resetEmail.trim().toLowerCase())) {
      toast.error(
        <MessageContainer message={t('error:PleaseFillUpValidEmailAddress')} />
      );
    } else {
      resetPassword({
        email: resetEmail.trim().toLowerCase(),
      })
        .then((response) => {
          if (response) {
            toast.success(
              <MessageContainer message={t('success:PasswordReset')} />
            );

            setMode('login');
            setResetEmail('');
          }
        })
        .catch((error) => {
          toast.error(
            <MessageContainer title={error.name} message={error.message} />
          );
        });
    }
  };

  useEffect(() => {
    i18n.changeLanguage(selectedLanguage);
  }, [i18n, selectedLanguage]);

  return (
    <Wrapper>
      <Container>
        <Logo />
        <CSSTransition
          nodeRef={loginFormRef}
          in={mode === 'login'}
          timeout={1000}
          classNames={{
            enter: 'animate__animated animate__flipInX',
          }}
        >
          <Form
            ref={loginFormRef}
            onSubmit={handleLoginSubmit}
            $display={mode === 'login'}
          >
            <InputGroup>
              <Label>{t('Language')}</Label>
              <Select
                options={LANGUAGES}
                selected={selectedLanguage}
                onSelect={handleChangeLanguage}
              />
            </InputGroup>
            <InputGroup>
              <Label htmlFor='email'>{t('Email')}</Label>
              <Input
                id='email'
                name='email'
                type='text'
                value={email}
                placeholder={t('placeholder:email')}
                onChange={(e) => setEmail(e.target.value)}
                maxLength='128'
                autoComplete='username'
                autoFocus
              />
            </InputGroup>
            <InputGroup>
              <Label htmlFor='password'>{t('Password')}</Label>
              <Input
                id='password'
                name='password'
                type='password'
                value={password}
                placeholder={t('placeholder:password')}
                onChange={(e) => setPassword(e.target.value)}
                autoComplete='current-password'
              />
            </InputGroup>

            <FormButton
              buttons={[
                {
                  type: 'submit',
                  text: t('button:SignInNow'),
                  tall: true,
                  wide: true,
                },
              ]}
            />

            <AccountButton
              buttons={[
                {
                  text: `${t('button:ForgetPassword')} ?`,
                  delimiter: ' ',
                  onClick: () => {
                    setMode('forget');
                  },
                },
              ]}
            />
            <Footer>{appConfig.version}</Footer>
          </Form>
        </CSSTransition>

        <CSSTransition
          nodeRef={forgetPasswordFormRef}
          in={mode === 'forget'}
          timeout={1000}
          classNames={{
            enter: 'animate__animated animate__flipInX',
          }}
        >
          <Form
            ref={forgetPasswordFormRef}
            onSubmit={handleForgetPasswordSubmit}
            $display={mode === 'forget'}
          >
            <InputGroup>
              <Label htmlFor='resetEmail'>
                {t('EnterYourEmailToResetYourPassword')}:
              </Label>
              <Input
                id='resetEmail'
                type='text'
                value={resetEmail}
                placeholder={t('placeholder:email')}
                onChange={(e) => setResetEmail(e.target.value)}
                autoComplete='username'
              />
            </InputGroup>

            <FormButton
              buttons={[
                { type: 'submit', text: t('button:Request') },
                {
                  type: 'button',
                  text: t('button:Cancel'),
                  onClick: () => {
                    setMode('login');
                  },
                },
              ]}
            ></FormButton>
          </Form>
        </CSSTransition>
      </Container>
    </Wrapper>
  );
};

export default Login;
