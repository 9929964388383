import { useState } from 'react';
import styled from 'styled-components';
import { FaSearch } from 'react-icons/fa';
import { MdClose } from 'react-icons/md';
import inputPlaceholder from '../../styles/mixin/inputPlaceholder';

const Searchbox = ({ onSearch, autoFocus = false, ...props }) => {
  const [keyword, setKeyword] = useState('');

  const handleSearch = (keyword) => {
    if (onSearch) onSearch(keyword);
  };

  const handleKeyup = (e) => {
    if (e.keyCode === 108 || e.keyCode === 13) {
      handleSearch(keyword);
    }
  };

  const handleClear = () => {
    setKeyword('');
    handleSearch('');
  };

  return (
    <Wrapper {...props}>
      <FaSearch
        onClick={() => {
          handleSearch(keyword);
        }}
        role='button'
        aria-label='search button'
      />
      <Input
        type='text'
        placeholder='Search'
        autocomplete='off'
        value={keyword}
        onChange={(e) => setKeyword(e.target.value)}
        onKeyUp={handleKeyup}
        autoFocus={autoFocus}
      />

      {keyword && (
        <MdClose
          onClick={handleClear}
          role='button'
          aria-label='clear button'
        />
      )}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  height: 36px;
  background: var(--color-background1);
  border-radius: var(--border-radius-l);
  padding: 0 calc(var(--spacing-s) + var(--font-body1) + var(--spacing-xs)) 0
    var(--spacing-s);

  > svg {
    font-size: var(--font-body1);
    color: var(--font-on-mute);

    &:first-child {
      margin-right: var(--spacing-xs);
    }

    &:last-child {
      position: absolute;
      right: var(--spacing-s);
    }
  }

  ${({ border }) =>
    border && 'border: var(--border-width) solid var(--border-color);'}
`;

const Input = styled.input`
  background: transparent;
  border: none;
  font-size: var(--font-body1);
  color: var(--font-on-background);
  outline: none;

  ${inputPlaceholder`
    color: var(--font-on-mute);
  `}
`;

export default Searchbox;
