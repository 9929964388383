import styled, { css } from 'styled-components';
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa';
import { usePagination, DOTS } from '../../utils/hooks/usePagination';

const Paginate = (props) => {
  const {
    currentPage,
    totalCount,
    pageSize,
    siblingCount = 1,
    onPageChange,
    alignRight,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  const onNext = () => {
    if (currentPage === lastPage) return;
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    if (currentPage === 1) return;
    onPageChange(currentPage - 1);
  };

  if (currentPage === 0 || paginationRange.length < 1) {
    return null;
  }

  let lastPage = paginationRange[paginationRange.length - 1];

  return (
    <Container alignRight={alignRight}>
      <Link disabled={currentPage === 1} onClick={onPrevious}>
        <Icon>
          <FaAngleLeft />
        </Icon>
      </Link>

      {paginationRange.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <Link key={index} className='paginationDots'>
              &#8230;
            </Link>
          );
        }

        return (
          <Link
            key={index}
            active={pageNumber === currentPage}
            onClick={() => onPageChange(pageNumber)}
          >
            {pageNumber}
          </Link>
        );
      })}

      <Link disabled={currentPage === lastPage} onClick={onNext}>
        <Icon>
          <FaAngleRight />
        </Icon>
      </Link>
    </Container>
  );
};

const Container = styled.ul`
  display: flex;
  text-align: center;

  ${({ alignRight }) => alignRight && 'justify-content: flex-end'};
`;

const Link = styled.li`
  width: 36px;
  line-height: 37px;
  font-size: var(--font-body1);
  color: var(--font-on-primary);
  border-radius: var(--border-radius-round);
  cursor: pointer;
  transition: 0.3s;

  &:not(:last-child) {
    margin-right: var(--spacing-xs);
  }

  ${({ disabled }) =>
    disabled &&
    css`
      opacity: 0.5;
      cursor: no-drop;
    `};

  ${({ disabled, active }) =>
    !disabled &&
    active &&
    css`
      background-color: var(--color-primary);
    `}

  &:hover {
    ${({ disabled, className }) =>
      !disabled &&
      !className &&
      css`
        background-color: var(--color-primary);
      `}
  }
  &.paginationDots {
    cursor: default;
  }
`;

const Icon = styled.span`
  font-size: var(--font-body1);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
`;

export default Paginate;
