import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import {
  getDemandOrderTypeList,
  getTroubleshootingList,
  modifyDemandOrder,
} from '../../../module/demandOrder/api';
import { getOwnerList } from '../../../module/owner/api';
import { success, error } from '../../../module/message';
import handleApiResponse from '../../../utils/api/handleApiResponse';
import AuthenticateContext from '../../../provider/context/authenticate.context';
import Section from '../../../components/section/Section';
import SectionBody from '../../../components/section/SectionBody';
import { Grid, Column } from '../../../components/grid/Grid';
import FormItem from '../../../components/form/FormItem';
import Label from '../../../components/form/Label';
import Input from '../../../components/form/Input';
import Select from '../../../components/select/Select';
import Textarea from '../../../components/form/Textarea';
import Button from '../../../components/button/Button';
import ButtonGroup from '../../../components/button/ButtonGroup';

const BasicInformation = ({
  previousData,
  data,
  setData,
  handleGetDemandOrderInformation,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const { userInformation } = useContext(AuthenticateContext);
  const [ownerList, setOwnerList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [troubleshootingList, setTroubleshootingList] = useState([]);
  const [modifyMode, setModifyMode] = useState(false);

  const handleChangeValue = (value, key) => {
    const newData = { ...data };
    newData[key] = value;

    setData(newData);
  };

  const handleCancelModify = () => {
    setData(previousData);
    setModifyMode(false);
  };

  const handleModify = () => {
    if (!data.process_result.trim()) {
      error({ message: t('error:PleaseFillUpProcessResult') });
      return;
    }

    handleApiResponse(
      modifyDemandOrder({
        cs_id: id,
        type_id: parseInt(data.type_id, 10),
        error_type: data.error_type,
        sales_id: data.sales_id,
        process_result: data.process_result,
        problem: data.problem,
      }),
      () => {
        success({ message: t('success:ModifyDemandOrder') });
        handleGetDemandOrderInformation();
        setModifyMode(false);
      }
    );
  };

  useEffect(() => {
    handleApiResponse(getOwnerList(), (response) => {
      const { owner } = response.data.data;
      const data = owner.map((owner) => {
        return { id: owner.owner_id, text: owner.owner };
      });

      setOwnerList(data);
    });

    handleApiResponse(getDemandOrderTypeList(), (response) => {
      const { cs_type } = response.data.data;
      const data = cs_type.map((type) => {
        return { id: type.type_id, text: type.type };
      });

      setTypeList(data);
    });

    handleApiResponse(getTroubleshootingList(), (response) => {
      const { troubleshooting } = response.data.data;
      const data = troubleshooting.map((trouble) => {
        return { id: trouble.trouble_id, text: trouble.trouble };
      });

      setTroubleshootingList(data);
    });
  }, [t]);

  return (
    <>
      <Section backgroundReverse noPaddingBottom>
        <SectionBody noPadding>
          <Grid columns={2}>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='orderNumber'>{t('OrderNumber')}</Label>
                <Input type='text' id='orderNumber' value={id} disabled />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='bank'>{t('Bank')}</Label>
                <Select
                  fullWidth
                  options={ownerList}
                  selected={data.owner}
                  disabled
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='type' required={modifyMode}>
                  {t('Type')}
                </Label>
                <Select
                  fullWidth
                  options={typeList}
                  selected={data.type_id}
                  disabled={!modifyMode}
                  onSelect={(type) => handleChangeValue(type, 'type_id')}
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='subType' required={modifyMode}>
                  {t('SubType')}
                </Label>
                <Select
                  fullWidth
                  options={troubleshootingList}
                  selected={data.error_type}
                  disabled={!modifyMode}
                  onSelect={(errroType) =>
                    handleChangeValue(errroType, 'error_type')
                  }
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='salesID'>{t('SalesID')}</Label>
                <Input
                  type='text'
                  id='salesID'
                  value={data.sales_id}
                  disabled={!modifyMode}
                  onChange={(event) => {
                    handleChangeValue(event.target.value, 'sales_id');
                  }}
                />
              </FormItem>
            </Column>
            <Column desktop={2}>
              <FormItem>
                <Label htmlFor='problem' required={modifyMode}>
                  {t('Description')}
                </Label>
                <Textarea
                  id='problem'
                  rows='4'
                  value={data.problem}
                  disabled={!modifyMode}
                  onChange={(e) => handleChangeValue(e.target.value, 'problem')}
                />
              </FormItem>
            </Column>

            <Column desktop={2}>
              <FormItem>
                <Label htmlFor='processResult' required={modifyMode}>
                  {t('ProcessResult')}
                </Label>
                <Textarea
                  id='processResult'
                  rows='4'
                  value={data.process_result}
                  disabled={!modifyMode}
                  onChange={(e) =>
                    handleChangeValue(e.target.value, 'process_result')
                  }
                />
              </FormItem>
            </Column>
          </Grid>
        </SectionBody>
      </Section>

      {parseInt(userInformation.r_id) !== 3 && (
        <ButtonGroup alignRight>
          {modifyMode ? (
            <>
              <Button danger onClick={handleCancelModify}>
                {t('button:Cancel')}
              </Button>
              <Button success onClick={handleModify}>
                {t('button:Save')}
              </Button>
            </>
          ) : (
            <Button
              warning
              onClick={() => {
                setModifyMode(true);
              }}
            >
              {t('button:Modify')}
            </Button>
          )}
        </ButtonGroup>
      )}
    </>
  );
};

export default BasicInformation;
