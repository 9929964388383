import { useState, useMemo, useCallback, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { MdMoreHoriz, MdDelete } from 'react-icons/md';
import { FiFileText } from 'react-icons/fi';
import { success } from '../../module/message';
import {
  getCustomerServiceList,
  removeCustomerService,
} from '../../module/customerService/api';
import handleApiResponse from '../../utils/api/handleApiResponse';
import AuthenticateContext from '../../provider/context/authenticate.context';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Section from '../../components/section/Section';
import SectionHeader from '../../components/section/SectionHeader';
import SectionBody from '../../components/section/SectionBody';
import SectionToolbar from '../../components/section/SectionToolbar';
import Heading1 from '../../components/heading/Heading1';
import Table from '../../components/table/Table';
import {
  useLimitChange,
  usePageChange,
  useSortChange,
} from '../../components/table/hooks';
import Dropdown, {
  DropdownItem,
  DropdownButtonOption,
  DropdownLinkOption,
} from '../../components/Dropdown';
import IconButton from '../../components/button/IconButton';
import StateModal from '../../components/modal/StateModal';
import SearchBox from '../../components/form/Searchbox';

const List = () => {
  const { t } = useTranslation();
  const { userInformation } = useContext(AuthenticateContext);
  const [listData, setListData] = useState([]);
  const [total, setTotal] = useState(1);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
    sort_column: 'first_name',
    sort_type: 'asc',
    search: '',
  });

  const pathData = useMemo(() => {
    return [{ name: t('CustomerService') }, { name: t('List') }];
  }, [t]);

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);
  const onSortChange = useSortChange(
    setListParams,
    listParams.sort_column,
    listParams.sort_type
  );

  const handleGetCustomerService = useCallback(() => {
    const params = {
      page: listParams.page,
      limit: listParams.limit,
      sort_column: listParams.sort_column,
      sort_type: listParams.sort_type,
    };

    if (listParams.search) {
      params.search = listParams.search;
    }

    handleApiResponse(getCustomerServiceList(params), (response) => {
      const { user, total } = response.data.data;
      setListData(user || []);
      setTotal(total);
    });
  }, [listParams]);

  const handleRemove = useCallback(
    (userId) => {
      StateModal({
        type: 'warning',
        text: t('YouWontBeAbleToRevertThis'),
        showCancelButton: true,
        confirmButtonText: t('button:Confirm'),
        cancelButtonText: t('button:Cancel'),
        onConfirm() {
          handleApiResponse(removeCustomerService({ u_id: userId }), () => {
            success({
              message: t('success:RemoveCustomerService'),
            });
            handleGetCustomerService();
          });
        },
      });
    },
    [t, handleGetCustomerService]
  );

  const generateActionDropdown = useCallback(
    ({ u_id }) => {
      return (
        <Dropdown key={u_id}>
          <Dropdown.Toggle>
            <IconButton>
              <MdMoreHoriz />
            </IconButton>
          </Dropdown.Toggle>
          <Dropdown.Content>
            <DropdownItem>
              <DropdownLinkOption to={`/customer-service/${u_id}`}>
                <FiFileText />
                {t('Details')}
              </DropdownLinkOption>
            </DropdownItem>
            {u_id !== userInformation.u_id && (
              <DropdownItem onClick={() => handleRemove(u_id)}>
                <DropdownButtonOption>
                  <MdDelete />
                  {t('Remove')}
                </DropdownButtonOption>
              </DropdownItem>
            )}
          </Dropdown.Content>
        </Dropdown>
      );
    },
    [t, userInformation.u_id, handleRemove]
  );

  useEffect(() => {
    handleGetCustomerService();
  }, [handleGetCustomerService]);

  return (
    <>
      <Breadcrumb path={pathData} />
      <Section noPadding>
        <SectionHeader sticky backgroundFill inline>
          <Heading1>{t('CustomerService')}</Heading1>
          <SectionToolbar>
            <div>
              <SearchBox
                border
                onSearch={(keyword) => {
                  setListParams((previousParams) => {
                    return {
                      ...previousParams,
                      page: 1,
                      search: keyword,
                    };
                  });
                }}
              />
            </div>
          </SectionToolbar>
        </SectionHeader>
        <SectionBody>
          <Table
            columns={[
              {
                title: t('Name'),
                fieldName: 'first_name',
                sortable: true,
                render(data, full) {
                  return `${full.first_name} ${full.last_name}`;
                },
              },
              {
                title: t('Email'),
                fieldName: 'email',
                sortable: true,
              },
              {
                title: t('Phone'),
                fieldName: 'phone',
                sortable: true,
              },
              {
                title: t('Action'),
                custom: true,
                width: '10%',
                align: 'center',
                render(data) {
                  return generateActionDropdown(data);
                },
              },
            ]}
            data={listData}
            currentPage={listParams.page}
            limit={listParams.limit}
            onLimitChange={onLimitChange}
            total={total}
            sortColumn={listParams.sort_column}
            sortType={listParams.sort_type}
            onSortChange={onSortChange}
            onPageChange={onPageChange}
            translation={{ info: t('table.info'), empty: t('table.empty') }}
          />
        </SectionBody>
      </Section>
    </>
  );
};

export default List;
