import styled from 'styled-components';

const Label = styled.label`
  display: inline-block;
  color: var(--font-on-background);
  font-size: var(--font-body1);
  margin-bottom: var(--spacing-xs);

  ${({ required }) =>
    required &&
    `&:after {
      content: ' *';
      color: var(--color-danger);
    }
  `}

  ${({ inline }) => inline && `display: inline; margin-bottom: 0;`}
`;

export default Label;
