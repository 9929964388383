import styled from 'styled-components';

const TabList = ({ children, ...props }) => {
  return (
    <Container role='tablist' {...props}>
      {children}
    </Container>
  );
};

const Container = styled.div`
  display: flex;
  flex-wrap: wrap;
  border-bottom: var(--border-width) solid var(--border-color);
  margin-bottom: var(--spacing);

  ${({ noMargin }) => noMargin && 'margin-bottom: 0;'}
`;

export default TabList;
