import CustomerServiceIcon from '../assets/icons/customer-service.png';
import Create from '../pages/customerService/Create';
import List from '../pages/customerService/List';
import Detail from '../pages/customerService/Detail';

const CustomerServiceRoute = (userInformation) => {
  return [
    {
      path: '/customer-service',
      name: 'CustomerServiceManagement',
      icon: CustomerServiceIcon,
      parent: true,
      checkPermission() {
        if (parseInt(userInformation.r_id, 10) !== 1) return false;

        return true;
      },
      child: [
        {
          path: '/create',
          name: 'Create',
          component: Create,
          checkPermission() {
            if (parseInt(userInformation.r_id, 10) !== 1) return false;

            return true;
          },
        },
        {
          path: '/list',
          name: 'CustomerServiceList',
          component: List,
          checkPermission() {
            if (parseInt(userInformation.r_id, 10) !== 1) return false;

            return true;
          },
        },
        {
          path: '/:id',
          component: Detail,
          checkPermission() {
            if (parseInt(userInformation.r_id, 10) !== 1) return false;

            return true;
          },
        },
      ],
    },
  ];
};

export default CustomerServiceRoute;
