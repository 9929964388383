import { createContext, useState, useCallback, useEffect  } from "react";
import { unmountComponentAtNode } from "react-dom";
import { useLocation } from 'react-router-dom';
import useWindowSize from '../../utils/hooks/useWindowSize';
import breakpoints from '../../styles/breakpoint.style';

const AppContext = createContext();

const AppContextProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [mode, setMode] = useState("desktop");
  const [width] = useWindowSize();
  const location = useLocation();

  const clearAllStateDialog = useCallback(() => {
    const container = document.getElementById("StateModalContainer");
    if (container) {
      container.textContent = "";
    }
  }, []);

  const clearAllTitledDialog = useCallback(() => {
    const container = document.getElementById("TitleModalContainer");
    if (container) {
      container.textContent = "";
    }
  }, []);

  const clearAllPopover = useCallback(() => {
    const container = document.getElementById("PopoverContainer");
    if (container) {
      unmountComponentAtNode(container);
    }
  }, []);

  // scroll to top on every navigation
  // clear all portal element when location(url) changed
  useEffect(() => {
    window.scrollTo(0, 0);
    clearAllStateDialog();
    clearAllTitledDialog();
    clearAllPopover();
  }, [
    location.pathname,
    clearAllStateDialog,
    clearAllTitledDialog,
    clearAllPopover,
  ]);

  // set current mode based on screen size
  useEffect(() => {
    if (width !== 0 && width <= breakpoints.tablet) {
      setMode('tablet');
    } else {
      setMode('desktop');
    }
  }, [width]);

  return (
    <AppContext.Provider
      value={{
        loading,
        setLoading,
        mode,
      }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default AppContext;
export { AppContextProvider };
