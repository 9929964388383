import { callApi, callGoApi } from '../../services/api';

const login = (data) => {
  const params = {
    user: data.user,
    password: data.password,
  };

  return callApi.post('/login.php', params);
};

const loginGo = (data) => {
  const params = {
    user: data.user,
    password: data.password,
  };

  return callGoApi.post('/auth/token', params);
};

const logout = (data) => {
  const params = {
    u_id: data.u_id,
  };

  return callApi.post('/logout.php', params);
};

const getInformation = (data) => {
  const params = {
    ticket: data.ticket,
  };

  return callApi.get('/get_selfinfo.php', {
    params: params,
  });
};

const resetPassword = (data) => {
  const params = {
    email: data.email,
  };

  return callApi.post('/send_pwdmail.php', params);
};

export { login, loginGo, logout, getInformation, resetPassword };
