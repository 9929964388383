import styled, { css } from 'styled-components';
import Input from './Input';
import Label from './Label';

const RadioButton = ({ htmlFor, value, text, disabled, checked, onChange }) => {
  return (
    <StyledLabel htmlFor={htmlFor} disabled={disabled}>
      <StyledCheckbox
        onChange={() => {
          onChange(value);
        }}
        checked={checked}
        type='checkbox'
        id={htmlFor}
        disabled={disabled}
      />
      <span>
        <Unchecked />
        {text}
      </span>
    </StyledLabel>
  );
};

const StyledCheckbox = styled(Input)`
  display: none;

  &:checked + span {
    > div {
      &:before {
        display: block;
      }
    }
  }
`;

const StyledLabel = styled(Label)`
  margin-right: var(--spacing-xs);

  > span {
    ${({ disabled }) =>
      disabled &&
      css`
        opacity: 0.5;
      `}
    display: flex;
    align-items: center;

    > div {
      margin-right: var(--spacing-xs);
      border-radius: var(--border-radius-round);

      width: 24px;
      height: 24px;

      &:before {
        display: none;
      }
    }
  }
`;

const Unchecked = styled.div`
  border: var(--border-width) solid var(--border-color);
  position: relative;

  &:before {
    content: '';
    background: var(--color-primary);
    width: 16px;
    height: 16px;
    position: absolute;
    border-radius: var(--border-radius-round);
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export default RadioButton;
