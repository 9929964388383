import { Redirect } from "react-router";

const InvalidRoute = [
  {
    path: "*",
    parent: false,
    component: () => {
      return <Redirect to="/404" />;
    },
  },
];

export default InvalidRoute;
