import styled from "styled-components";

const Logo = () => {
  return (
    <Container>
      <h1>Dispatch System</h1>
    </Container>
  );
};

const Container = styled.div`
  margin-bottom: 20px;
  text-align: center;

  > h1 {
    font-size: 48px;
    color: var(--font-on-background);
  }
`;

export default Logo;
