import { useState, useEffect, useContext, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { getRoleSimpleList } from '../../module/role/api';
import {
  getCustomerServiceInformation,
  modifyCustomerServicePassword,
} from '../../module/customerService/api';
import handleApiResponse from '../../utils/api/handleApiResponse';
import { success, error } from '../../module/message';
import AuthenticateContext from '../../provider/context/authenticate.context';
import Section from '../../components/section/Section';
import SectionHeader from '../../components/section/SectionHeader';
import SectionBody from '../../components/section/SectionBody';
import SectionToolbar from '../../components/section/SectionToolbar';
import Heading1 from '../../components/heading/Heading1';
import { Grid, Column } from '../../components/grid/Grid';
import FormItem from '../../components/form/FormItem';
import Label from '../../components/form/Label';
import Input from '../../components/form/Input';
import Select from '../../components/select/Select';
import Button from '../../components/button/Button';
import ButtonGroup from '../../components/button/ButtonGroup';
import TitleModal from '../../components/modal/TitleModal';

const Profile = () => {
  const { t } = useTranslation();
  const { userInformation, removeAuthenticate } =
    useContext(AuthenticateContext);
  const [roleSimpleList, setRoleSimpleList] = useState([]);
  const [data, setData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    r_id: '',
  });

  const showChangePassword = useCallback(() => {
    TitleModal({
      size: 'small',
      children: (
        <ChangePasswordModal
          email={userInformation.email}
          removeAuthenticate={removeAuthenticate}
        />
      ),
    });
  }, [userInformation.email, removeAuthenticate]);

  useEffect(() => {
    handleApiResponse(getRoleSimpleList(), (response) => {
      const { role } = response.data.data;
      const data = role.map((role) => {
        return { id: role.role_id, text: role.role };
      });
      setRoleSimpleList(data);
    });
  }, []);

  useEffect(() => {
    handleApiResponse(
      getCustomerServiceInformation({ u_id: userInformation.u_id }),
      (response) => {
        const { data } = response.data;
        setData({
          email: data.email,
          first_name: data.first_name,
          last_name: data.last_name,
          phone: data.phone,
          r_id: data.r_id,
        });
      }
    );
  }, [userInformation.u_id]);

  return (
    <Section noPadding>
      <SectionHeader inline>
        <Heading1>{t('Profile')}</Heading1>
        <SectionToolbar>
          <Button onClick={showChangePassword}>{t('ChangePassword')}</Button>
        </SectionToolbar>
      </SectionHeader>
      <SectionBody>
        <Section backgroundReverse noPaddingBottom>
          <SectionBody noPadding>
            <Grid columns={2}>
              <Column desktop={2}>
                <FormItem>
                  <Label htmlFor='email'>{t('Email')}</Label>
                  <Input type='text' id='email' value={data.email} disabled />
                </FormItem>
              </Column>
              <Column desktop={1}>
                <FormItem>
                  <Label htmlFor='firstName'>{t('FirstName')}</Label>
                  <Input
                    type='text'
                    id='firstName'
                    value={data.first_name}
                    disabled
                  />
                </FormItem>
              </Column>
              <Column desktop={1}>
                <FormItem>
                  <Label htmlFor='lastName'>{t('LastName')}</Label>
                  <Input
                    type='text'
                    id='lastName'
                    value={data.last_name}
                    disabled
                  />
                </FormItem>
              </Column>
              <Column desktop={1}>
                <FormItem>
                  <Label htmlFor='phone'>{t('Phone')}</Label>
                  <Input type='text' id='phone' value={data.phone} disabled />
                </FormItem>
              </Column>

              <Column desktop={1}>
                <FormItem>
                  <Label htmlFor='role'>{t('Role')}</Label>
                  <Select
                    fullWidth
                    options={roleSimpleList}
                    selected={data.r_id}
                    disabled
                  />
                </FormItem>
              </Column>
            </Grid>
          </SectionBody>
        </Section>
      </SectionBody>
    </Section>
  );
};

const ChangePasswordModal = ({ email, removeAuthenticate, close }) => {
  const { t } = useTranslation();
  const [oldPassword, setOldPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');

  const handleSubmit = () => {
    if (!oldPassword.trim()) {
      error({ message: t('error:PleaseFillUpOldlPassword') });
      return;
    }

    if (!newPassword.trim()) {
      error({ message: t('error:PleaseFillUpNewlPassword') });
      return;
    }

    handleApiResponse(
      modifyCustomerServicePassword({
        account: email,
        old_password: oldPassword,
        new_password: newPassword,
      }),
      () => {
        success({
          message: t('success:ChangePassword'),
        });
        close();
        removeAuthenticate();
      }
    );
  };

  return (
    <>
      <TitleModal.Header handleClose={close}>
        {t('ChangePassword')}
      </TitleModal.Header>
      <TitleModal.Body noPaddingBottom>
        <FormItem>
          <Label htmlFor='oldPassword' required>
            {t('OldPassword')}
          </Label>
          <Input
            autoComplete='new-password'
            type='password'
            id='oldPassword'
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </FormItem>
        <FormItem>
          <Label htmlFor='newPassword' required>
            {t('NewPassword')}
          </Label>
          <Input
            autoComplete='new-password'
            type='password'
            id='newPassword'
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </FormItem>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={close}>
            {t('button:Cancel')}
          </Button>
          <Button success onClick={handleSubmit}>
            {t('button:Submit')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

export default Profile;
