import styled from 'styled-components';
import { Link } from 'react-router-dom';
import media from '../../styles/mixin/media';

const Breadcrumb = ({ path = [] }) => {
  return (
    <Wrapper>
      {path.map(({ name, url }, index) => {
        if (url)
          return (
            <Link to={url} key={index}>
              <p>{name}</p>
            </Link>
          );
        else return <p key={index}>{name}</p>;
      })}
    </Wrapper>
  );
};

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  font-size: var(--font-body2);
  line-height: var(--font-body1);
  color: var(--font-on-primary);
  padding: var(--spacing-s) 0;

  > *:not(:last-child) {
    display: flex;
    align-items: center;
    color: var(--font-on-primary);
    overflow: hidden;

    > p {
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      border-bottom: var(--border-width) solid transparent;

      &:hover {
        border-bottom: var(--border-width) solid var(--color-primary);
      }
    }

    &:after {
      content: '/';
      padding: 0 var(--spacing-xs);
    }
  }

  > *:last-child {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    border-bottom: var(--border-width) solid transparent;
    color: var(--color-primary);
  }

  ${media.tablet`
    padding-top: 0;
  `}
`;

export default Breadcrumb;
