const testEmail = (text) => {
  const emailReg =
    /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return emailReg.test(text);
};

const testInteger = (value) => {
  const intRegex = /^[0-9]\d*$/;
  return intRegex.test(value);
};

export { testEmail, testInteger };
