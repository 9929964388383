import { usePopper } from "react-popper";

const useCustomPopper = (
  targetElement,
  popperElement,
  {
    strategy = "absolute",
    arrowElement = null,
    boundary = "clippingParents",
  } = {}
) => {
  return usePopper(targetElement, popperElement, {
    placement: "bottom",
    strategy: strategy,
    modifiers: [
      {
        name: "flip", //當reference元素將超出邊界時，定位popper元素
        enabled: true,
        options: {
          padding: 8,
        },
      },
      {
        name: "preventOverflow",
        options: {
          padding: 8,
          boundary: boundary,
        },
      },
      {
        name: "offset",
        options: {
          offset: [0, 8],
        },
      },
      {
        name: "arrow",
        options: {
          element: arrowElement,
        },
      },
    ],
  });
};

export default useCustomPopper;
