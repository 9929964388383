import { useState, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { getRoleSimpleList } from '../../module/role/api';
import { success, error } from '../../module/message';
import { createCustomerService } from '../../module/customerService/api';
import handleApiResponse from '../../utils/api/handleApiResponse';
import { testEmail } from '../../utils/regex/check';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Section from '../../components/section/Section';
import SectionHeader from '../../components/section/SectionHeader';
import SectionBody from '../../components/section/SectionBody';
import Heading1 from '../../components/heading/Heading1';
import { Grid, Column } from '../../components/grid/Grid';
import FormItem from '../../components/form/FormItem';
import Label from '../../components/form/Label';
import Input from '../../components/form/Input';
import Button from '../../components/button/Button';
import ButtonGroup from '../../components/button/ButtonGroup';
import Select from '../../components/select/Select';

const Create = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const [roleSimpleList, setRoleSimpleList] = useState([]);
  const [data, setData] = useState({
    email: '',
    first_name: '',
    last_name: '',
    phone: '',
    r_id: '',
  });

  const pathData = useMemo(() => {
    return [{ name: t('CustomerService') }, { name: t('Create') }];
  }, [t]);

  const handleChangeValue = (value, key) => {
    const newData = { ...data };
    newData[key] = value;

    setData(newData);
  };

  const handleReset = () => {
    setData({
      email: '',
      first_name: '',
      last_name: '',
      phone: '',
      r_id: '',
    });
  };

  const handleCreate = () => {
    if (!data.email.trim()) {
      error({ message: t('error:PleaseFillUpEmail') });
      return;
    }

    if (!testEmail(data.email.trim())) {
      error({ message: t('error:PleaseFillUpValidEmailAddress') });
      return;
    }

    if (!data.first_name.trim()) {
      error({ message: t('error:PleaseFillUpFirstName') });
      return;
    }

    if (!data.last_name.trim()) {
      error({ message: t('error:PleaseFillUpLastName') });
      return;
    }

    if (!data.phone.trim()) {
      error({ message: t('error:PleaseFillUpPhoneNumber') });
      return;
    }

    if (!data.r_id) {
      error({ message: t('error:PleaseSelectARole') });
      return;
    }

    handleApiResponse(
      createCustomerService({
        email: data.email,
        first_name: data.first_name,
        last_name: data.last_name,
        phone: data.phone,
        r_id: data.r_id,
      }),
      () => {
        success({ message: t('success:CreateCustomerService') });
        history.push({ pathname: '/customer-service/list' });
      }
    );
  };

  useEffect(() => {
    handleApiResponse(getRoleSimpleList(), (response) => {
      const { role } = response.data.data;
      const data = role.map((role) => {
        return { id: role.role_id, text: role.role };
      });
      setRoleSimpleList(data);
    });
  }, []);

  return (
    <>
      <Breadcrumb path={pathData} />
      <Section noPadding>
        <SectionHeader>
          <Heading1>{t('CreateCustomerService')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Section backgroundReverse noPaddingBottom>
            <SectionBody noPadding>
              <Grid columns={2}>
                <Column desktop={2}>
                  <FormItem>
                    <Label htmlFor='email' required>
                      {t('Email')}
                    </Label>
                    <Input
                      type='text'
                      id='email'
                      value={data.email}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'email')
                      }
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='firstName' required>
                      {t('FirstName')}
                    </Label>
                    <Input
                      type='text'
                      id='firstName'
                      value={data.first_name}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'first_name')
                      }
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='lastName' required>
                      {t('LastName')}
                    </Label>
                    <Input
                      type='text'
                      id='lastName'
                      value={data.last_name}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'last_name')
                      }
                    />
                  </FormItem>
                </Column>
                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='phone' required>
                      {t('Phone')}
                    </Label>
                    <Input
                      type='text'
                      id='phone'
                      value={data.phone}
                      onChange={(e) =>
                        handleChangeValue(e.target.value, 'phone')
                      }
                    />
                  </FormItem>
                </Column>

                <Column desktop={1}>
                  <FormItem>
                    <Label htmlFor='role' required>
                      {t('Role')}
                    </Label>
                    <Select
                      fullWidth
                      options={roleSimpleList}
                      selected={data.r_id}
                      onSelect={(role) => handleChangeValue(role, 'r_id')}
                    />
                  </FormItem>
                </Column>
              </Grid>
            </SectionBody>
          </Section>

          <ButtonGroup alignRight>
            <Button danger onClick={handleReset}>
              {t('button:Reset')}
            </Button>
            <Button success onClick={handleCreate}>
              {t('button:Create')}
            </Button>
          </ButtonGroup>
        </SectionBody>
      </Section>
    </>
  );
};

export default Create;
