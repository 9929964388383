import styled, { css } from "styled-components";

const IconFigure = styled.figure`
  width: 24px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  ${({ size }) => {
    switch (size) {
      case "small":
        return css`
          width: 16px;
          height: 16px;
        `;
      case "large":
        return css`
          width: 32px;
          height: 32px;
        `;
      default:
        break;
    }
  }}
`;

export default IconFigure;
