import { useState } from 'react';

const useToggleOptions = (defaultOptions) => {
  const [options, setOptions] = useState(defaultOptions);

  const handleToggleOptions = (index) => {
    options[index].checked = options[index].checked === 1 ? 0 : 1;
    setOptions([...options]);
  };

  const resetOptions = () => {
    setOptions(defaultOptions);
  };

  return [options, handleToggleOptions, resetOptions];
};

export default useToggleOptions;
