import { useContext, useCallback } from 'react';
import { ThemeProvider } from 'styled-components';
import { goApiUrl } from './config/api.config';
import AppContext from './provider/context/app.context';
import AuthenticateContext from './provider/context/authenticate.context';
import AxiosInterceptor from './services/api';
import { error } from './module/message';
import themes from './styles/theme';
import GlobalStyle from './components/style/GlobalStyle';
import Router from './Router';

const App = () => {
  const { setLoading, mode } = useContext(AppContext);
  const { userInformation } = useContext(AuthenticateContext);

  const handleShowError = (status, msg) => {
    error({ title: status, message: msg });
  };

  // add authenticate information before send api request
  const handleBeforeSend = useCallback(
    (config) => {
      if (config.global !== false) {
        setLoading(true);
      }

      // call external api no need send authenticate information
      if (
        config.baseURL === goApiUrl &&
        userInformation &&
        userInformation.token &&
        !config.external
      ) {
        config.headers['Authorization'] = `Bearer ${userInformation.token}`;
      }

      return config;
    },
    [setLoading, userInformation]
  );

  // check api response when request done
  const handleDone = useCallback((response) => {
    const data = response.data;

    switch (response.status) {
      case 200:
      case 201:
      case 202:
      case 204:
        if (response.config.baseURL === goApiUrl) {
          if (
            data !== undefined &&
            !(data instanceof Blob) &&
            data.status !== 200
          ) {
            handleShowError(data.status, data.msg);
            return null;
          }
        } else {
          if (data !== undefined && data.status !== 0) {
            handleShowError(data.status, data.msg);
            return null;
          }
        }

        return response;
      default:
        handleShowError(data.status, data.msg);
        return null;
    }
  }, []);

  const handleAllDone = useCallback(() => {
    setLoading(false);
  }, [setLoading]);

  return (
    <ThemeProvider theme={themes.dark[mode]}>
      <GlobalStyle />

      <AxiosInterceptor
        onBeforeSend={handleBeforeSend}
        onDone={handleDone}
        onAllDone={handleAllDone}
      />

      <Router />
    </ThemeProvider>
  );
};

export default App;
