import styled from "styled-components";
import headerStyle from "../../styles/header.style";
import media from "../../styles/mixin/media";
import Logo from "./Logo";
import MenuIcon from "./MenuIcon";

const AsideHeader = ({ toggleSidebar }) => {
  return (
    <Brand>
      <MenuIcon toggleSidebar={toggleSidebar} />
      <Logo />
    </Brand>
  );
};

const Brand = styled.div`
  display: none;
  height: ${headerStyle.height};

  ${media.tablet`
    padding: 0 var(--spacing-xs);
    align-items: center;
    display: flex
  `};
`;

export default AsideHeader;
