import { createContext, useState, useCallback, useEffect } from 'react';
import Cookies from 'js-cookie';

const AuthenticateContext = createContext();

const INFORMATION_KEY_NAME = 'DSUSERINFO';
const AuthenticateContextProvider = ({ children }) => {
  const [isGetting, setIsGetting] = useState(true);
  const [isAuthenticated, setAuthenticated] = useState(false);
  const [userInformation, setUserInformation] = useState(null);

  const getInformation = () => {
    let information = {};
    try {
      information = JSON.parse(localStorage.getItem(INFORMATION_KEY_NAME));
      if (information === null || typeof information !== 'object') {
        information = {};
      }
    } catch (error) {
      console.log(error);
    }

    return information;
  };

  const updateAuthenticate = useCallback((userInformation) => {
    setAuthenticated(true);
    setUserInformation(userInformation);
    localStorage.setItem(INFORMATION_KEY_NAME, JSON.stringify(userInformation));
  }, []);

  const removeAuthenticate = useCallback(() => {
    setAuthenticated(false);
    setUserInformation(null);
    Cookies.remove('PHPSESSID');
    localStorage.removeItem(INFORMATION_KEY_NAME);
  }, []);

  const initialAuthenticate = useCallback(() => {
    const userInformation = getInformation();
    if (Cookies.get('PHPSESSID') && userInformation.token) {
      updateAuthenticate(userInformation);
    }

    setIsGetting(false);
  }, [updateAuthenticate]);

  useEffect(() => {
    initialAuthenticate();
  }, [initialAuthenticate]);

  return (
    <AuthenticateContext.Provider
      value={{
        isGetting,
        isAuthenticated,
        userInformation,
        updateAuthenticate,
        removeAuthenticate,
      }}
    >
      {children}
    </AuthenticateContext.Provider>
  );
};

export default AuthenticateContext;
export { AuthenticateContextProvider };
