import { useEffect, useState } from "react";
import styled from "styled-components";
import { MdKeyboardArrowUp } from "react-icons/md";
import Button from "./Button";

const ScrollToTopBtn = () => {
  const [$display, setDisplay] = useState(false);

  const toggleVisible = () => {
    const scrolled = window.pageYOffset;
    scrolled === 0 ? setDisplay(false) : setDisplay(true);
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  useEffect(() => {
    window.addEventListener("scroll", toggleVisible);

    return () => {
      window.removeEventListener("scroll", toggleVisible);
    };
  }, []);

  return (
    <ScrollToTopButton onClick={scrollToTop} $display={$display} iconOnly>
      <MdKeyboardArrowUp />
    </ScrollToTopButton>
  );
};

const ScrollToTopButton = styled(Button)`
  position: fixed;
  bottom: var(--spacing);
  right: var(--spacing);
  display: ${({ $display }) => ($display ? "block" : "none")};
  z-index: 30;
`;

export default ScrollToTopBtn;
