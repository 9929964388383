import styled from "styled-components";
import loginBackground from "../../assets/images/bg-login.jpg";
import logoCastles from "../../assets/images/logo-castles.png";
import media from "../../styles/mixin/media";

const Wrapper = styled.div`
  background: #13142e;
  min-height: 100vh;
  background-image: url(${logoCastles});
  padding: 6% 2rem 1rem 2rem;
  background-repeat: no-repeat;
  background-position: right 5% bottom 5%;
  background-size: 20%;
  font-family: Helvetica;
  overflow: hidden;

  &::before {
    content: "";
    background-image: url(${loginBackground});
    background-size: cover;
    background-position: center;
    opacity: 0.2;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    position: fixed;
  }

  ${media.tablet`
    padding-top: 3.75rem;
  `}
`;

export default Wrapper;
