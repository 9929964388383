const LANGUAGES = [
  { id: 'en', text: 'English' },
  { id: 'zh-TW', text: '繁體中文' },
];

const DEMAND_ORDER_TYPE = [
  { id: 0, text: 'Customer' },
  { id: 1, text: 'Device' },
  { id: 2, text: 'Bank' },
];

export { LANGUAGES, DEMAND_ORDER_TYPE };
