import styled, { css } from 'styled-components';

const TableHeadCell = styled.th`
  font-size: var(--font-body1);
  font-weight: var(--font-bold);
  padding: var(--spacing-s) var(--spacing-xs);
  width: ${({ width }) => width || 'auto'};
  position: relative;

  > div {
    display: flex;

    > span {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &:first-child {
    padding-left: var(--spacing);
  }

  &:last-child {
    padding-right: var(--spacing);
  }

  ${({ sortable }) => {
    return (
      sortable &&
      css`
        cursor: pointer;

        > div > span {
          max-width: calc(100% - 1rem);
          padding-right: var(--spacing-xs);
        }
      `
    );
  }}

  ${({ align }) => {
    switch (align) {
      case 'center':
        return css`
          > div {
            justify-content: center;
          }
        `;
      default:
        break;
    }
  }}
`;

export default TableHeadCell;
