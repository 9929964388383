import { useState, useMemo, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { MdMoreHoriz } from 'react-icons/md';
import { FiFileText } from 'react-icons/fi';
import { FaFilter } from 'react-icons/fa';
import {
  getDemandOrderList,
  getDemandOrderTypeList,
} from '../../module/demandOrder/api';
import { getOwnerList } from '../../module/owner/api';
import handleApiResponse from '../../utils/api/handleApiResponse';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Section from '../../components/section/Section';
import SectionHeader from '../../components/section/SectionHeader';
import SectionBody from '../../components/section/SectionBody';
import SectionToolbar from '../../components/section/SectionToolbar';
import Heading1 from '../../components/heading/Heading1';
import Table from '../../components/table/Table';
import {
  useLimitChange,
  usePageChange,
  useSortChange,
} from '../../components/table/hooks';
import Dropdown, {
  DropdownItem,
  DropdownLinkOption,
} from '../../components/Dropdown';
import IconButton from '../../components/button/IconButton';
import TitleModal from '../../components/modal/TitleModal';
import SearchBox from '../../components/form/Searchbox';
import Button from '../../components/button/Button';
import ButtonGroup from '../../components/button/ButtonGroup';
import { Grid, Column } from '../../components/grid/Grid';
import FormItem from '../../components/form/FormItem';
import Label from '../../components/form/Label';
import Select from '../../components/select/Select';

const List = () => {
  const { t } = useTranslation();
  const [ownerList, setOwnerList] = useState([]);
  const [typeList, setTypeList] = useState([]);
  const [listData, setListData] = useState([]);
  const [total, setTotal] = useState(1);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
    sort_column: 'create_time',
    sort_type: 'desc',
    search: '',
    owner_id: -1,
    type: -1,
  });

  const pathData = useMemo(() => {
    return [{ name: t('DemandOrder') }, { name: t('List') }];
  }, [t]);

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);
  const onSortChange = useSortChange(
    setListParams,
    listParams.sort_column,
    listParams.sort_type
  );

  const handleGetDemandOrder = useCallback(() => {
    if (listParams.owner_id < 0) return;

    const params = {
      page: listParams.page,
      limit: listParams.limit,
      sort_column: listParams.sort_column,
      sort_type: listParams.sort_type,
      owner_id: listParams.owner_id,
    };

    if (listParams.type >= 0) {
      params.type = listParams.type;
    }

    if (listParams.search) {
      params.search = listParams.search;
    }

    handleApiResponse(getDemandOrderList(params), (response) => {
      const { customer_service, total } = response.data.data;
      setListData(customer_service || []);
      setTotal(total);
    });
  }, [listParams]);

  const generateActionDropdown = useCallback(
    ({ cs_id }) => {
      return (
        <Dropdown key={cs_id}>
          <Dropdown.Toggle>
            <IconButton>
              <MdMoreHoriz />
            </IconButton>
          </Dropdown.Toggle>
          <Dropdown.Content>
            <DropdownItem>
              <DropdownLinkOption to={`/demand-order/${cs_id}`}>
                <FiFileText />
                {t('Details')}
              </DropdownLinkOption>
            </DropdownItem>
          </Dropdown.Content>
        </Dropdown>
      );
    },
    [t]
  );

  const showFilterPanel = () => {
    TitleModal({
      size: 'normal',
      children: (
        <DemandListFilter
          listParams={listParams}
          setListParams={setListParams}
          ownerList={ownerList}
          typeList={typeList}
        />
      ),
    });
  };

  useEffect(() => {
    handleGetDemandOrder();
  }, [handleGetDemandOrder]);

  useEffect(() => {
    handleApiResponse(getOwnerList(), (response) => {
      const { owner } = response.data.data;
      const data = owner.map((owner) => {
        return { id: owner.owner_id, text: owner.owner };
      });

      if (data.length > 0) {
        setListParams((previous) => {
          return {
            ...previous,
            owner_id: data[0].id,
          };
        });
      }

      setOwnerList(data);
    });

    handleApiResponse(getDemandOrderTypeList(), (response) => {
      const { cs_type } = response.data.data;
      const data = cs_type.map((type) => {
        return { id: type.type_id, text: type.type };
      });

      data.unshift({ id: -1, text: t('All') });
      setTypeList(data);
    });
  }, [t]);

  return (
    <>
      <Breadcrumb path={pathData} />
      <Section noPadding>
        <SectionHeader sticky backgroundFill inline>
          <Heading1>{t('DemandOrder')}</Heading1>
          <SectionToolbar>
            <div>
              <SearchBox
                border
                onSearch={(keyword) => {
                  setListParams((previousParams) => {
                    return {
                      ...previousParams,
                      page: 1,
                      search: keyword,
                    };
                  });
                }}
              />

              <Button secondary onClick={showFilterPanel}>
                <FaFilter />
                {t('Filter')}
              </Button>
            </div>
          </SectionToolbar>
        </SectionHeader>
        <SectionBody>
          <Table
            columns={[
              {
                title: t('OrderNumber'),
                fieldName: 'cs_id',
                sortable: true,
              },
              {
                title: t('Bank'),
                fieldName: 'owner',
                sortable: true,
              },
              {
                title: t('CustomerService'),
                fieldName: 'first_name',
                sortable: true,
                render(data, full) {
                  return `${full.first_name} ${full.last_name}`;
                },
              },
              {
                title: t('Type'),
                fieldName: 'type',
                sortable: true,
              },
              {
                title: t('CreatedDate'),
                fieldName: 'create_time',
                sortable: true,
              },
              {
                title: t('Action'),
                custom: true,
                width: '10%',
                align: 'center',
                render(data) {
                  return generateActionDropdown(data);
                },
              },
            ]}
            data={listData}
            currentPage={listParams.page}
            limit={listParams.limit}
            onLimitChange={onLimitChange}
            total={total}
            sortColumn={listParams.sort_column}
            sortType={listParams.sort_type}
            onSortChange={onSortChange}
            onPageChange={onPageChange}
            translation={{ info: t('table.info'), empty: t('table.empty') }}
          />
        </SectionBody>
      </Section>
    </>
  );
};

const DemandListFilter = ({
  listParams,
  setListParams,
  ownerList,
  typeList,
  close,
}) => {
  const { t } = useTranslation();
  const [bank, setBank] = useState(listParams.owner_id);
  const [type, setType] = useState(listParams.type);

  const handleSearch = () => {
    setListParams((previousParams) => {
      return {
        ...previousParams,
        page: 1,
        owner_id: bank,
        type: type,
      };
    });
    close();
  };

  return (
    <>
      <TitleModal.Header handleClose={close}>{t('Filter')}</TitleModal.Header>
      <TitleModal.Body noPaddingBottom>
        <Grid columns={2}>
          <Column desktop={1}>
            <FormItem>
              <Label>{t('Bank')}</Label>
              <Select
                fullWidth
                options={ownerList}
                selected={bank}
                onSelect={setBank}
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label>{t('Type')}</Label>
              <Select
                fullWidth
                options={typeList}
                selected={type}
                onSelect={setType}
              />
            </FormItem>
          </Column>
        </Grid>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={close}>
            {t('button:Cancel')}
          </Button>
          <Button success onClick={handleSearch}>
            {t('button:Search')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

export default List;
