import { useState, useEffect, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useHistory } from 'react-router-dom';
import {
  getWorkOrderStatusList,
  modifyWorkOrder,
  createWorkOrderBasedOnWorkOrder,
} from '../../../module/workOrder/api';
import { getCustomerServiceSimpleList } from '../../../module/customerService/api';
import { getEngineerSimpleList } from '../../../module/engineer/api';
import { success, error } from '../../../module/message';
import handleApiResponse from '../../../utils/api/handleApiResponse';
import AuthenticateContext from '../../../provider/context/authenticate.context';
import Section from '../../../components/section/Section';
import SectionBody from '../../../components/section/SectionBody';
import { Grid, Column } from '../../../components/grid/Grid';
import FormItem from '../../../components/form/FormItem';
import Label from '../../../components/form/Label';
import Input from '../../../components/form/Input';
import Textarea from '../../../components/form/Textarea';
import Select from '../../../components/select/Select';
import Button from '../../../components/button/Button';
import ButtonGroup from '../../../components/button/ButtonGroup';
import DatetimePickerV2 from '../../../components/datetimePicker/DatetimePickerV2';
import TitleModal from '../../../components/modal/TitleModal';

const BasicInformation = ({
  previousData,
  setPreviousData,
  data,
  setData,
  installationInformation,
  handleGetWorkOrderInformation,
  handleSyncHardwareSerialNumber,
}) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const history = useHistory();
  const { userInformation } = useContext(AuthenticateContext);
  const [statusList, setStatusList] = useState([]);
  const [customerServiceList, setCustomerServiceList] = useState([]);
  const [engineerList, setEngineerList] = useState([]);
  const [assigneeList, setAssigneeList] = useState([]);
  const [statusOptions, setStatusOptions] = useState([]);
  const [modifyMode, setModifyMode] = useState(false);

  const handleChangeValue = (value, key) => {
    const newData = { ...data };
    newData[key] = value;

    setData(newData);
  };

  const handleCancelModify = () => {
    setData(previousData);
    setModifyMode(false);
  };

  const handleModify = async () => {
    // get hardware serial number when change status to complete
    if (previousData.wo_status === 6 && data.wo_status === 8) {
      const hardwareSerialNumber = await handleSyncHardwareSerialNumber();
      if (hardwareSerialNumber) {
        handleGetWorkOrderInformation();
      } else {
        error({ message: t('error:FailedToSyncHardwareSerialNumber') });
        return;
      }
    }

    const [principalType, principal] = data.principal.split('_');
    handleApiResponse(
      modifyWorkOrder({
        wo_id: id,
        real_time: data.real_time,
        principal_type: principalType,
        principal: principal,
        wo_status: data.wo_status,
        memo: data.memo || '',
        actual_address: installationInformation.actual_address,
      }),
      () => {
        success({ message: t('success:ModifyWorkOrder') });
        setPreviousData(data);
        setModifyMode(false);
      }
    );
  };

  const handleCreateOrderBasedOnOrder = (workOrderNumber) => {
    handleApiResponse(
      createWorkOrderBasedOnWorkOrder({
        wo_id: id,
        number: workOrderNumber,
      }),
      (response) => {
        const { data } = response.data;
        history.push({ pathname: `/work-order/in-progress/${data}` });
        success({ message: t('success:CreateWorkOrderBasedOnWorkOrder') });
      }
    );
  };

  const showCreateOrderBasedOnOrderModal = () => {
    TitleModal({
      size: 'normal',
      children: (
        <CreateOrderBasedOnOrderModal
          handleCreateOrderBasedOnOrder={handleCreateOrderBasedOnOrder}
        />
      ),
    });
  };

  useEffect(() => {
    let statusOptions = statusList.filter((status) => {
      switch (parseInt(previousData.wo_status, 10)) {
        case 1:
          return [1, 2, 7].includes(status.id);
        case 2:
          return [2, 3, 4, 7].includes(status.id);
        case 3:
          return [2, 3, 7].includes(status.id);
        case 4:
          return [4, 5, 6, 7].includes(status.id);
        case 5:
          return [4, 5, 7].includes(status.id);
        case 6:
          return [6, 7, 8].includes(status.id);
        default:
          return [];
      }
    });

    setStatusOptions(statusOptions);
  }, [statusList, previousData.wo_status]);

  useEffect(() => {
    setAssigneeList([].concat(customerServiceList, engineerList));
  }, [customerServiceList, engineerList]);

  useEffect(() => {
    handleApiResponse(getWorkOrderStatusList(), (response) => {
      let { wo_status } = response.data.data;
      wo_status = wo_status || [];

      const data = wo_status.map((type) => {
        return { id: parseInt(type.lifephase_id, 10), text: type.lifephase };
      });

      setStatusList(data);
    });

    handleApiResponse(getCustomerServiceSimpleList(), (response) => {
      const { data } = response.data;

      const users = data || [];
      setCustomerServiceList(
        users.map((user) => {
          return {
            id: `1_${user.u_id}`,
            text: `${user.first_name} ${user.last_name} (${t(
              'CustomerService'
            )})`,
          };
        })
      );
    });

    handleApiResponse(getEngineerSimpleList(), (response) => {
      const { data } = response.data;

      const engineers = data || [];
      setEngineerList(
        engineers.map((engineer) => {
          return {
            id: `2_${engineer.en_id}`,
            text: `${engineer.first_name} ${engineer.last_name} (${t(
              'Engineer'
            )})`,
          };
        })
      );
    });
  }, [t]);

  return (
    <>
      <Section backgroundReverse noPaddingBottom>
        <SectionBody noPadding>
          <Grid columns={2}>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='workOrderNumber'>{t('WorkOrderNumber')}</Label>
                <Input
                  type='text'
                  id='workOrderNumber'
                  value={data.wo_number}
                  disabled
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='workOrderType'>{t('WorkOrderType')}</Label>
                <Input
                  type='text'
                  id='workOrderType'
                  value={data.type}
                  disabled
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='expectFinishDate' required={modifyMode}>
                  {t('ExpectFinishDate')}
                </Label>
                <DatetimePickerV2
                  disabled={!modifyMode}
                  selected={data.real_time}
                  onConfirm={(date) => {
                    handleChangeValue(date, 'real_time');
                  }}
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='urgency'>{t('Urgency')}</Label>
                <Input type='text' id='urgency' value={data.urgency} disabled />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='status' required={modifyMode}>
                  {t('Status')}
                </Label>
                <Select
                  fullWidth
                  allowSearch={false}
                  options={statusOptions}
                  selected={data.wo_status}
                  disabled={!modifyMode}
                  onSelect={(status) => handleChangeValue(status, 'wo_status')}
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='assignee' required={modifyMode}>
                  {t('Assignee')}
                </Label>
                <Select
                  fullWidth
                  options={assigneeList}
                  selected={data.principal}
                  disabled={!modifyMode}
                  onSelect={(principal) =>
                    handleChangeValue(principal, 'principal')
                  }
                />
              </FormItem>
            </Column>
            <Column desktop={2}>
              <FormItem>
                <Label htmlFor='memo'>{t('Memo')}</Label>
                <Textarea
                  id='memo'
                  rows='4'
                  value={data.memo}
                  disabled={!modifyMode}
                  onChange={(e) => handleChangeValue(e.target.value, 'memo')}
                />
              </FormItem>
            </Column>
          </Grid>
        </SectionBody>
      </Section>

      {[1, 2].includes(parseInt(userInformation.r_id, 10)) && (
        <ButtonGroup alignRight>
          {(previousData.wo_status === 7 || previousData.wo_status === 8) &&
            !modifyMode && (
              <Button warning onClick={showCreateOrderBasedOnOrderModal}>
                {t('button:CreateANewOrderBasedOnThisOrder')}
              </Button>
            )}

          {previousData.wo_status !== 7 && previousData.wo_status !== 8 && (
            <>
              {modifyMode ? (
                <>
                  <Button danger onClick={handleCancelModify}>
                    {t('button:Cancel')}
                  </Button>
                  <Button success onClick={handleModify}>
                    {t('button:Save')}
                  </Button>
                </>
              ) : (
                <Button
                  warning
                  onClick={() => {
                    setModifyMode(true);
                  }}
                >
                  {t('button:Modify')}
                </Button>
              )}
            </>
          )}
        </ButtonGroup>
      )}
    </>
  );
};

const CreateOrderBasedOnOrderModal = ({
  handleCreateOrderBasedOnOrder,
  close,
}) => {
  const { t: trans } = useTranslation();
  const [workOrderNumber, setWorkOrderNumber] = useState('');

  const handleCreate = () => {
    if (!workOrderNumber.trim()) {
      error({ message: trans('error:PleaseFillUpWorkOrderNumber') });
      return;
    }

    handleCreateOrderBasedOnOrder(workOrderNumber);
    close();
  };

  return (
    <>
      <TitleModal.Header handleClose={close}>
        {trans('CreateANewOrderBasedOnThisOrder')}
      </TitleModal.Header>
      <TitleModal.Body noPaddingBottom>
        <FormItem>
          <Label htmlFor='workOrderNumber' required>
            {trans('WorkOrderNumber')}
          </Label>
          <Input
            type='text'
            id='workOrderNumber'
            value={workOrderNumber}
            maxLength='20'
            onChange={(event) => setWorkOrderNumber(event.target.value)}
          />
        </FormItem>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={close}>
            {trans('button:Cancel')}
          </Button>
          <Button success onClick={handleCreate}>
            {trans('button:Create')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

export default BasicInformation;
