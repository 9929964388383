import DefaultRoute from './default';
import WorkOrderRoute from './workOrder';
import DemandOrderRoute from './demandOrder';
import CustomerServiceRoute from './customerService';
import EngineerRoute from './engineer';
import CustomerRoute from './customer';
import LogRoute from './log';
import ReportRoute from './report';
import ProfileRoute from './profile';
import InvalidRoute from './invalid';

const getRoutes = (userInformation) => {
  return [].concat(
    DefaultRoute(),
    WorkOrderRoute(userInformation),
    DemandOrderRoute(userInformation),
    CustomerServiceRoute(userInformation),
    EngineerRoute(userInformation),
    CustomerRoute(userInformation),
    LogRoute(),
    ReportRoute(userInformation),
    ProfileRoute,
    InvalidRoute
  );
};

export default getRoutes;
