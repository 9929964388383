import { callApi } from '../../services/api';

const getCustomerList = (data) => {
  const params = {
    page: data.page,
    limit: data.limit,
    sort_column: data.sort_column,
    sort_type: data.sort_type,
    search: data.search,
  };

  return callApi.get('/get_merchant.php', {
    params: params,
  });
};

const getMerchantSimpleList = () => {
  return callApi.get('/get_merchant_simple.php');
};

const getCustomerInformation = (data) => {
  const params = {
    m_id: data.m_id,
  };

  return callApi.get('/get_merchant_info.php', {
    params: params,
  });
};

const modifyCustomer = (data) => {
  const params = {
    m_id: data.m_id,
    memo: data.memo,
  };

  return callApi.post('/set_merchant.php', params);
};

export { getCustomerList, getMerchantSimpleList, getCustomerInformation, modifyCustomer };
