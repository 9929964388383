import styled from "styled-components";
import IconButton from "../button/IconButton";
import IconFigure from "../icon/IconFigure";
import ImageFluid from "../image/ImageFluid";
import burger from "../../assets/icons/burger.png";

const MenuIcon = ({ toggleSidebar }) => {
  return (
    <Wrapper onClick={toggleSidebar}>
      <IconFigure>
        <ImageFluid
          crossOrigin="anonymous"
          src={burger}
          alt="Sidebar toggler"
        />
      </IconFigure>
    </Wrapper>
  );
};

const Wrapper = styled(IconButton)`
  margin: 0;
  margin-right: calc(var(--spacing) - 0.5rem);
`;

export default MenuIcon;
