import { useState, useCallback, useMemo, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { FaFilter } from 'react-icons/fa';
import { MdMoreHoriz } from 'react-icons/md';
import { FiFileText } from 'react-icons/fi';
import { IoMdOptions } from 'react-icons/io';
import {
  getWorkOrderList,
  getWorkOrderTypeList,
  getUrgencyList,
} from '../../module/workOrder/api';
import { getMerchantSimpleList } from '../../module/customer/api';
import { getCustomerServiceSimpleList } from '../../module/customerService/api';
import { getEngineerSimpleList } from '../../module/engineer/api';
import handleApiResponse from '../../utils/api/handleApiResponse';
import formatDate from '../../utils/format/formatDate';
import useToggleOptions from '../../utils/hooks/useToggleOptions';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Section from '../../components/section/Section';
import SectionHeader from '../../components/section/SectionHeader';
import SectionBody from '../../components/section/SectionBody';
import SectionToolbar from '../../components/section/SectionToolbar';
import Heading1 from '../../components/heading/Heading1';
import Table from '../../components/table/Table';
import {
  useLimitChange,
  usePageChange,
  useSortChange,
} from '../../components/table/hooks';
import Dropdown, {
  DropdownItem,
  DropdownDivOption,
  DropdownLinkOption,
} from '../../components/Dropdown';
import IconButton from '../../components/button/IconButton';
import TitleModal from '../../components/modal/TitleModal';
import SearchBox from '../../components/form/Searchbox';
import Button from '../../components/button/Button';
import ButtonGroup from '../../components/button/ButtonGroup';
import { Grid, Column } from '../../components/grid/Grid';
import FormItem from '../../components/form/FormItem';
import Label from '../../components/form/Label';
import Select from '../../components/select/Select';
import RadioButton from '../../components/form/RadioButton';
import Checkbox from '../../components/form/Checkbox';
import DatePicker from '../../components/datetimePicker/DatePicker';

const CompletedList = () => {
  const { t } = useTranslation();
  const [listData, setListData] = useState([]);
  const [total, setTotal] = useState(1);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
    sort_column: 'real_time',
    sort_type: 'desc',
    search: '',
    type: -1,
    merchant: -1,
    ur_id: -1,
    principal: -1,
    time_mode: 'all',
    start_time: formatDate(Date.now(), 'date'),
    end_time: formatDate(Date.now(), 'date'),
  });
  const [typeList, setTypeList] = useState([]);
  const [customerList, setCustomerList] = useState([]);
  const [urgencyList, setUrgencyList] = useState([]);
  const [customerServiceList, setCustomerServiceList] = useState([]);
  const [engineerList, setEngineerList] = useState([]);
  const [assigneeList, setAssigneeList] = useState([]);
  const [tableOptions, toggleTableOptions] = useToggleOptions([
    { text: 'OrderNumber', checked: 1 },
    { text: 'WorkOrderType', checked: 1 },
    { text: 'EquipmentType', checked: 1 },
    { text: 'TerminalSerialNumber', checked: 1 },
    { text: 'HardwareSerialNumber', checked: 1 },
    { text: 'MerchantNumber', checked: 1 },
    { text: 'Customer', checked: 1 },
    { text: 'WorkOrderImportTime', checked: 1 },
    { text: 'ExpectFinishDate', checked: 1 },
    { text: 'Urgency', checked: 1 },
    { text: 'Assignee', checked: 1 },
  ]);

  const pathData = useMemo(() => {
    return [{ name: t('WorkOrder') }, { name: t('CompletedList') }];
  }, [t]);

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);
  const onSortChange = useSortChange(
    setListParams,
    listParams.sort_column,
    listParams.sort_type
  );

  const handleGetWorkOrder = useCallback(() => {
    const params = {
      page: listParams.page,
      limit: listParams.limit,
      sort_column: listParams.sort_column,
      sort_type: listParams.sort_type,
      lifephase_id: 8,
    };

    if (listParams.time_mode !== 'all') {
      params.start_time = listParams.start_time;
      params.end_time = listParams.end_time;
    }

    if (listParams.type >= 0) {
      params.type = listParams.type;
    }

    if (listParams.merchant !== -1) {
      params.merchant = listParams.merchant.split('_')[1];
    }

    if (listParams.ur_id !== -1) {
      params.ur_id = listParams.ur_id;
    }

    if (listParams.principal !== -1) {
      const [principalType, principal] = listParams.principal.split('_');
      params.principal_type = Number(principalType);
      params.principal = Number(principal);
    }

    if (listParams.search) {
      params.search = listParams.search;
    }

    handleApiResponse(getWorkOrderList(params), (response) => {
      const { workorder, total } = response.data.data;
      setListData(workorder || []);
      setTotal(total);
    });
  }, [listParams]);

  const generateActionDropdown = useCallback(
    ({ wo_id }) => {
      return (
        <Dropdown key={wo_id}>
          <Dropdown.Toggle>
            <IconButton>
              <MdMoreHoriz />
            </IconButton>
          </Dropdown.Toggle>
          <Dropdown.Content>
            <DropdownItem>
              <DropdownLinkOption to={`/work-order/completed/${wo_id}`}>
                <FiFileText />
                {t('Details')}
              </DropdownLinkOption>
            </DropdownItem>
          </Dropdown.Content>
        </Dropdown>
      );
    },
    [t]
  );

  const tableColumns = useMemo(() => {
    const columns = tableOptions
      .filter((option) => {
        return option.checked === 1;
      })
      .map((option) => {
        switch (option.text) {
          case 'OrderNumber':
            return {
              title: t('OrderNumber'),
              fieldName: 'number',
              sortable: true,
            };
          case 'WorkOrderType':
            return {
              title: t('WorkOrderType'),
              fieldName: 'type_val',
              sortable: true,
            };
          case 'EquipmentType':
            return {
              title: t('EquipmentType'),
              fieldName: 'eq_type',
              sortable: true,
            };
          case 'TerminalSerialNumber':
            return {
              title: t('TerminalSerialNumber'),
              fieldName: 'TID',
              sortable: true,
            };
          case 'HardwareSerialNumber':
            return {
              title: t('HardwareSerialNumber'),
              fieldName: 'hwsn',
              sortable: true,
              render(data) {
                if (!data) {
                  return '';
                }

                return data;
              },
            };
          case 'MerchantNumber':
            return {
              title: t('MerchantNumber'),
              fieldName: 'MID',
              sortable: true,
            };
          case 'Customer':
            return {
              title: t('Customer'),
              fieldName: 'merchant',
              sortable: true,
            };
          case 'WorkOrderImportTime':
            return {
              title: t('WorkOrderImportTime'),
              fieldName: 'import_time',
              sortable: true,
            };
          case 'ExpectFinishDate':
            return {
              title: t('ExpectFinishDate'),
              fieldName: 'real_time',
              sortable: true,
            };
          case 'Urgency':
            return {
              title: t('Urgency'),
              fieldName: 'urgency',
              sortable: true,
            };
          case 'Assignee':
            return {
              title: t('Assignee'),
              fieldName: 'first_name',
              sortable: false,
              render(data, full) {
                return `${full.first_name} ${full.last_name}`;
              },
            };
          default:
            return null;
        }
      });

    columns.push({
      title: t('Action'),
      custom: true,
      width: '10%',
      align: 'center',
      render(data) {
        return generateActionDropdown(data);
      },
    });

    return columns;
  }, [tableOptions, generateActionDropdown, t]);

  const showFilterPanel = () => {
    TitleModal({
      size: 'normal',
      children: (
        <WorkOrderListFilter
          listParams={listParams}
          setListParams={setListParams}
          typeList={typeList}
          customerList={customerList}
          urgencyList={urgencyList}
          assigneeList={assigneeList}
        />
      ),
    });
  };

  useEffect(() => {
    handleGetWorkOrder();
  }, [handleGetWorkOrder]);

  useEffect(() => {
    handleApiResponse(getWorkOrderTypeList(), (response) => {
      let { wo_type } = response.data.data;

      wo_type = wo_type || [];
      setTypeList(
        [].concat(
          [{ id: -1, text: t('All') }],
          wo_type.map((type) => {
            return { id: type.type_id, text: type.type };
          })
        )
      );
    });

    handleApiResponse(getMerchantSimpleList(), (response) => {
      const { data } = response.data;

      const merchants = data || [];
      setCustomerList(
        [].concat(
          [{ id: -1, text: t('All') }],
          merchants.map((merchant) => {
            return {
              id: `${merchant.m_id}_${merchant.name}`,
              text: merchant.name,
            };
          })
        )
      );
    });

    handleApiResponse(getUrgencyList(), (response) => {
      let { urgency } = response.data.data;

      urgency = urgency || [];
      setUrgencyList(
        [].concat(
          [{ id: -1, text: t('All') }],
          urgency.map((urgency) => {
            return { id: urgency.ur_id, text: urgency.urgency };
          })
        )
      );
    });

    handleApiResponse(getCustomerServiceSimpleList(), (response) => {
      const { data } = response.data;

      const users = data || [];
      setCustomerServiceList(
        users.map((user) => {
          return {
            id: `1_${user.u_id}`,
            text: `${user.first_name} ${user.last_name} (${t(
              'CustomerService'
            )})`,
          };
        })
      );
    });

    handleApiResponse(getEngineerSimpleList(), (response) => {
      const { data } = response.data;

      const engineers = data || [];
      setEngineerList(
        engineers.map((engineer) => {
          return {
            id: `2_${engineer.en_id}`,
            text: `${engineer.first_name} ${engineer.last_name} (${t(
              'Engineer'
            )})`,
          };
        })
      );
    });
  }, [t]);

  useEffect(() => {
    setAssigneeList(
      [].concat([{ id: -1, text: t('All') }], customerServiceList, engineerList)
    );
  }, [t, customerServiceList, engineerList]);

  return (
    <>
      <Breadcrumb path={pathData} />
      <Section noPadding>
        <SectionHeader sticky backgroundFill inline>
          <Heading1>{t('CompletedWorkOrder')}</Heading1>
          <SectionToolbar>
            <div>
              <SearchBox
                border
                onSearch={(keyword) => {
                  setListParams((previousParams) => {
                    return {
                      ...previousParams,
                      page: 1,
                      search: keyword,
                    };
                  });
                }}
              />

              <Button secondary onClick={showFilterPanel}>
                <FaFilter />
                {t('Filter')}
              </Button>

              <Dropdown>
                <Dropdown.Toggle>
                  <Button secondary>
                    <IoMdOptions />
                    {t('Columns')}
                  </Button>
                </Dropdown.Toggle>
                <Dropdown.Content>
                  {tableOptions.map((option, index) => (
                    <DropdownDivOption
                      key={index}
                      onClick={() => toggleTableOptions(index)}
                    >
                      <Checkbox
                        onChange={() => toggleTableOptions(index)}
                        htmlFor={option.text}
                        noMargin
                        checked={option.checked}
                        text={t(`${option.text}`)}
                      />
                    </DropdownDivOption>
                  ))}
                </Dropdown.Content>
              </Dropdown>
            </div>
          </SectionToolbar>
        </SectionHeader>
        <SectionBody>
          <Table
            columns={tableColumns}
            data={listData}
            currentPage={listParams.page}
            limit={listParams.limit}
            onLimitChange={onLimitChange}
            total={total}
            sortColumn={listParams.sort_column}
            sortType={listParams.sort_type}
            onSortChange={onSortChange}
            onPageChange={onPageChange}
            translation={{ info: t('table.info'), empty: t('table.empty') }}
          />
        </SectionBody>
      </Section>
    </>
  );
};

const WorkOrderListFilter = ({
  listParams,
  setListParams,
  typeList,
  customerList,
  urgencyList,
  assigneeList,
  close,
}) => {
  const { t } = useTranslation();
  const [type, setType] = useState(listParams.type);
  const [customer, setCustomer] = useState(listParams.merchant);
  const [urgency, setUrgency] = useState(listParams.ur_id);
  const [principal, setPrincipal] = useState(listParams.principal);
  const [mode, setMode] = useState(listParams.time_mode);
  const [startTime, setStartTime] = useState(listParams.start_time);
  const [endTime, setEndTime] = useState(listParams.end_time);

  const handleSearch = () => {
    setListParams((previousParams) => {
      return {
        ...previousParams,
        page: 1,
        type: type,
        merchant: customer,
        ur_id: urgency,
        principal: principal,
        time_mode: mode,
        start_time: startTime,
        end_time: endTime,
      };
    });
    close();
  };

  return (
    <>
      <TitleModal.Header handleClose={close}>{t('Filter')}</TitleModal.Header>
      <TitleModal.Body noPaddingBottom>
        <Grid columns={2}>
          <Column desktop={1}>
            <FormItem>
              <Label>{t('Type')}</Label>
              <Select
                fullWidth
                options={typeList}
                selected={type}
                onSelect={setType}
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label>{t('Customer')}</Label>
              <Select
                fullWidth
                options={customerList}
                selected={customer}
                onSelect={setCustomer}
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label>{t('Urgency')}</Label>
              <Select
                fullWidth
                options={urgencyList}
                selected={urgency}
                onSelect={setUrgency}
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label>{t('Assignee')}</Label>
              <Select
                fullWidth
                options={assigneeList}
                selected={principal}
                onSelect={setPrincipal}
              />
            </FormItem>
          </Column>
          <Column desktop={2}>
            <FormItem>
              <Label>{t('ExpectFinishDate')}</Label>
              <div>
                <RadioButton
                  htmlFor='all'
                  text={t('All')}
                  value='all'
                  checked={mode === 'all'}
                  onChange={() => {
                    setMode('all');
                  }}
                />
                <RadioButton
                  htmlFor='custom'
                  text={t('Custom')}
                  value='custom'
                  checked={mode === 'custom'}
                  onChange={() => {
                    setMode('custom');
                  }}
                />
              </div>
              {mode !== 'all' && (
                <Grid columns={2}>
                  <Column desktop={1}>
                    <DatePicker selected={startTime} onConfirm={setStartTime} />
                  </Column>
                  <Column desktop={1}>
                    <DatePicker selected={endTime} onConfirm={setEndTime} />
                  </Column>
                </Grid>
              )}
            </FormItem>
          </Column>
        </Grid>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={close}>
            {t('button:Cancel')}
          </Button>
          <Button success onClick={handleSearch}>
            {t('button:Search')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

export default CompletedList;
