import styled from "styled-components";

const ListBox = styled.div`
  visibility: ${({ $display }) => ($display ? "visible" : "hidden")};
  background: var(--color-background1);
  color: var(--color-white);
  border: var(--border-width) solid var(--border-color);
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  width: ${({ width }) => width}px;
  z-index: 20;
`;
ListBox.displayName = "ListBox";

export default ListBox;
