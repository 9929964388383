import styled from "styled-components";
import rounded from "../../styles/mixin/rounded";

const ImageFigure = styled.figure.attrs(() => ({
  rounded: true,
}))`
  width: 160px;
  height: 160px;
  border: 4px dashed var(--border-color);
  padding: var(--spacing-xs);
  margin-left: var(--spacing);

  ${({ small }) =>
    small &&
    `
      width: 40px;
      height: 40px;
    `}

  ${rounded}

  ${({ noBorder }) => noBorder && "border: none;"}
`;

export default ImageFigure;
