import WorkOrderIcon from '../assets/icons/work-order.png';
import InProgressList from '../pages/workOrder/InProgressList';
import InProgressDetail from '../pages/workOrder/InProgressDetail';
import BatchModifyWorkOrder from '../pages/workOrder/BatchModifyWorkOrder';
import CompletedList from '../pages/workOrder/CompletedList';
import CompletedDetail from '../pages/workOrder/CompletedDetail';
import ClosedList from '../pages/workOrder/ClosedList';
import ClosedDetail from '../pages/workOrder/ClosedDetail';
import PendingReportList from '../pages/workOrder/PendingReportList';
import Generate from '../pages/workOrder/Generate';

const WorkRoute = (userInformation) => {
  return [
    {
      path: '/work-order',
      name: 'WorkOrderManagement',
      icon: WorkOrderIcon,
      parent: true,
      checkPermission() {
        return true;
      },
      child: [
        {
          path: '/in-progress/list',
          name: 'InProgressList',
          component: InProgressList,
          checkPermission() {
            return true;
          },
        },
        {
          path: '/in-progress/:id',
          component: InProgressDetail,
          checkPermission() {
            return true;
          },
        },
        {
          path: '/in-progress/list/batch-modify-work-order',
          component: BatchModifyWorkOrder,
          checkPermission() {
            return true;
          },
        },
        {
          path: '/completed/list',
          name: 'CompletedList',
          component: CompletedList,
          checkPermission() {
            return true;
          },
        },
        {
          path: '/completed/:id',
          component: CompletedDetail,
          checkPermission() {
            return true;
          },
        },
        {
          path: '/closed/list',
          name: 'ClosedList',
          component: ClosedList,
          checkPermission() {
            return true;
          },
        },
        {
          path: '/closed/:id',
          component: ClosedDetail,
          checkPermission() {
            return true;
          },
        },
        {
          path: '/pending-report/list',
          name: 'PendingReportList',
          component: PendingReportList,
          checkPermission() {
            return true;
          },
        },
        {
          path: '/generate',
          name: 'Generate',
          component: Generate,
          checkPermission() {
            return true;
          },
        },
      ],
    },
  ];
};

export default WorkRoute;
