import { useState, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { generateWorkOrder } from '../../module/workOrder/api';
import { error } from '../../module/message';
import handleApiResponse from '../../utils/api/handleApiResponse';
import formatDate from '../../utils/format/formatDate';
import Breadcrumb from '../../components/breadcrumb/Breadcrumb';
import Section from '../../components/section/Section';
import SectionHeader from '../../components/section/SectionHeader';
import SectionBody from '../../components/section/SectionBody';
import Heading1 from '../../components/heading/Heading1';
import Tab from '../../components/Tab';
import Button from '../../components/button/Button';
import ButtonGroup from '../../components/button/ButtonGroup';
import CustomerInformation from './generate/CustomerInformation';
import InstallationInformation from './generate/InstallationInformation';

const Generate = () => {
  const { t } = useTranslation();
  const [selectedTab, setSelectedTab] = useState('customerInformation');
  const [data, setData] = useState({
    name_ch: '',
    name_en: '',
    MID: '',
    contact: '',
    phone: '',
    address: '',
    number: '',
    TID: '',
    request_class: '',
    time: formatDate(new Date()),
    header_1: '',
    s_contact: '',
    s_phone: '',
    s_address: '',
  });

  const pathData = useMemo(() => {
    return [{ name: t('WorkOrder') }, { name: t('Generate') }];
  }, [t]);

  const handleChangeValue = (value, key) => {
    const newData = { ...data };
    newData[key] = value;

    setData(newData);
  };

  const handleReset = () => {
    setData({
      name_ch: '',
      name_en: '',
      MID: '',
      contact: '',
      phone: '',
      address: '',
      number: '',
      TID: '',
      request_class: '',
      time: formatDate(new Date()),
      header_1: '',
      s_contact: '',
      s_phone: '',
      s_address: '',
    });
  };

  const handleGenerate = () => {
    if (!data.name_ch.trim() || !data.name_en.trim()) {
      error({ message: t('error:PleaseFillUpName') });
      return;
    }
    if (!data.MID.trim()) {
      error({ message: t('error:PleaseFillUpNumber') });
      return;
    }
    if (!data.contact.trim()) {
      error({ message: t('error:PleaseFillUpContactPerson') });
      return;
    }
    if (!data.phone.trim()) {
      error({ message: t('error:PleaseFillUpPhoneNumber') });
      return;
    }
    if (!data.address.trim()) {
      error({ message: t('error:PleaseFillUpAddress') });
      return;
    }
    if (!data.number.trim()) {
      error({ message: t('error:PleaseFillUpWorkOrderNumber') });
      return;
    }
    if (!data.TID.trim()) {
      error({ message: t('error:PleaseFillUpTerminalSerialNumber') });
      return;
    }
    if (!data.request_class) {
      error({ message: t('error:PleaseSelectARequestClass') });
      return;
    }
    if (!data.header_1.trim()) {
      error({ message: t('error:PleaseFillUpHeader') });
      return;
    }
    if (!data.s_contact.trim()) {
      error({ message: t('error:PleaseFillUpContactPerson') });
      return;
    }
    if (!data.s_phone.trim()) {
      error({ message: t('error:PleaseFillUpPhoneNumber') });
      return;
    }
    if (!data.s_address.trim()) {
      error({ message: t('error:PleaseFillUpAddress') });
      return;
    }

    handleApiResponse(
      generateWorkOrder({
        name_ch: data.name_ch.trim(),
        name_en: data.name_en.trim(),
        MID: data.MID.trim(),
        contact: data.contact.trim(),
        phone: data.phone.trim(),
        address: data.address.trim(),
        number: data.number.trim(),
        TID: data.TID.trim(),
        request_class: data.request_class,
        time: data.time,
        header_1: data.header_1.trim(),
        s_contact: data.s_contact.trim(),
        s_phone: data.s_phone.trim(),
        s_address: data.s_address.trim(),
      }),
      (response) => {
        const { data: workOrderContent } = response.data;

        if (workOrderContent) {
          const exportedFilenmae = `${data.number.trim()}.txt`;
          const blob = new Blob([workOrderContent], {
            type: 'text/txt;charset=utf-8;',
          });

          if (navigator.msSaveBlob) {
            // IE 10+
            navigator.msSaveBlob(blob, exportedFilenmae);
          } else {
            const link = document.createElement('a');
            if (link.download !== undefined) {
              // feature detection
              // Browsers that support HTML5 download attribute
              const url = URL.createObjectURL(blob);
              link.setAttribute('href', url);
              link.setAttribute('download', exportedFilenmae);
              link.style.visibility = 'hidden';
              document.body.appendChild(link);
              link.click();
              document.body.removeChild(link);
            }
          }
        } else {
          error({
            message: t('error:FailedToGenerateWorkOrder'),
          });
        }
      }
    );
  };

  return (
    <>
      <Breadcrumb path={pathData} />
      <Section noPadding>
        <SectionHeader>
          <Heading1>{t('GenerateWorkOrder')}</Heading1>
        </SectionHeader>
        <SectionBody>
          <Tab selected={selectedTab} onChange={setSelectedTab}>
            <Tab.List>
              <Tab.Tab name='customerInformation'>
                {t('CustomerInformation')}
              </Tab.Tab>
              <Tab.Tab name='installationInformation'>
                {t('InstallationInformation')}
              </Tab.Tab>
            </Tab.List>

            <Tab.Panel name='customerInformation'>
              <CustomerInformation
                data={data}
                handleChangeValue={handleChangeValue}
              />
            </Tab.Panel>

            <Tab.Panel name='installationInformation'>
              <InstallationInformation
                data={data}
                handleChangeValue={handleChangeValue}
              />
            </Tab.Panel>
          </Tab>
          <ButtonGroup alignRight>
            <Button danger onClick={handleReset}>
              {t('button:Reset')}
            </Button>
            <Button success onClick={handleGenerate}>
              {t('button:Generate')}
            </Button>
          </ButtonGroup>
        </SectionBody>
      </Section>
    </>
  );
};

export default Generate;
