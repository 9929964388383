import styled from "styled-components";

const LinkSpan = ({ children, ...props }) => {
  return <Link {...props}>{children}</Link>;
};

const Link = styled.span.attrs((props) => {
  return {
    fontSize: props.fontSmall ? "1rem" : "1.5rem",
    color: props.light ? "#fff" : "rgba(255, 255, 255, 0.5)",
    borderColor: props.light ? "#e6e6e6" : "#3e5fff",
  };
})`
  font-size: ${({ fontSize }) => fontSize};
  line-height: 1.5;
  font-weight: 500;
  color: ${({ color }) => color};
  cursor: pointer;
  position: relative;
  display: inline-block;

  &:after {
    display: block;
    content: "";
    position: absolute;
    bottom: 0;
    top: 1rem;
    left: 0;
    width: 0%;
    transition: width 0.3s ease;
  }

  &:hover {
    &:after {
      width: 100%;
      border-bottom: 1px solid ${({ borderColor }) => borderColor};
      opacity: 0.5;
    }
  }
`;

export default LinkSpan;
