import styled from 'styled-components';

const Timeline = styled.div`
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: 7.8rem;
    width: 0.214rem;
    top: 5px;
    bottom: 5px;
    height: calc(100% - 5px);
    background-color: #e7e9f5;
  }
`;

const Items = styled.div``;

const Item = styled.div`
  display: table;
  position: relative;
  margin-bottom: 1.5rem;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ItemTag = styled.span`
  display: table-cell;
  font-size: var(--font-body1);
  font-weight: 500;
  vertical-align: top;
  position: absolute;
  padding-top: 0.5rem;
  color: #fff;
  width: 6.5rem;
  text-align: center;
`;

const ItemCircle = styled.div`
  border-radius: 50%;
  border: 0.89rem solid #2d2c4c;
  z-index: 1;
  top: 0.2rem;
  left: 7rem;
  position: relative;

  > svg {
    font-size: 1.5rem;
    top: 0;
    left: 0;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  ${({ success }) => success && 'color: var(--color-success);'}

  ${({ danger }) => danger && 'color: var(--color-danger);'}

  ${({ warning }) => warning && 'color: var(--color-warning);'}
`;

const ItemDescription = styled.div`
  display: table-cell;
  vertical-align: top;
  font-size: 1rem;
  padding: 0 0 0 8rem;
  color: #fff;
`;

const ItemTitle = styled.span`
  display: block;
  font-weight: 400;

  &:last-child {
    margin-top: 8px;
  }
`;

const ItemText = styled.span`
  display: block;
  color: var(--font-on-mute);
  margin-top: 8px;
  line-height: 1.25;
  word-break: break-all;
`;

export default Object.assign(Timeline, {
  Items: Items,
  Item: Item,
  ItemTag: ItemTag,
  ItemCircle: ItemCircle,
  ItemDescription: ItemDescription,
  ItemTitle: ItemTitle,
  ItemText: ItemText,
});
