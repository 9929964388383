import styled from 'styled-components';
import inputPlaceholder from '../../styles/mixin/inputPlaceholder';

const Input = styled.input`
  display: block;
  width: 100%;
  height: 36px;
  border: var(--border-width) solid var(--border-color);
  padding: 10px var(--spacing-s);
  border-radius: var(--border-radius-l);
  background: transparent;
  color: var(--font-on-background);
  transition: 0.3s;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;

  &:focus {
    border: var(--border-width) solid var(--color-primary);
    outline: none;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }

  ${inputPlaceholder`
    color: var(--font-on-mute);
  `}

  ${({ hidden }) => hidden && 'display: none;'}

  ${({ borderRadiusS }) =>
    borderRadiusS && 'border-radius: var(--border-radius-s);'}

  ${({ alignCenter }) => alignCenter && 'text-align: center;'}
`;

export default Input;
