import styled from 'styled-components';

const SectionToolbar = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  > div {
    display: flex;
    align-items: center;

    > *:not(:first-child) {
      margin-left: var(--spacing-s);
    }
  }

  > *:not(:first-child) {
    margin-left: var(--spacing-s);
  }
`;

export default SectionToolbar;
