import styled from "styled-components";

const BaseBackdrop = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--color-backdrop);
  z-index: 50;
`;

export default BaseBackdrop;
