/**
 * Convert a timestamp into a date and return specific format
 *
 * @param {String|Integer} timestamp The timestamp in unix of YYYY-MM-DD HH:MM:SS format
 * @param {String} format currently accept date for YYYY-MM-DD, time for HH:MM:SS and others for YYYY-MM-DD HH:MM:SS
 *
 * @return {String}
 */
const formatDate = (timestamp, format = null) => {
  const d = new Date(timestamp);
  let second = `${d.getSeconds()}`;
  let minutes = `${d.getMinutes()}`;
  let hour = `${d.getHours()}`;
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;
  if (hour.length < 2) hour = `0${hour}`;
  if (minutes.length < 2) minutes = `0${minutes}`;
  if (second.length < 2) second = `0${second}`;

  switch (format) {
    case 'date':
      return `${year}-${month}-${day}`;
    case 'time':
      return `${hour}:${minutes}:${second}`;
    default:
      return `${year}-${month}-${day} ${hour}:${minutes}:${second}`;
  }
};

export default formatDate;
