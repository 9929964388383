import Dropdown from "./Dropdown";
import DropdownToggle from "./DropdownToggle";
import DropdownContent from "./DropdownContent";

export default Object.assign(Dropdown, {
  Toggle: DropdownToggle,
  Content: DropdownContent,
});
export { default as DropdownItem } from "./DropdownItem";
export { default as DropdownButtonOption } from "./DropdownButtonOption";
export { default as DropdownDivOption } from "./DropdownDivOption";
export { default as DropdownLinkOption } from "./DropdownLinkOption";
