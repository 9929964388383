import { useCallback } from 'react';

const useSortChange = (setState, sortColumn, sortType) => {
  return useCallback(
    (columnName) => {
      let type = null;
      if (columnName === sortColumn) {
        type = sortType === 'asc' ? 'desc' : 'asc';
      }

      setState((previous) => {
        return {
          ...previous,
          page: 1,
          sort_column: columnName,
          sort_type: type ? type : previous.sort_type,
        };
      });
    },
    [setState, sortColumn, sortType]
  );
};

export default useSortChange;
