import styled from 'styled-components';
import backgroundColor from '../../styles/mixin/backgroundColor';
import colorOnBackground from '../../styles/mixin/colorOnBackground';

const BadgeFill = styled.span.attrs(() => ({
  primary: true,
}))`
  display: inline-flex;
  justify-content: center;
  align-items: center;
  height: auto;
  width: auto;
  border-radius: var(--border-radius-l);
  font-size: 1rem;
  padding: 0.5rem;
  margin: 0;
  color: var(--font-on-background);
  margin-right: var(--spacing-s);

  ${backgroundColor}
  ${colorOnBackground}

  ${({ extraSmall }) => extraSmall && `font-size: 0.75rem; padding: 0.125rem;`}

  ${({ small }) => small && `font-size: 1rem; padding: 0.25rem;`}

  ${({ large }) => large && `font-size: 2rem;`}
`;

export default BadgeFill;
