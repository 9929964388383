import { useContext, cloneElement } from "react";
import DropdownContext from "./DropdownContext";

const DropdownItem = ({ children, onClick }) => {
  const { handleSelect } = useContext(DropdownContext);

  const handleClick = (e) => {
    if (onClick) onClick(e);
    handleSelect();
  };

  return cloneElement(children, {
    onClick: handleClick,
  });
};

export default DropdownItem;
