import { useTranslation } from 'react-i18next';
import Section from '../../../components/section/Section';
import SectionBody from '../../../components/section/SectionBody';
import { Grid, Column } from '../../../components/grid/Grid';
import FormItem from '../../../components/form/FormItem';
import Label from '../../../components/form/Label';
import Input from '../../../components/form/Input';
import Select from '../../../components/select/Select';
import DatetimePickerV2 from '../../../components/datetimePicker/DatetimePickerV2';

const InstallationInformation = ({ data, handleChangeValue }) => {
  const { t } = useTranslation();

  return (
    <>
      <Section backgroundReverse noPaddingBottom>
        <SectionBody noPadding>
          <Grid columns={2}>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='workOrderNumber' required>
                  {t('WorkOrderNumber')}
                </Label>
                <Input
                  type='text'
                  id='workOrderNumber'
                  value={data.number}
                  maxLength='20'
                  onChange={(e) => handleChangeValue(e.target.value, 'number')}
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='terminalSerialNumber' required>
                  {t('TerminalSerialNumber')}
                </Label>
                <Input
                  type='text'
                  id='terminalSerialNumber'
                  value={data.TID}
                  maxLength='8'
                  onChange={(e) => handleChangeValue(e.target.value, 'TID')}
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='requestClass' required>
                  {t('RequestClass')}
                </Label>
                <Select
                  fullWidth
                  options={[
                    { id: 'A', text: 'A' },
                    { id: 'B', text: 'B' },
                    { id: 'C', text: 'C' },
                  ]}
                  selected={data.request_class}
                  onSelect={(requestClass) =>
                    handleChangeValue(requestClass, 'request_class')
                  }
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='expectInstallDate' required>
                  {t('ExpectInstallDate')}
                </Label>
                <DatetimePickerV2
                  selected={data.time}
                  onConfirm={(date) => {
                    handleChangeValue(date, 'time');
                  }}
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='header' required>
                  {t('Header')}
                </Label>
                <Input
                  type='text'
                  id='header'
                  value={data.header_1}
                  maxLength='50'
                  onChange={(e) =>
                    handleChangeValue(e.target.value, 'header_1')
                  }
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='contactPerson' required>
                  {t('ContactPerson')}
                </Label>
                <Input
                  type='text'
                  id='contactPerson'
                  value={data.s_contact}
                  maxLength='60'
                  onChange={(e) =>
                    handleChangeValue(e.target.value, 's_contact')
                  }
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='phone' required>
                  {t('Phone')}
                </Label>
                <Input
                  type='text'
                  id='phone'
                  value={data.s_phone}
                  maxLength='15'
                  onChange={(e) => handleChangeValue(e.target.value, 's_phone')}
                />
              </FormItem>
            </Column>
            <Column desktop={1}>
              <FormItem>
                <Label htmlFor='address' required>
                  {t('Address')}
                </Label>
                <Input
                  type='text'
                  id='address'
                  value={data.s_address}
                  maxLength='200'
                  onChange={(e) =>
                    handleChangeValue(e.target.value, 's_address')
                  }
                />
              </FormItem>
            </Column>
          </Grid>
        </SectionBody>
      </Section>
    </>
  );
};

export default InstallationInformation;
