import EngineerIcon from '../assets/icons/engineer.png';
import Create from '../pages/engineer/Create';
import List from '../pages/engineer/List';
import Detail from '../pages/engineer/Detail';

const EngineerRoute = (userInformation) => {
  return [
    {
      path: '/engineer',
      name: 'EngineerManagement',
      icon: EngineerIcon,
      parent: true,
      checkPermission() {
        if (parseInt(userInformation.r_id, 10) !== 1) return false;

        return true;
      },
      child: [
        {
          path: '/create',
          name: 'Create',
          component: Create,
          checkPermission() {
            if (parseInt(userInformation.r_id, 10) !== 1) return false;

            return true;
          },
        },
        {
          path: '/list',
          name: 'EngineerList',
          component: List,
          checkPermission() {
            if (parseInt(userInformation.r_id, 10) !== 1) return false;

            return true;
          },
        },
        {
          path: '/:id',
          component: Detail,
          checkPermission() {
            if (parseInt(userInformation.r_id, 10) !== 1) return false;

            return true;
          },
        },
      ],
    },
  ];
};

export default EngineerRoute;
