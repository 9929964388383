import styled from "styled-components";
import rounded from "../../styles/mixin/rounded";

const ImageFluid = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;

  ${rounded}
`;

export default ImageFluid;
