import styled, { css } from 'styled-components';
import Button from '../button/Button';
import Accordion from './Accordion';
import { useTranslation } from 'react-i18next';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Input from '../form/Input';
import useCustomPopper from '../../utils/hooks/useCustomPopper';
import OutsideClickHandler from 'react-outside-click-handler';
import formatDate from '../../utils/format/formatDate';

const DatePicker = ({ onConfirm, selected, minDate, maxDate, fixedYear }) => {
  const { t } = useTranslation();
  const [currentDisplayType, setCurrentDisplayType] = useState(null);
  const [displayPicker, setDisplayPicker] = useState(false);
  const [selectedYear, setSelectedYear] = useState(
    new Date(selected).getFullYear()
  );
  const [selectedMonth, setSelectedMonth] = useState(
    new Date(selected).getMonth()
  );
  const [selectedDay, setSelectedDay] = useState(new Date(selected).getDate());

  const [confirmYear, setConfirmYear] = useState(
    new Date(selected).getFullYear()
  );
  const [confirmMonth, setConfirmMonth] = useState(
    new Date(selected).getMonth()
  );
  const [confirmDay, setConfirmDay] = useState(new Date(selected).getDate());

  const [inputValue, setInputValue] = useState(selected);

  const [toggleElement, setToggleElement] = useState(null);
  const [contentElement, setContentElement] = useState(null);
  const { styles, attributes, state, update } = useCustomPopper(
    toggleElement,
    contentElement,
    { strategy: 'absolute', placement: 'bottom-start' }
  );
  const weeks = useMemo(() => {
    return ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'].map((week) => (
      <Week key={week}>{week}</Week>
    ));
  }, []);

  const handleDisplayAccordionContent = useCallback(
    (type) => {
      if (currentDisplayType === type) {
        setCurrentDisplayType(null);
      } else {
        setCurrentDisplayType(type);
      }
    },
    [currentDisplayType]
  );

  const handleChangeYear = (selected) => {
    if (selected === 'previous') {
      setSelectedYear((previousParams) => {
        if (previousParams <= 1) return previousParams;
        else return (previousParams -= 1);
      });
    } else if (selected === 'next') {
      setSelectedYear((previousParams) => (previousParams += 1));
    }
    setCurrentDisplayType(null);
  };

  const handleChangeMonth = (selected) => {
    if (selected === 'previous') {
      setSelectedMonth((previousParams) =>
        previousParams <= 0 ? 11 : (previousParams -= 1)
      );
    } else if (selected === 'next') {
      setSelectedMonth((previousParams) =>
        previousParams >= 11 ? 0 : (previousParams += 1)
      );
    }
    setCurrentDisplayType(null);
  };

  const handleDisabled = useCallback(
    (firstWeekOfMonth, daysOfMonthForSelectedDate, dayIndex, day) => {
      const selectedDate = formatDate(
        new Date(
          selectedYear,
          firstWeekOfMonth >= dayIndex + 1
            ? selectedMonth - 1
            : daysOfMonthForSelectedDate + firstWeekOfMonth < dayIndex + 1
            ? selectedMonth + 1
            : selectedMonth,
          day
        ).getTime() +
          86399 * 1000
      );

      if (
        (minDate && minDate > selectedDate) ||
        (maxDate && maxDate < selectedDate)
      ) {
        return true;
      }

      if (fixedYear) {
        const disabledDateInDec =
          selectedMonth === 11 &&
          dayIndex >= daysOfMonthForSelectedDate + firstWeekOfMonth;
        const disabledDateInJan =
          selectedMonth === 0 && dayIndex < firstWeekOfMonth;

        return disabledDateInDec || disabledDateInJan ? true : false;
      }
      return false;
    },
    [fixedYear, minDate, maxDate, selectedMonth, selectedYear]
  );

  const handleChangeDate = useCallback(
    (daysOfMonthForSelectedDate, firstWeekOfMonth, dayIndex, day) => {
      if (!fixedYear) {
        setSelectedYear(() => {
          if (
            selectedMonth === 0 &&
            dayIndex < firstWeekOfMonth &&
            selectedYear > 1
          ) {
            return selectedYear - 1;
          } else if (
            selectedMonth === 11 &&
            dayIndex >= daysOfMonthForSelectedDate + firstWeekOfMonth
          ) {
            return selectedYear + 1;
          }

          return selectedYear;
        });
        setConfirmYear(() => {
          if (
            selectedMonth === 0 &&
            dayIndex < firstWeekOfMonth &&
            selectedYear > 1
          ) {
            return selectedYear - 1;
          } else if (
            selectedMonth === 11 &&
            dayIndex >= daysOfMonthForSelectedDate + firstWeekOfMonth
          ) {
            return selectedYear + 1;
          }

          return selectedYear;
        });
      }

      setSelectedMonth(() => {
        if (firstWeekOfMonth > dayIndex) {
          return selectedMonth !== 0 ? selectedMonth - 1 : 11;
        } else if (daysOfMonthForSelectedDate + firstWeekOfMonth <= dayIndex) {
          return selectedMonth !== 11 ? selectedMonth + 1 : 0;
        }
        return selectedMonth;
      });

      setConfirmMonth(() => {
        if (firstWeekOfMonth > dayIndex) {
          return selectedMonth !== 0 ? selectedMonth - 1 : 11;
        } else if (daysOfMonthForSelectedDate + firstWeekOfMonth <= dayIndex) {
          return selectedMonth !== 11 ? selectedMonth + 1 : 0;
        }
        return selectedMonth;
      });
      setConfirmDay(day);
    },
    [fixedYear, selectedMonth, selectedYear]
  );

  const handleSelectedDay = useCallback(
    (
      day,
      daysOfMonthForSelectedDate,
      index,
      firstWeekOfMonth,
      lastWeekOfMonth
    ) => {
      if (
        confirmDay === day &&
        confirmYear === selectedYear &&
        confirmMonth === selectedMonth
      ) {
        if (firstWeekOfMonth === 6 && lastWeekOfMonth === 6) {
          return true;
        } else if (firstWeekOfMonth !== 6 && lastWeekOfMonth === 6) {
          return index + 1 > firstWeekOfMonth ? true : false;
        } else {
          return index + 1 >= firstWeekOfMonth &&
            index + 1 <= daysOfMonthForSelectedDate + firstWeekOfMonth
            ? true
            : false;
        }
      } else return false;
    },
    [confirmYear, confirmMonth, confirmDay, selectedYear, selectedMonth]
  );

  const handleDisplayDays = useMemo(() => {
    const daysOfMonthForSelectedDate = new Date(
      selectedYear,
      selectedMonth + 1,
      0
    ).getDate();

    const daysOfPrevioueMonthForSelectedDate = new Date(
      selectedYear,
      selectedMonth + 1 - 1,
      0
    ).getDate();

    const firstWeekOfMonth = new Date(
      `${selectedYear}/${selectedMonth + 1}/1`
    ).getDay();

    const lastWeekOfMonth = new Date(
      `${selectedYear}/${selectedMonth + 1}/${daysOfMonthForSelectedDate}`
    ).getDay();

    const days = [];

    for (let day of [...Array(daysOfMonthForSelectedDate).keys()]) {
      days.push(day + 1);
    }

    if (firstWeekOfMonth !== 0) {
      for (
        let i = daysOfPrevioueMonthForSelectedDate;
        i > daysOfPrevioueMonthForSelectedDate - firstWeekOfMonth;
        i--
      ) {
        days.unshift(i);
      }
    }

    if (lastWeekOfMonth !== 6) {
      for (let i = 1; i <= 6 - lastWeekOfMonth; i++) {
        days.push(i);
      }
    }

    return days.map((day, index) => {
      return (
        <Day
          disabled={handleDisabled(
            firstWeekOfMonth,
            daysOfMonthForSelectedDate,
            index,
            day
          )}
          onClick={() => {
            handleChangeDate(
              daysOfMonthForSelectedDate,
              firstWeekOfMonth,
              index,
              day
            );
          }}
          selected={handleSelectedDay(
            day,
            daysOfMonthForSelectedDate,
            index,
            firstWeekOfMonth,
            lastWeekOfMonth
          )}
          key={index}
        >
          {day}
        </Day>
      );
    });
  }, [
    selectedMonth,
    selectedYear,
    handleDisabled,
    handleChangeDate,
    handleSelectedDay,
  ]);

  const handleToggle = () => {
    setDisplayPicker(!displayPicker);
    update();
  };

  const handleConfirm = () => {
    onConfirm(inputValue);
    setDisplayPicker(false);
    setCurrentDisplayType(null);
  };

  useEffect(() => {
    const date = new Date(selected);

    setSelectedYear(date.getFullYear());
    setSelectedMonth(date.getMonth());
    setSelectedDay(date.getDate());
    setConfirmYear(date.getFullYear());
    setConfirmMonth(date.getMonth());
    setConfirmDay(date.getDate());
    setInputValue(selected);
  }, [selected]);

  useEffect(() => {
    setInputValue(
      formatDate(new Date(confirmYear, confirmMonth, confirmDay), 'date')
    );
  }, [confirmYear, confirmMonth, confirmDay]);

  return (
    <OutsideClickHandler onOutsideClick={handleConfirm}>
      <PickerInput
        ref={setToggleElement}
        value={inputValue}
        onClick={handleToggle}
        readOnly
      />

      <Wrapper
        {...attributes.popper}
        style={styles.popper}
        placement={state && state.placement}
        ref={setContentElement}
        displayPicker={displayPicker}
      >
        <Body displayType={currentDisplayType}>
          {!fixedYear && (
            <Accordion
              type='year'
              handleDisplayAccordionContent={() => {
                handleDisplayAccordionContent('year');
              }}
              handleChangeTitle={handleChangeYear}
              setCurrentDisplayType={setCurrentDisplayType}
              currentDisplayType={currentDisplayType}
              selectedYear={selectedYear}
              setSelectedYear={setSelectedYear}
              selectedDay={selectedDay}
              minDate={minDate}
              maxDate={maxDate}
              fixedYear={fixedYear}
            />
          )}
          <Accordion
            type='month'
            handleDisplayAccordionContent={() => {
              handleDisplayAccordionContent('month');
            }}
            handleChangeTitle={handleChangeMonth}
            setCurrentDisplayType={setCurrentDisplayType}
            currentDisplayType={currentDisplayType}
            selectedMonth={selectedMonth}
            setSelectedMonth={setSelectedMonth}
            selectedYear={selectedYear}
            selectedDay={selectedDay}
            fixedYear={fixedYear}
            minDate={minDate}
            maxDate={maxDate}
          />
          <DateContent>
            {weeks}
            {handleDisplayDays}
          </DateContent>
        </Body>
        <Footer>
          <ConfirmButton fullWidth alignCenter onClick={handleConfirm}>
            {t('Confirm')}
          </ConfirmButton>
        </Footer>
      </Wrapper>
    </OutsideClickHandler>
  );
};

const Wrapper = styled.div`
  padding-bottom: var(--spacing-s);
  background: var(--color-background1);
  border-radius: var(--border-radius);
  border: var(--border-width) solid var(--border-color);
  box-shadow: var(--box-shadow);
  overflow: hidden;
  width: 286px;
  z-index: 1;
  color: var(--color-white);

  display: ${({ displayPicker }) => (displayPicker ? 'block' : 'none')};
`;

const Week = styled.div`
  min-width: 36px;
  height: 36px;
  border-radius: var(--border-radius-s);
  line-height: 36px;
  text-align: center;
`;

const Day = styled.button`
  min-width: 36px;
  height: 36px;
  border-radius: var(--border-radius-s);
  line-height: 36px;
  text-align: center;
  padding: 0;
  border: 0;
  background: var(--color-background1);
  color: var(--color-white);
  &:hover {
    background: var(--color-primary);
  }
  ${({ selected }) => selected && `background: var(--color-hover);`}
  ${({ disabled }) =>
    disabled &&
    css`
      color: var(--font-on-mute);
      background: none;
      &:hover {
        background: none;
      }
    `}
`;

const DateContent = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const PickerInput = styled(Input)`
  &:disabled {
    cursor: auto;
    opacity: 1;
  }
`;

const ConfirmButton = styled(Button)`
  margin: 16px auto 0;
`;

const Body = styled.div`
  ${({ displayType }) =>
    displayType &&
    css`
      height: 216px;
    `}

  overflow: hidden;
  padding: ${({ displayType }) =>
    displayType === 'year'
      ? ' 0 4px var(--spacing-s) var(--spacing-s)'
      : '0 var(--spacing-s) 0 var(--spacing-s)'};
`;

const Footer = styled.div`
  padding: 0 var(--spacing-s);
`;

export default DatePicker;
