import { useState, useRef, useMemo, useEffect, useCallback } from 'react';
import styled from 'styled-components';
import { FiUpload } from 'react-icons/fi';
import { FaTimes, FaPause, FaPlay, FaRedo } from 'react-icons/fa';
import media from '../../styles/mixin/media';
import formatSize from '../../utils/format/formatSize';
import KEY_CODE from '../../utils/aria/keycode';
import fileIcon from '../../assets/icons/file.svg';
import HiddenFileInput from '../file/HiddenFileInput';
import Message from './Message';

const FileDropzone = ({
  message,
  accept,
  translation,
  state = {
    isRemoving: false,
    isUploading: false,
  },
  chunking = true,
  chunkSize = 6000000,
  onAddedFile,
  onRemovedFile,
  onPausedUpload,
  onCanceledFile,
  onUploading,
  onCompleted,
  onError,
}) => {
  const inputRef = useRef(null);
  const [dragging, setDragging] = useState(false);
  const [files, setFiles] = useState([]);
  const [percentage, setPercentage] = useState(0);
  const [isPausing, setIsPausing] = useState(false);
  const [total, setTotal] = useState(0);
  const [index, setIndex] = useState(0);
  const [successCount, setSuccessCount] = useState(0);
  const [hasFail, setHasFail] = useState(false);
  const [failUploadIndexes, setFailUploadIndexes] = useState([]);

  const texts = useMemo(() => {
    const defaultTexts = {
      Cancel: 'Cancel Upload',
      FileIcon: 'File Icon',
      Pause: 'Pause Upload',
      RemoveFile: 'Remove File',
      Resume: 'Resume Upload',
      Retry: 'Retry Upload',
    };

    return { ...defaultTexts, ...translation };
  }, [translation]);

  const handleClick = useCallback(() => {
    if (files.length > 0) return;

    inputRef.current.click();
  }, [files]);

  const handleReset = useCallback(() => {
    setFiles([]);
    setPercentage(0);
    setIsPausing(false);
    setTotal(0);
    setIndex(0);
    setSuccessCount(0);
    setHasFail(false);
    setFailUploadIndexes([]);
  }, []);

  // handle keyboard interaction
  const handleKeyUp = useCallback(
    (event) => {
      const key = event.keyCode;

      switch (key) {
        case KEY_CODE.ENTER:
          handleClick();

          break;
        default:
          break;
      }
    },
    [handleClick]
  );

  const handleKeyDown = useCallback((event) => {
    const key = event.keyCode;

    switch (key) {
      case KEY_CODE.ENTER:
        event.preventDefault();
        break;
      default:
        break;
    }
  }, []);

  const handleFileChange = useCallback(
    (e) => {
      const { files: newFiles } = e.target;

      if (newFiles.length > 0 && files.length === 0) {
        if (onAddedFile) {
          onAddedFile(newFiles[0]);
        }

        setFiles([newFiles[0]]);
        setTotal(chunking ? Math.ceil(newFiles[0].size / chunkSize) : 1);
      }

      inputRef.current.value = '';
    },
    [files, onAddedFile, chunking, chunkSize]
  );

  const handleDragEnter = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (files.length === 0) setDragging(true);
    },
    [files]
  );

  const handleDragOver = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (files.length === 0) setDragging(true);
    },
    [files]
  );

  const handleDragLeave = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (files.length === 0) setDragging(false);
    },
    [files]
  );

  const handleDrop = useCallback(
    (e) => {
      e.preventDefault();
      e.stopPropagation();
      if (files.length === 0) {
        setDragging(false);
        const { dataTransfer } = e;
        const { files: newFiles } = dataTransfer;

        if (newFiles.length > 0) {
          if (onAddedFile) {
            onAddedFile(newFiles[0]);
          }

          setFiles([newFiles[0]]);
          setTotal(chunking ? Math.ceil(newFiles[0].size / chunkSize) : 1);
        }
      }
    },
    [files, onAddedFile, chunkSize, chunking]
  );

  const handleUpload = useCallback(
    async (index, setNext = true) => {
      const [file] = files;

      if (!file) return;

      if (!chunking) {
        if (onUploading) {
          try {
            await onUploading(file, file.name, index);
            setSuccessCount((previous) => ++previous);
          } catch (error) {
            setHasFail(true);
            setFailUploadIndexes((previous) => {
              return [...previous, index];
            });
          }
        }
      } else {
        const start = index * chunkSize;
        const end = Math.min(start + chunkSize, file.size);

        const chunkData = file.slice(start, end);

        if (onUploading) {
          try {
            await onUploading(chunkData, file.name, index);
            setSuccessCount((previous) => ++previous);

            if (setNext) {
              setIndex((previous) => ++previous);
            }
          } catch (error) {
            if (error.message !== 'canceled') {
              setHasFail(true);
              setFailUploadIndexes((previous) => {
                return [...previous, index];
              });

              if (setNext) {
                setIndex((previous) => ++previous);
              }
            }
          }
        }
      }
    },
    [files, chunking, chunkSize, onUploading]
  );

  const handlePauseUpload = useCallback(() => {
    if (onPausedUpload) onPausedUpload();
    setIsPausing(true);
  }, [onPausedUpload]);

  const handleResumeUpload = useCallback(() => {
    setIsPausing(false);
  }, []);

  const handleRetryUpload = useCallback(() => {
    const retryIndexes = [...failUploadIndexes];
    setHasFail(false);
    setFailUploadIndexes([]);

    retryIndexes.forEach((index) => {
      handleUpload(index, false);
    });
  }, [failUploadIndexes, handleUpload]);

  const handleCancelUpload = useCallback(
    (e) => {
      if (e) e.stopPropagation();

      if (onCanceledFile) onCanceledFile(files[0]);
      handleReset();
    },
    [files, onCanceledFile, handleReset]
  );

  const handleRemoveFile = useCallback(
    (e) => {
      if (e) e.stopPropagation();

      if (onRemovedFile) onRemovedFile(files[0]);
      handleReset();
    },
    [files, onRemovedFile, handleReset]
  );

  const handleComplete = useCallback(() => {
    if (onCompleted) onCompleted();
  }, [onCompleted]);

  const handleError = useCallback(() => {
    if (onError) onError();
  }, [onError]);

  const messageItem = useMemo(() => {
    return (
      <Message dragging={dragging}>
        <FiUpload role='img' aria-hidden='true' data-icon='upload' />
        <p>{message}</p>
      </Message>
    );
  }, [dragging, message]);

  const previewItem = useMemo(() => {
    if (files.length === 0) {
      return null;
    }

    return (
      <Preview>
        <PreviewImage>
          <img
            crossOrigin='anonymous'
            src={fileIcon}
            alt={texts.FileIcon}
            aria-hidden='true'
          />
        </PreviewImage>

        <PreviewDetails>
          <p>{formatSize(files[0].size)}</p>
          <p className='ellipsis'>{files[0].name}</p>

          <Progress>
            {(state.isUploading || percentage > 0) && (
              <ProgressBar percentage={percentage} data-testid='progress-bar'>
                <span>{percentage}%</span>
              </ProgressBar>
            )}

            {state.isUploading ? (
              <Button danger title={texts.Cancel} onClick={handleCancelUpload}>
                <FaTimes />
              </Button>
            ) : (
              <Button
                danger
                title={texts.RemoveFile}
                onClick={handleRemoveFile}
              >
                <FaTimes />
              </Button>
            )}

            {!state.isUploading && hasFail && (
              <Button title={texts.Retry} onClick={handleRetryUpload}>
                <FaRedo />
              </Button>
            )}

            {chunking && state.isUploading && !isPausing && (
              <PauseButton title={texts.Pause} onClick={handlePauseUpload}>
                <FaPause />
              </PauseButton>
            )}

            {chunking && state.isUploading && isPausing && (
              <ResumeButton title={texts.Resume} onClick={handleResumeUpload}>
                <FaPlay />
              </ResumeButton>
            )}
          </Progress>
        </PreviewDetails>
      </Preview>
    );
  }, [
    texts,
    files,
    percentage,
    chunking,
    isPausing,
    state.isUploading,
    hasFail,
    handleCancelUpload,
    handlePauseUpload,
    handleRemoveFile,
    handleResumeUpload,
    handleRetryUpload,
  ]);

  // uploading
  useEffect(() => {
    if (state.isUploading && !isPausing) {
      if (index < total) {
        handleUpload(index);
      }
    }
  }, [state.isUploading, isPausing, total, index, handleUpload]);

  // removing
  useEffect(() => {
    if (state.isRemoving) {
      handleRemoveFile();
    }
  }, [state.isRemoving, handleRemoveFile]);

  // set percentage and check complete
  useEffect(() => {
    if (total <= 0) return;

    setPercentage(Math.ceil((successCount / total) * 100));

    if (successCount === total) {
      handleComplete();
    }
  }, [total, successCount, handleComplete]);

  // check has upload fail
  useEffect(() => {
    if (hasFail && successCount + failUploadIndexes.length === total) {
      handleError();
    }
  }, [hasFail, successCount, failUploadIndexes.length, total, handleError]);

  return (
    <Dropzone
      tabIndex='0'
      onClick={handleClick}
      onKeyUp={handleKeyUp}
      onKeyDown={handleKeyDown}
      onDragEnter={handleDragEnter}
      onDragLeave={handleDragLeave}
      onDragOver={handleDragOver}
      onDrop={handleDrop}
      reachedLimit={files.length > 0}
    >
      <HiddenFileInput
        ref={inputRef}
        accept={accept}
        onChange={handleFileChange}
      />

      {files.length === 0 ? messageItem : previewItem}
    </Dropzone>
  );
};

const Dropzone = styled.div`
  min-height: 200px;
  border: 4px dashed var(--border-color);
  border-radius: var(--border-radius);
  color: var(--font-on-primary);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 0 var(--spacing-s);
  cursor: ${({ reachedLimit }) => (reachedLimit ? 'default' : 'pointer')};

  &:focus {
    border-color: var(--color-primary);
    outline: none;
  }
`;
Dropzone.displayName = 'Dropzone';

const Preview = styled.div`
  max-width: 50%;
  display: flex;
  align-items: center;
  text-align: center;

  ${media.laptop`
    max-width: 75%;
  `}

  ${media.tablet`
    max-width: 100%;
  `}
`;
Preview.displayName = 'Preview';

const PreviewImage = styled.div`
  margin-right: var(--spacing-xs);

  > img {
    width: 120px;
    margin-bottom: var(--spacing-xs);
  }
`;
PreviewImage.displayName = 'PreviewImage';

const PreviewDetails = styled.div`
  overflow: hidden;
  min-width: 200px;

  > * {
    margin-bottom: var(--spacing-xs);
  }

  > .ellipsis {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
`;
PreviewDetails.displayName = 'PreviewDetails';

const Progress = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

//fix:color
const ProgressBar = styled.div`
  flex: 1;
  height: 1.25rem;
  border-radius: var(--border-radius);
  position: relative;
  overflow: hidden;
  border: 1px solid var(--color-primary);

  &::before {
    position: absolute;
    content: '';
    width: ${({ percentage }) => percentage}%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: linear-gradient(to bottom, #00bdff, #597ef7);
    transition: width 300ms ease-in-out;
  }

  > span {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }
`;
ProgressBar.displayName = 'ProgressBar';

const Button = styled.span.attrs(() => ({
  role: 'button',
}))`
  display: flex;
  justify-content: center;
  align-items: center;
  flex: 0 0 auto;
  cursor: pointer;
  width: 1.25rem;
  height: 1.25rem;
  color: var(--font-on-primary);
  background: var(--color-primary);
  border-radius: var(--border-radius);
  margin-left: var(--spacing-xs);
  padding: 0.25rem;

  ${({ danger }) =>
    danger &&
    `
      background: var(--color-danger);
    `}
`;

const PauseButton = styled(Button)`
  padding: 5px;
`;

const ResumeButton = styled(Button)`
  padding: 5px 5px 5px 6px;
`;

export default FileDropzone;
