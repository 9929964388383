import { useState, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { MdMoreHoriz } from 'react-icons/md';
import { FiFileText } from 'react-icons/fi';
import { FaFilePdf } from 'react-icons/fa';
import {
  getWorkOrderReportList,
  createWorkOrderReport,
  getWorkOrderReportInformation,
} from '../../../module/workOrder/api';
import { success, error } from '../../../module/message';
import handleApiResponse from '../../../utils/api/handleApiResponse';
import downloadFile from '../../../utils/downloadFile';
import Section from '../../../components/section/Section';
import SectionHeader from '../../../components/section/SectionHeader';
import SectionBody from '../../../components/section/SectionBody';
import SectionToolbar from '../../../components/section/SectionToolbar';
import Table from '../../../components/table/Table';
import {
  useLimitChange,
  usePageChange,
  useSortChange,
} from '../../../components/table/hooks';
import Dropdown, {
  DropdownItem,
  DropdownButtonOption,
} from '../../../components/Dropdown';
import IconButton from '../../../components/button/IconButton';
import Button from '../../../components/button/Button';
import ButtonGroup from '../../../components/button/ButtonGroup';
import TitleModal from '../../../components/modal/TitleModal';
import { Grid, Column } from '../../../components/grid/Grid';
import FormItem from '../../../components/form/FormItem';
import Label from '../../../components/form/Label';
import Input from '../../../components/form/Input';
import Textarea from '../../../components/form/Textarea';
import Slideshow from '../../../components/slideshow/Slideshow';
import MediaBlock from '../../../components/media/MediaBlock';
import PDFViewer from '../../../components/pdf/PDFViewer';

const Report = ({ lifephaseId }) => {
  const { t } = useTranslation();
  const { id } = useParams();
  const [listData, setListData] = useState([]);
  const [total, setTotal] = useState(1);
  const [listParams, setListParams] = useState({
    page: 1,
    limit: 10,
    sort_column: 'create_time',
    sort_type: 'desc',
  });

  const onLimitChange = useLimitChange(setListParams);
  const onPageChange = usePageChange(setListParams);
  const onSortChange = useSortChange(
    setListParams,
    listParams.sort_column,
    listParams.sort_type
  );

  const handleGetWorkReportList = useCallback(() => {
    const params = {
      wo_id: id,
      page: listParams.page,
      limit: listParams.limit,
      sort_column: listParams.sort_column,
      sort_type: listParams.sort_type,
    };

    handleApiResponse(getWorkOrderReportList(params), (response) => {
      const { report, total } = response.data.data;
      setListData(report || []);
      setTotal(total);
    });
  }, [id, listParams]);

  const showDetails = useCallback((id) => {
    handleApiResponse(
      getWorkOrderReportInformation({ report_id: id }),
      (response) => {
        const { data } = response.data;

        TitleModal({
          size: 'normal',
          children: <ReportDetailsModal data={data} />,
        });
      }
    );
  }, []);

  const generateActionDropdown = useCallback(
    ({ report_id }) => {
      return (
        <Dropdown key={report_id}>
          <Dropdown.Toggle>
            <IconButton>
              <MdMoreHoriz />
            </IconButton>
          </Dropdown.Toggle>
          <Dropdown.Content>
            <DropdownItem onClick={() => showDetails(report_id)}>
              <DropdownButtonOption>
                <FiFileText />
                {t('Details')}
              </DropdownButtonOption>
            </DropdownItem>
          </Dropdown.Content>
        </Dropdown>
      );
    },
    [t, showDetails]
  );

  const showCreateReport = () => {
    TitleModal({
      size: 'normal',
      children: (
        <CreateReportModal
          id={id}
          handleGetWorkReportList={handleGetWorkReportList}
        />
      ),
    });
  };

  useEffect(() => {
    handleGetWorkReportList();
  }, [handleGetWorkReportList]);

  return (
    <Section backgroundReverse>
      {lifephaseId === '6' && (
        <SectionHeader>
          <SectionToolbar>
            <div>
              <Button onClick={showCreateReport}>{t('Report')}</Button>
            </div>
          </SectionToolbar>
        </SectionHeader>
      )}
      <SectionBody noPadding={lifephaseId !== '6'}>
        <Table
          backgroundReverse
          columns={[
            {
              title: t('Name'),
              fieldName: 'name',
              sortable: true,
            },
            {
              title: t('Type'),
              fieldName: 'status',
              sortable: true,
              render(data) {
                return data || '';
              },
            },
            {
              title: t('Time'),
              fieldName: 'create_time',
              sortable: true,
            },
            {
              title: t('Content'),
              fieldName: 'content',
              sortable: true,
            },
            {
              title: t('Action'),
              custom: true,
              width: '10%',
              align: 'center',
              render(data) {
                return generateActionDropdown(data);
              },
            },
          ]}
          data={listData}
          currentPage={listParams.page}
          limit={listParams.limit}
          onLimitChange={onLimitChange}
          total={total}
          sortColumn={listParams.sort_column}
          sortType={listParams.sort_type}
          onSortChange={onSortChange}
          onPageChange={onPageChange}
          translation={{ info: t('table.info'), empty: t('table.empty') }}
        />
      </SectionBody>
    </Section>
  );
};

const CreateReportModal = ({ id, handleGetWorkReportList, close }) => {
  const { t } = useTranslation();
  const [data, setData] = useState({
    status: '1',
    memo: '',
  });

  const handleChangeData = (key, value) => {
    const newData = { ...data };
    newData[key] = value;

    setData(newData);
  };

  const handleSubmit = () => {
    if (!data.status) {
      error({ message: t('error:PleaseSelectAType') });
      return;
    }

    if (!data.memo.trim()) {
      error({ message: t('error:PleaseFillUpContent') });
      return;
    }

    handleApiResponse(
      createWorkOrderReport({
        wo_id: parseInt(id, 10),
        status: data.status,
        memo: data.memo,
      }),
      (response) => {
        if (response) {
          success({ message: t('success:Report') });
          close();
          handleGetWorkReportList();
        }
      }
    );
  };

  return (
    <>
      <TitleModal.Header handleClose={close}>{t('Report')}</TitleModal.Header>
      <TitleModal.Body noPaddingBottom>
        <FormItem>
          <Label htmlFor='type' required>
            {t('Type')}
          </Label>
          <Input type='text' id='assignee' value='一般' disabled />
        </FormItem>
        <FormItem>
          <Label htmlFor='content' required>
            {t('Content')}
          </Label>
          <Textarea
            id='content'
            rows='4'
            value={data.memo}
            onChange={(e) => handleChangeData('memo', e.target.value)}
          />
        </FormItem>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={close}>
            {t('button:Cancel')}
          </Button>
          <Button
            success
            disabled={data.status === '' || data.memo === ''}
            onClick={handleSubmit}
          >
            {t('button:Submit')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

const ReportDetailsModal = ({ data, close }) => {
  const { t } = useTranslation();

  const showPreviewPDFModal = ({ title, source }) => {
    TitleModal({
      size: 'normal',
      children: <PreviewPDFModal title={title} source={source} />,
    });
  };

  return (
    <>
      <TitleModal.Header handleClose={close}>
        {t('ReportDetails')}
      </TitleModal.Header>
      <TitleModal.Body noPaddingBottom>
        <Grid columns={2}>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='type'>{t('Type')}</Label>
              <Input
                type='text'
                id='type'
                value={
                  data.trouble ? `${data.status}(${data.trouble})` : data.status
                }
                disabled
              />
            </FormItem>
          </Column>
          <Column desktop={1}>
            <FormItem>
              <Label htmlFor='time'>{t('Time')}</Label>
              <Input type='text' id='time' value={data.create_time} disabled />
            </FormItem>
          </Column>
          <Column desktop={2}>
            <FormItem>
              <Label htmlFor='content'>{t('Content')}</Label>
              <Textarea id='content' rows='4' value={data.content} disabled />
            </FormItem>
          </Column>

          {data?.photo?.length > 0 && (
            <Column desktop={2}>
              <FormItem>
                <Label htmlFor='photo'>{t('Photo')}</Label>
                <Section>
                  <SectionBody noPadding>
                    <Slideshow images={data.photo} />
                  </SectionBody>
                </Section>
              </FormItem>
            </Column>
          )}

          {(data.engineer_sign || data.customer_sign) && (
            <>
              <Column desktop={1} className='margin-bottom'>
                <Label htmlFor='engineerSignature'>
                  {t('EngineerSignature')}
                </Label>
                <Section noPaddingBottom fullHeight>
                  <SectionBody noPadding>
                    {data.engineer_sign && (
                      <img
                        src={data.engineer_sign}
                        alt='engineer signature'
                        style={{ maxWidth: '100%' }}
                      />
                    )}
                  </SectionBody>
                </Section>
              </Column>
              <Column desktop={1} className='margin-bottom'>
                <Label htmlFor='customerSignature'>
                  {t('CustomerSignature')}
                </Label>
                <Section noPaddingBottom fullHeight>
                  <SectionBody noPadding>
                    {data.customer_sign && (
                      <img
                        src={data.customer_sign}
                        alt='customer signature'
                        style={{ maxWidth: '100%' }}
                      />
                    )}
                  </SectionBody>
                </Section>
              </Column>
            </>
          )}

          {(data.electronic_worksheet || data.electronic_receipt) && (
            <Column desktop={2} className='margin-bottom'>
              <Label htmlFor='attachment'>{t('Attachment')}</Label>
              {data.electronic_worksheet && (
                <MediaBlock
                  icon={<FaFilePdf />}
                  title={t('InstallationWorkOrder')}
                  description={data.electronic_worksheet.split('/').pop()}
                  tools={
                    <ButtonGroup alignCenter>
                      <Button
                        onClick={() => {
                          showPreviewPDFModal({
                            title: t('InstallationWorkOrder'),
                            source: data.electronic_worksheet,
                          });
                        }}
                      >
                        {t('button:Preview')}
                      </Button>
                      <Button
                        onClick={() => {
                          downloadFile(
                            data.electronic_worksheet,
                            'application/pdf'
                          );
                        }}
                      >
                        {t('button:Download')}
                      </Button>
                    </ButtonGroup>
                  }
                />
              )}

              {data.electronic_receipt && (
                <MediaBlock
                  icon={<FaFilePdf />}
                  title={t('InstallationReceipt')}
                  description={data.electronic_receipt.split('/').pop()}
                  tools={
                    <ButtonGroup alignCenter>
                      <Button
                        onClick={() => {
                          showPreviewPDFModal({
                            title: t('InstallationReceipt'),
                            source: data.electronic_receipt,
                          });
                        }}
                      >
                        {t('button:Preview')}
                      </Button>
                      <Button
                        onClick={() => {
                          downloadFile(
                            data.electronic_receipt,
                            'application/pdf'
                          );
                        }}
                      >
                        {t('button:Download')}
                      </Button>
                    </ButtonGroup>
                  }
                />
              )}
            </Column>
          )}
        </Grid>
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={close}>
            {t('button:Close')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

const PreviewPDFModal = ({ title, source, close }) => {
  const { t } = useTranslation();

  return (
    <>
      <TitleModal.Header handleClose={close}>{title}</TitleModal.Header>
      <TitleModal.Body noPaddingBottom>
        <PDFViewer source={source} loadingText={t('Loading')} />
      </TitleModal.Body>
      <TitleModal.Footer>
        <ButtonGroup>
          <Button danger onClick={close}>
            {t('button:Close')}
          </Button>
          <Button
            onClick={() => {
              downloadFile(source, 'application/pdf');
            }}
          >
            {t('button:Download')}
          </Button>
        </ButtonGroup>
      </TitleModal.Footer>
    </>
  );
};

export default Report;
