import styled from 'styled-components';
import media from '../../styles/mixin/media';

const Footer = styled.div`
  text-align: center;
  color: rgba(255, 255, 255, 0.5);
  margin-top: 15px;
  line-height: 1.5;

  ${media.mobile`
    font-size: 0.75rem;
  `}
`;

export default Footer;
