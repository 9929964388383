import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MdKeyboardArrowRight, MdKeyboardArrowDown } from 'react-icons/md';
import Section from '../../../components/section/Section';
import SectionHeader from '../../../components/section/SectionHeader';
import SectionBody from '../../../components/section/SectionBody';
import SectionToolbar from '../../../components/section/SectionToolbar';
import Heading2 from '../../../components/heading/Heading2';
import { Grid, Column } from '../../../components/grid/Grid';
import FormItem from '../../../components/form/FormItem';
import Tooltip from '../../../components/tooltip/Tooltip';
import Button from '../../../components/button/Button';
import Collapse from '../../../components/animate/Collapse';
import Block from '../../../components/parameter/Block';

const Parameter = ({ data }) => {
  return data.map((parameter) => {
    return (
      <ParameterSection
        key={parameter.label}
        label={parameter.label}
        data={parameter.data}
      />
    );
  });
};

const ParameterSection = ({ label, data }) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);

  return (
    <Section backgroundReverse>
      <SectionHeader inline>
        <Heading2>{label}</Heading2>
        <SectionToolbar>
          {show ? (
            <Tooltip title={t('Hide')}>
              <Button reverse onClick={() => setShow(!show)} iconOnly>
                <MdKeyboardArrowDown />
              </Button>
            </Tooltip>
          ) : (
            <Tooltip title={t('Show')}>
              <Button reverse onClick={() => setShow(!show)} iconOnly>
                <MdKeyboardArrowRight />
              </Button>
            </Tooltip>
          )}
        </SectionToolbar>
      </SectionHeader>
      <Collapse in={show} duration={500}>
        <SectionBody>
          <Grid columns={4}>
            {Object.entries(data).map(([key, value]) => {
              if (typeof value === 'object') return null;

              return (
                <Column key={key} desktop={1}>
                  <FormItem>
                    <Block>{`${key}: ${value}`}</Block>
                  </FormItem>
                </Column>
              );
            })}
          </Grid>
        </SectionBody>
      </Collapse>
    </Section>
  );
};

export default Parameter;
