import styled from 'styled-components';
import media from '../../styles/mixin/media';

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(${({ columns }) => columns}, minmax(0, 1fr));
  margin: 0 calc(var(--spacing) * -1 / 2);

  > * {
    grid-column-end: span ${({ columns }) => columns};
  }

  ${({ alignCenter }) => alignCenter && 'align-items: center;'}
`;

const Column = styled.div`
  padding: 0 calc(var(--spacing) / 2);
  grid-column-end: span ${({ desktop }) => desktop};

  ${({ laptop }) => {
    return (
      laptop &&
      media.laptop`
        grid-column-end: span ${laptop};
      `
    );
  }}

  ${({ tablet }) => {
    return (
      tablet &&
      media.tablet`
        grid-column-end: span ${tablet};
      `
    );
  }}

  ${({ mobile }) => {
    return (
      mobile &&
      media.mobile`
        grid-column-end: span ${mobile};
      `
    );
  }}
`;

export { Grid, Column };
