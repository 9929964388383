import { callApi } from '../../services/api';

const getDemandOrderList = (data) => {
  const params = {
    page: data.page,
    limit: data.limit,
    sort_column: data.sort_column,
    sort_type: data.sort_type,
    search: data.search,
    owner_id: data.owner_id,
    type: data.type,
  };

  return callApi.get('/get_customer_service.php', {
    params: params,
  });
};

const getDemandOrderTypeList = () => {
  return callApi.get('/get_customer_service_type.php');
};

const getTroubleshootingList = () => {
  return callApi.get('/get_troubleshooting.php');
};

const getDemandOrderInformation = (data) => {
  const params = {
    cs_id: data.cs_id,
  };

  return callApi.post('/get_customer_service_info.php', params);
};

const createDemandOrder = (data) => {
  const params = {
    owner: data.owner,
    type: data.type,
    error_type: data.error_type,
    sales_id: data.sales_id,
    problem: data.problem,
    process_result: data.process_result,
  };

  return callApi.post('/create_customer_service.php', params);
};

const modifyDemandOrder = (data) => {
  const params = {
    cs_id: data.cs_id,
    type_id: data.type_id,
    error_type: data.error_type,
    sales_id: data.sales_id,
    process_result: data.process_result,
    problem: data.problem,
  };

  return callApi.post('/set_customer_service.php', params);
};

export {
  getDemandOrderList,
  getDemandOrderTypeList,
  getTroubleshootingList,
  getDemandOrderInformation,
  createDemandOrder,
  modifyDemandOrder,
};
