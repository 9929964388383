import styled from 'styled-components';
import Spin from '../keyframe/Spin';

const Spinner = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: 0.3rem solid var(--color-background2);
  border-top-color: var(--color-primary);
  animation: 1s ${Spin} infinite linear;
`;

export default Spinner;
