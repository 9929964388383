import TabContext from './TabContext';

const Tab = ({ children, selected, onChange }) => {
  return (
    <TabContext.Provider value={{ selected: selected, onChange: onChange }}>
      {children}
    </TabContext.Provider>
  );
};

export default Tab;
